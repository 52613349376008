import React, { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import {
  changePassword,
  getAccount,
  getCentralWallet,
  getExchangeTotal,
  getWallets,
  newExchangeRequest,
  newInquiry,
  editWallet,
  getExchangeFee,
  ExchangeInfo,
  listExchanges,
} from '../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../components/Header';
import Footer from '../components/Footer';

import PrevIcon from 'img/arrow_prev.png';
import NextIcon from 'img/arrow_next.png';

import PointDIcon from 'img/point_d.png';

import { format } from 'react-string-format';
import dayjs from 'dayjs';

import '../css/MyPage.css';

export default withRouter(function MyPage(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const [account, setAccount] = useState<{
    username: string;
    email: string;
    phone: string;
    pointA: number;
    pointB: number;
    pointC: number;
    pointD: number;
    recommender?: string;
    recommendees: Array<string>;
  } | null>(null);
  const [wallets, setWallets] = useState<string[] | null>(null);

  const [pw, setPw] = useState<string>('');
  const [newPw, setNewPw] = useState<string>('');
  const [newPwConfirm, setNewPwConfirm] = useState<string>('');

  const [enquiryOn, setEnquiryOn] = useState<boolean>(false);
  const [enquiryTitle, setEnquiryTitle] = useState<string>('');
  const [enquiryContent, setEnquiryContent] = useState<string>('');
  const [enquiryIsPrivate, setEnquiryIsPrivate] = useState(false);

  const [exchanges, setExchanges] = useState<ExchangeInfo[]>([]);
  // 현재 출금 신청할 포인트
  const [exchangePoint, setExchangePoint] = useState(0);
  const [exchangeFee, setExchangeFee] = useState(0);
  const [exchangeTab, setExchangeTab] = useState('pending');
  const [exchangeMaxCount, setExchangeMaxCount] = useState(5);

  const [recommendUrl, setRecommendUrl] = useState('');

  const [centralWallet, setCentralWallet] = useState('');

  const [recommendeesOn, setRecommendeesOn] = useState<boolean>(false);
  const [recommendeesPage, setRecommendeesPage] = useState<number>(0);

  const [editWalletOn, setEditWalletOn] = useState<boolean>(false);
  const [newWallet, setNewWallet] = useState<string>('');

  useEffect(() => {
    (async () => {
      const account = await getAccount();
      if (!account) {
        alert(t('challenge-login-first'));
        props.history.push('/signin');
        return;
      }

      setAccount(account);
      setRecommendUrl(`${window.location.origin}/signup?recommender=${account.username}&&accountType=individual`);
      // account.recommendees = Array.from({ length: 312 }, (v, i) => `id${i}`);
    })();
    (async () => {
      const wallets = await getWallets();

      if (!wallets) {
        props.history.push('/signin');
        return;
      }

      setWallets(wallets);
    })();
    (async () => {
      const fee = await getExchangeFee();

      if (fee === null) {
        props.history.push('/signin');
        return;
      }

      setExchangeFee(fee);
    })();
    (async () => {
      const exchanges = await listExchanges();
      if (exchanges) setExchanges(exchanges);
    })();
    (async () => {
      const centralWallet = await getCentralWallet();
      if (!centralWallet) {
        return;
      }
      setCentralWallet(centralWallet);
    })();
  }, []);

  async function changePw() {
    const pwValue = pw ?? '';
    const newPwValue = newPw ?? '';
    const newPwConfirmValue = newPwConfirm ?? '';
    if (!pwValue) {
      alert(t('signup-alert-pw-required'));
      return;
    }

    if (!newPwValue) {
      alert(t('signup-alert-pw-required'));
      return;
    }

    if (!newPwConfirmValue) {
      alert(t('signup-alert-pw-required'));
      return;
    }

    if (newPwValue.length < 6) {
      alert(t('signup-alert-pw-too-short'));
      return;
    }

    if (72 < new TextEncoder().encode(newPwValue).length) {
      alert(t('signup-alert-pw-too-long'));
      return;
    }

    if (newPwValue !== newPwConfirmValue) {
      alert(t('signup-alert-pw-not-match'));
      return;
    }

    const response = await changePassword(pwValue, newPwValue);

    if (!response) return;
    if (!response.success) {
      alert(t('mypage-check-password'));
      return;
    }

    alert(t('mypage-change-password-successful'));
  }

  async function handleInquiry() {
    if (!(enquiryTitle ?? '').trim()) {
      alert(t('mypage-enter-title'));
      return;
    }

    if (!(enquiryContent ?? '').trim()) {
      alert(t('mypage-enter-content'));
      return;
    }

    await newInquiry(t('mypage-enquiry-etc'), enquiryTitle, enquiryContent, enquiryIsPrivate);

    window.location.reload();
  }

  async function handleExchange() {
    if (exchangePoint < 100) {
      alert(t('mypage-alert-insufficient-expoint'));
      return;
    }

    if ((account?.pointD ?? 0) < exchangePoint) {
      alert(t('mypage-alert-insufficient-pointd'));
      return;
    }

    if (await newExchangeRequest(exchangePoint)) window.location.reload();
  }

  async function onEditWallet() {
    const newWalletValue = newWallet ?? '';

    if (!newWalletValue) {
      alert(t('signup-alert-new-wallet-required'));
      return;
    }

    (async () => {
      const response = await editWallet(newWalletValue);

      if (!response) return;
      if (!response.success) {
        alert(t('mypage-check-new-wallet'));
        return;
      }

      alert('지갑주소 변경 신청이 완료되었습니다. 등록된 이메일을 확인해주세요.');
      location.reload();
    })();
  }

  function copyText(text: string) {
    if (!document.queryCommandSupported('copy')) {
      return alert(t('cannot-copy'));
    }
    const textarea = document.createElement('textarea');
    textarea.value = text;

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.dark(t('copy-success'), {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      closeButton: false,
    });
  }

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <div className="Mypage-main">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <div className="Mypage-userinfo-box">
            <div className="Mypage-title">{t('mypage-change-password')}</div>
            <div style={{ height: '20px' }} />
            <div className="Mypage-info-label">{t('mypage-password')}</div>
            <div style={{ height: '10px' }} />
            <div style={{ height: '32px' }}>
              <input
                className="Mypage-info-input"
                placeholder={t('mypage-input-password')}
                type="password"
                value={pw}
                onChange={(e) => setPw(e.target.value)}
              />
            </div>
            <div>
              <div style={{ height: '10px' }} />
              <div className="Mypage-info-label">{t('mypage-new-password')}</div>
              <div style={{ height: '10px' }} />
              <div style={{ height: '32px' }}>
                <input
                  className="Mypage-info-input"
                  placeholder={t('mypage-input-new-password')}
                  type="password"
                  value={newPw}
                  onChange={(e) => setNewPw(e.target.value)}
                />
              </div>
              <div style={{ height: '10px' }} />
              <div className="Mypage-info-label">{t('mypage-confirm-password')}</div>
              <div style={{ height: '10px' }} />
              <div style={{ height: '32px' }}>
                <input
                  className="Mypage-info-input"
                  placeholder={t('mypage-input-new-password')}
                  type="password"
                  value={newPwConfirm}
                  onChange={(e) => setNewPwConfirm(e.target.value)}
                />
              </div>
              <div style={{ height: '20px' }} />
              <button className="Mypage-wallet-innerbox-btn1" type="button" onClick={changePw}>
                {t('mypage-confirm-change')}
              </button>
            </div>
            <div style={{ height: '30px' }} />
            <div style={{ height: '34px', position: 'relative' }}>
              <div className="Mypage-info-info">
                {t('mypage-info-username-label')}: {account?.username} <br />
                {t('mypage-info-email-label')}:{account?.email}
              </div>
              <button
                className="Mypage-info-support"
                onClick={() => {
                  setEnquiryOn(true);
                }}
              >
                {t('mypage-menu-contact')}
              </button>
            </div>
          </div>
          <div style={{ height: '20px' }} />
          <div className="Mypage-userinfo-box">
            <div className="Mypage-title">{t('mypage-info-recommender-label')}</div>
            <div style={{ height: '20px' }} />
            <div style={{ display: 'flex' }}>
              <div className="Mypage-info-label">{t('mypage-info-recommendees-label')}</div>
              <div style={{ width: '15px' }} />
              <button
                className="Mypage-info-b3"
                onClick={() => {
                  setRecommendeesOn(true);
                }}
              >
                {account?.recommendees.length}
                {t('mypage-info-recommendees-unit')}
              </button>
            </div>
            <div style={{ height: '10px' }} />
            <div className="Mypage-info-label">{t('mypage-recommend-link')}:</div>
            <div className="Mypage-info-h2">{recommendUrl}</div>
            <div style={{ height: '10px' }} />
            <button className="Mypage-info-b1" onClick={() => copyText(recommendUrl)}>
              {t('copy-label')}
            </button>
            <div style={{ height: '10px' }} />
          </div>
          <div style={{ height: '20px' }} />
          <div className="Mypage-userinfo-box">
            <div className="Mypage-title">출금 내역</div>
            <div style={{ height: '20px' }} />
            <div style={{ display: 'flex' }}>
              <button
                className="Mypage-exchanges-tab"
                disabled={exchangeTab == 'pending'}
                onClick={() => {
                  setExchangeTab('pending');
                  setExchangeMaxCount(5);
                }}
              >
                출금 진행 중
              </button>
              <div style={{ width: '16px' }} />
              <button
                className="Mypage-exchanges-tab"
                disabled={exchangeTab == 'exchanged'}
                onClick={() => {
                  setExchangeTab('exchanged');
                  setExchangeMaxCount(5);
                }}
              >
                출금 완료
              </button>
            </div>
            <div style={{ height: '12px' }} />
            {exchanges
              .filter((info) => info.status == exchangeTab)
              .slice(0, exchangeMaxCount)
              .map((e, i) => {
                return (
                  <>
                    <div style={{ height: '8px' }} />
                    <div className="Mypage-exchanges-box" key={`exchange${e.id}`}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={PointDIcon} style={{ width: '16px', height: '16px', background: 'none', border: 'none' }} />
                          <div style={{ width: '4px' }} />
                          <div className="Mypage-exchanges-h1">{e.point}</div>
                          <div style={{ width: '20px' }} />
                          <div className="Mypage-exchanges-arrow" />
                          <div style={{ width: '10px' }} />
                          <div className="Mypage-exchanges-h2">{e.point * (1 - e.fee / 100)}$</div>
                        </div>
                        <div style={{ height: '6px' }} />
                        <div className="Mypage-exchanges-h3">{dayjs(e.requestedAt).format('YYYY년 M월 D일 A hh:mm')}</div>
                      </div>
                      <span style={{ margin: 'auto' }} />
                      <div className={exchangeTab == 'pending' ? 'Mypage-exchanges-h4' : 'Mypage-exchanges-h5'}>
                        {exchangeTab == 'pending' ? '출금 신청됨' : '출금 완료'}
                      </div>
                      <div style={{ width: '8px' }} />
                      <div className={exchangeTab == 'pending' ? 'Mypage-exchanges-dot1' : 'Mypage-exchanges-dot2'} />
                    </div>
                  </>
                );
              })}
            {exchanges.filter((info) => info.status == exchangeTab).length > exchangeMaxCount && (
              <>
                <div style={{ height: '8px' }} />
                <button className="Mypage-exchanges-more" type="button" onClick={() => setExchangeMaxCount((val) => val + 5)}>
                  더보기
                </button>
              </>
            )}
          </div>
        </div>
        <div style={{ width: '20px' }} />
        <div className="Mypage-wallet-box">
          <div className="Mypage-title">{t('mypage-wallet-title')}</div>
          <div style={{ height: '29px' }} />
          <div className="Mypage-wallet-label">{t('mypage-wallet-my-point')}</div>
          <div className="Mypage-wallet-innerbox">
            <div style={{ height: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              <div className="Mypage-wallet-innerbox-circle" />
              <div style={{ width: '10px' }} />
              <div className="Mypage-wallet-innerbox-h1">{t('demo-point')}</div>
              <div style={{ width: '20px' }} />
              <div className="Mypage-wallet-innerbox-h2">{(account?.pointA ?? 0) + 'p'}</div>
            </div>
            <div style={{ height: '16px' }} />

            <div style={{ height: '35px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              <div className="Mypage-wallet-innerbox-circle" />
              <div style={{ width: '10px' }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: '20px', display: 'flex', flexDirection: 'row' }}>
                  <div className="Mypage-wallet-innerbox-h1">{t('tournament-point')}</div>
                  <div style={{ width: '20px' }} />
                  <div className="Mypage-wallet-innerbox-h2">{(account?.pointB ?? 0) + 'p'}</div>
                </div>
                <div style={{ height: '15px' }}>
                  <div className="Mypage-wallet-innerbox-h3">{t('mypage-wallet-p1')}</div>
                </div>
              </div>
            </div>

            <div style={{ height: '16px' }} />

            <div style={{ height: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              <div className="Mypage-wallet-innerbox-circle" />
              <div style={{ width: '10px' }} />
              <div className="Mypage-wallet-innerbox-h1">{t('live-point')}</div>
              <div style={{ width: '20px' }} />
              <div className="Mypage-wallet-innerbox-h2">{(account?.pointC ?? 0) + 'p'}</div>
            </div>
            <div style={{ height: '16px' }} />

            <div style={{ height: '35px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              <div className="Mypage-wallet-innerbox-circle" />
              <div style={{ width: '10px' }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: '20px', display: 'flex', flexDirection: 'row' }}>
                  <div className="Mypage-wallet-innerbox-h1">{t('eth-point')}</div>
                  <div style={{ width: '20px' }} />
                  <div className="Mypage-wallet-innerbox-h2">{(account?.pointD ?? 0) + 'p'}</div>
                </div>
                <div style={{ height: '15px' }}>
                  <div className="Mypage-wallet-innerbox-h3">{t('mypage-wallet-p2')}</div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: '10px' }} />
          <div className="Mypage-wallet-label">{t('mypage-withdraw')}</div>
          <div className="Mypage-wallet-innerbox">
            <div style={{ height: '20px', display: 'flex', flexDirection: 'row' }}>
              <div className="Mypage-wallet-innerbox-h1">{t('mypage-wallet-p3')}</div>
              <div style={{ width: '35px' }} />
              <div className="Mypage-wallet-innerbox-h2">{(account?.pointD ?? 0) + 'p'}</div>
            </div>
            <div style={{ height: '10px' }} />
            <div style={{ height: '32px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <input
                className="Mypage-wallet-innerbox-input"
                placeholder="환전 하려는 포인트량"
                onChange={(event) => {
                  try {
                    if (!event.target.value || !event.target.value.trim()) setExchangePoint(0);
                    else setExchangePoint(Number.parseInt(event.target.value.trim()));
                  } catch {
                    return;
                  }
                }}
                value={exchangePoint}
              />
              <div className="Mypage-wallet-innerbox-h5"> = </div>
              <div className="Mypage-wallet-innerbox-h6">{exchangePoint}$</div>
            </div>
            <div style={{ height: '10px' }} />
            <div className="Mypage-wallet-innerbox-h3">{t('mypage-wallet-p4')}</div>
            <div className="Mypage-wallet-innerbox-h7">{format(t('mypage-wallet-p5'), `${5}%`)}</div>
            <div style={{ height: '10px' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="Mypage-wallet-innerbox-h7">{t('mypage-fees')}</div>
              <span style={{ margin: 'auto' }} />
              <div className="Mypage-wallet-innerbox-h7">{(exchangePoint * 0.05).toFixed(2)}$</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="Mypage-wallet-innerbox-h7">{t('mypage-wallet-p6')}</div>
              <span style={{ margin: 'auto' }} />
              <div className="Mypage-wallet-innerbox-h7">{exchangePoint.toFixed(2)}$</div>
            </div>
            <div style={{ height: '10px' }} />

            <div className="Mypage-wallet-innerbox-h9">{t('mypage-wallet-p7')}</div>
            <div className="Mypage-wallet-innerbox-h10">{t('mypage-wallet-p8')}</div>

            <div style={{ height: '10px' }} />
            <div style={{ height: '32px' }}>
              <button className="Mypage-wallet-innerbox-btn-full" onClick={handleExchange}>
                {t('mypage-request-withdrawal')}
              </button>
            </div>
            <div style={{ height: '10px' }} />
          </div>
          <div style={{ height: '10px' }} />

          <div className="Mypage-wallet-label">{t('mypage-wallet-p9')}</div>
          <div className="Mypage-wallet-innerbox">
            <div className="Mypage-wallet-innerbox-h1">{t('mypage-wallet-label')}</div>
            <div style={{ height: '4px' }} />
            <div className="Mypage-wallet-innerbox-token">{wallets ? wallets[0] : ''}</div>
            <div style={{ height: '10px' }} />
            <div style={{ height: '32px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <button className="Mypage-wallet-innerbox-btn2" onClick={() => copyText(wallets ? wallets[0] : '')}>
                {t('copy-label')}
              </button>
              <div style={{ width: '10px' }} />
              <button className="Mypage-wallet-innerbox-btn3" onClick={() => setEditWalletOn(true)}>
                {t('mypage-change-wallet')}
              </button>
            </div>
            <div style={{ height: '20px' }} />
            <div className="Mypage-wallet-innerbox-h1">{t('mypage-wallet-p10')}</div>
            <div style={{ height: '4px' }} />
            <div className="Mypage-wallet-innerbox-h8">
              {exchanges.reduce((total, info) => {
                return total + info.point * (1 - info.fee / 100);
              }, 0)}{' '}
              $
            </div>
          </div>

          <div style={{ height: '10px' }} />
          <div className="Mypage-wallet-label">{t('mypage-wallet-p11')}</div>
          <div className="Mypage-wallet-innerbox">
            <div className="Mypage-wallet-innerbox-h1">{t('mypage-wallet-label')}</div>
            <div style={{ height: '4px' }} />
            <div className="Mypage-wallet-innerbox-token">{centralWallet}</div>
            <div style={{ height: '10px' }} />
            <div style={{ height: '32px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <button className="Mypage-wallet-innerbox-btn2" onClick={() => copyText(centralWallet)}>
                {t('copy-label')}
              </button>
            </div>
            <div style={{ height: '10px' }} />
            <div className="Mypage-wallet-innerbox-h3">{t('mypage-wallet-p12')}</div>
            <div style={{ height: '20px' }} />
            <div style={{ height: '20px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <div style={{ width: '40px' }}>
                <div className="Mypage-wallet-innerbox-h1">{t('mypage-trade-ratio')}</div>
              </div>
              <div style={{ width: '20px' }} />
              <div className="Mypage-wallet-innerbox-h2">10$ ⇢ 10p</div>
            </div>
            <div className="Mypage-wallet-innerbox-h7">{t('mypage-wallet-p13')}</div>
          </div>
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
      <Modal
        isOpen={enquiryOn}
        onAfterOpen={() => {
          setEnquiryTitle('');
          setEnquiryContent('');
        }}
        className="Mypage-enquiry-box"
        style={{ overlay: { background: 'rgba(0, 0, 0, 0.7)' } }}
      >
        <div style={{ height: '35px', display: 'flex' }}>
          <div className="Mypage-enquiry-title">{t('mypage-menu-contact')}</div>
          <span style={{ margin: 'auto' }} />
          <button
            className="Mypage-modal-cancelbtn"
            onClick={() => {
              setEnquiryOn(false);
            }}
          >
            {t('cancel-label')}
          </button>
          <div style={{ width: '10px' }} />
          <button className="Mypage-modal-confirmbtn" onClick={handleInquiry}>
            {t('complete-label')}
          </button>
        </div>
        <div style={{ height: '20px' }} />
        <div className="Mypage-info-label">{t('mypage-title-label')}</div>
        <div style={{ height: '10px' }} />
        <input
          className="Mypage-info-input"
          placeholder={t('mypage-input-title')}
          value={enquiryTitle}
          onChange={(e) => {
            setEnquiryTitle(e.target.value);
          }}
        />
        <div style={{ height: '10px' }} />
        <div className="Mypage-info-label">{t('mypage-content-label')}</div>
        <div style={{ height: '10px' }} />
        <div className="Mypage-modal-content">
          <textarea
            className="Mypage-modal-textarea"
            placeholder={t('mypage-input-content')}
            value={enquiryContent}
            onChange={(e) => {
              setEnquiryContent(e.target.value.substring(0, 100));
            }}
          />
          <div className="Mypage-modal-content-words">{enquiryContent.length + '/100'}</div>
        </div>
      </Modal>

      <Modal
        isOpen={recommendeesOn}
        onAfterOpen={() => {
          setRecommendeesPage(0);
        }}
        className="Mypage-recommendees-modal"
        style={{ overlay: { background: 'rgba(0, 0, 0, 0.7)' } }}
      >
        <div style={{ height: '35px', display: 'flex' }}>
          <div className="Mypage-enquiry-title">{t('mypage-info-recommendees-label')}</div>
          <span style={{ margin: 'auto' }} />
          <button
            className="Mypage-modal-confirmbtn"
            onClick={() => {
              setRecommendeesOn(false);
            }}
          >
            {t('confirm-label')}
          </button>
        </div>
        {account && account.recommendees && (
          <>
            <div style={{ height: `${account.recommendees.length > 0 ? 20 : 0}px` }} />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridTemplateRows: `repeat(${Math.min(10, Math.ceil(account.recommendees.length / 3) - 10 * recommendeesPage)}, 32px)`,
              }}
            >
              {account.recommendees
                .slice(recommendeesPage * 30, Math.min((recommendeesPage + 1) * 30, account.recommendees.length))
                .map((e) => {
                  return <div className="Mypage-recommendees-id">{e}</div>;
                })}
            </div>
            {account.recommendees.length > 30 && (
              <>
                <div style={{ height: '10px' }} />
                <div style={{ display: 'flex', margin: '0 auto' }}>
                  <button
                    className="Mypage-modal-page"
                    onClick={() => {
                      setRecommendeesPage((val) => Math.max(0, val - 1));
                    }}
                  >
                    <img src={PrevIcon} />
                  </button>
                  {Math.ceil(account.recommendees.length / 30) > 7 ? (
                    <>
                      <button className="Mypage-modal-page" onClick={() => setRecommendeesPage(0)} disabled={recommendeesPage == 0}>
                        1
                      </button>
                      {recommendeesPage > 3 && <div className="Mypage-modal-page">…</div>}
                      {Array.from({ length: 5 }, (v, i) => {
                        const pageNum = Math.min(Math.max(3, recommendeesPage), Math.ceil(account.recommendees.length / 30) - 4) + i - 2;
                        return (
                          <button
                            className="Mypage-modal-page"
                            onClick={() => setRecommendeesPage(pageNum)}
                            disabled={recommendeesPage == pageNum}
                          >
                            {pageNum + 1}
                          </button>
                        );
                      })}
                      {recommendeesPage < Math.ceil(account.recommendees.length / 30) - 4 && <div className="Mypage-modal-page">…</div>}
                      <button
                        className="Mypage-modal-page"
                        onClick={() => setRecommendeesPage(Math.ceil(account.recommendees.length / 30) - 1)}
                        disabled={recommendeesPage == Math.ceil(account.recommendees.length / 30) - 1}
                      >
                        {Math.ceil(account.recommendees.length / 30)}
                      </button>
                    </>
                  ) : (
                    <>
                      {Array.from({ length: Math.ceil(account.recommendees.length / 30) }, (v, i) => {
                        return (
                          <button className="Mypage-modal-page" onClick={() => setRecommendeesPage(i)} disabled={recommendeesPage == i}>
                            {i + 1}
                          </button>
                        );
                      })}
                    </>
                  )}

                  <button
                    className="Mypage-modal-page"
                    onClick={() => {
                      setRecommendeesPage((val) => Math.min(val + 1, Math.ceil(account.recommendees.length / 30) - 1));
                    }}
                  >
                    <img src={NextIcon} />
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </Modal>

      <Modal
        isOpen={editWalletOn}
        onAfterOpen={() => {
          setNewWallet('');
        }}
        className="Mypage-recommendees-modal"
        style={{ overlay: { background: 'rgba(0, 0, 0, 0.7)' } }}
      >
        <div style={{ height: '35px', display: 'flex' }}>
          <div className="Mypage-enquiry-title">{t('mypage-wallet-p14')}</div>
          <span style={{ margin: 'auto' }} />
          <button
            className="Mypage-modal-cancelbtn"
            onClick={() => {
              setEditWalletOn(false);
            }}
          >
            {t('cancel-label')}
          </button>
        </div>
        <div style={{ height: '20px' }} />
        <div className="Mypage-info-label">{t('mypage-wallet-p15')}</div>
        <div style={{ height: '10px' }} />
        <div style={{ height: '32px' }}>
          <input
            className="Mypage-info-input"
            placeholder={t('mypage-wallet-p16')}
            value={newWallet}
            onChange={(e) => setNewWallet(e.target.value)}
          />
        </div>
        <div style={{ height: '10px' }} />
        <div className="Mypage-modal-h1">{t('mypage-wallet-p17')}</div>
        <div style={{ height: '4px' }} />
        <div className="Mypage-modal-h2"> {t('mypage-wallet-p18')}</div>
        <div style={{ height: '10px' }} />
        <div className="Mypage-modal-h2">{t('mypage-wallet-p19')}</div>
        <div style={{ height: '16px' }} />
        <div style={{ height: '44px' }}>
          <button className="Mypage-wallet-innerbox-btn-full" onClick={onEditWallet}>
            {t('mypage-confirm-change')}
          </button>
        </div>
      </Modal>
    </div>
  );
});
