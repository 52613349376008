import React, { ReactElement, useEffect, useState } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Header from '../components/Header';
import Footer from '../components/Footer';

import '../css/SendEnquiry.css';
import { newInquiry } from '../api';

export default withRouter(function SendEnquiry(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();

  async function handleInquiry() {
    if (!(enquiryTitle ?? '').trim()) {
      alert(t('mypage-enter-title'));
      return;
    }

    if (!(enquiryContent ?? '').trim()) {
      alert(t('mypage-enter-content'));
      return;
    }

    await newInquiry(t('mypage-enquiry-etc'), enquiryTitle, enquiryContent, isPrivate);

    history.push('/customercare');
  }

  const [enquiryTitle, setEnquiryTitle] = useState<string>('');
  const [enquiryContent, setEnquiryContent] = useState<string>('');
  const [isPrivate, setIsPrivate] = useState(false);

  return (
    <div className="App">
      <Header />
      <div className="SendEnquiry-box">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="SendEnquiry-title">{t('send-enquiry-title')}</div>
          <button className="Mypage-modal-cancelbtn" onClick={() => history.push('/customercare')}>
            {t('cancel-label')}
          </button>
        </div>
        <div style={{ height: '20px' }} />
        <div className="SendEnquiry-label">{t('service-title')}</div>
        <div style={{ height: '10px' }} />
        <div style={{ height: '32px', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
          <input
            className="SendEnquiry-input"
            placeholder={t('mypage-input-title')}
            value={enquiryTitle}
            onChange={(e) => {
              setEnquiryTitle(e.target.value);
            }}
          />
        </div>
        <div style={{ height: '13px' }} />
        <div className="SendEnquiry-label">{t('mypage-content-label')}</div>
        <div style={{ height: '10px' }} />
        <div style={{ height: '256px', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
          <textarea
            className="SendEnquiry-textarea"
            placeholder={t('mypage-input-content')}
            value={enquiryContent}
            onChange={(e) => {
              setEnquiryContent(e.target.value.substring(0, 100));
            }}
          />
        </div>
        <div style={{ height: '20px' }} />
        <div style={{ height: '44px', display: 'flex', alignItems: 'center' }}>
          <span style={{ margin: 'auto' }} />
          <button className={isPrivate ? 'SendEnquiry-toggle-on' : 'SendEnquiry-toggle-off'} onClick={() => setIsPrivate((val) => !val)} />
          <div style={{ width: '10px' }} />
          <div className="SendEnquiry-is-private-label">{t('send-enquiry-is-private-label')}</div>
          <div style={{ width: '20px' }} />
          <button className="SendEnquiry-send-btn" onClick={handleInquiry}>
            {t('send-enquiry-send-label')}
          </button>
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});
