import React, { ReactElement, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { resetPassword } from '../api';

import Logo from 'img/signup_logo.png';

import Footer from './Footer';

import { useEffect } from 'react';

export default withRouter(function ResetPassword(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const [token, setToken] = useState('');
  const [pw, setPw] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) setToken(token);
  }, []);

  async function resetPw() {
    const pwValue = pw ?? '';

    const result = await resetPassword(token, pwValue, 'individual');
    if (result && result) {
      alert('비밀번호 변경 성공!');
      props.history.push('/');
    } else {
      alert('비밀번호 변경 실패!');
    }
  }

  return (
    <div
      style={{
        backgroundColor: '#1d1d1d',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
      }}
    >
      <img src={Logo} style={{ width: '250px', height: '250px' }} />
      <div style={{ height: '20px' }} />
      <div className="Mypage-info-label">재설정할 비밀번호를 입력하세요.</div>
      <div style={{ height: '20px' }} />
      <div style={{ width: '300px', height: '30px' }}>
        <input className="Mypage-info-input" type="password" value={pw} onChange={(e) => setPw(e.target.value)} />
      </div>
      <div style={{ height: '10px' }} />
      <div style={{ width: '300px', height: '30px' }}>
        <button className="Mypage-wallet-innerbox-btn-full" onClick={resetPw}>
          비밀번호 재설정하기
        </button>
      </div>
    </div>
  );
});
