import dayjs from 'dayjs';
import BitcoinIcon from 'img/bitcoin_icon.png';
import LongIcon from 'img/long_icon.png';
import ShortIcon from 'img/short_icon.png';
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Rectangle, Tooltip, XAxis, YAxis } from 'recharts';
import {
  betFutureTrading,
  completeFutureTradingBet,
  FutureTradingBetInfo,
  FutureTradingResultInfo,
  getAccount,
  getBusinessAccount,
  getFutureTradingBets,
  getFutureTradingResults,
  getFutureTradingSettings,
} from '../api';
import '../css/Challenge.css';
import '../css/FutureTrading.css';
import Header from './Header';
import disableScroll from 'disable-scroll';
import d3, { ticks } from 'd3';
import OutsideClickHandler from 'react-outside-click-handler';

// images
import LongDot from 'img/dot_long.png';
import ShortDot from 'img/dot_short.png';

import IconLineChart from 'img/icon_chart_line.png';
import IconCandlestickChart from 'img/icon_chart_candlestick.png';
import IconBarChart from 'img/icon_chart_bars.png';

import IconLineChartSelected from 'img/icon_chart_line_gold.png';
import IconCandlestickChartSelected from 'img/icon_chart_candlestick_gold.png';
import IconBarChartSelected from 'img/icon_chart_bars_gold.png';

const StreamHost = 'wss://stream.binance.com:9443';
const ApiHost = 'https://api.binance.com/api/v3';

// Time
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

// data settings
const fps = 1;

// graph settings
// sensitivy (이 값 곱해서 줌 들어갈 것임)
const deltaWheel = 0.01;

// const MAX_DATA_COUNT = 5000;
const RightBound = 200;

const startPeriod = 15 * MINUTE;
const tickWidthMin = 5;
const tickWidthMax = 50;
const minTick = 1 * SECOND;

// Display Constants
const chartWidth = 1380;
const chartHeight = 670;
const yAxisWidth = 100;

interface PriceData {
  x: number;
  open: number;
  close: number;
  low: number;
  high: number;
  price: number;
  end: number;
}

interface AggregateData {
  M: boolean;
  T: number;
  a: number;
  f: number;
  l: number;
  m: boolean;
  p: string;
  q: string;
}

type ChartType = 'line' | 'candlestick';
const ChartIcons: { [key in ChartType]: '*.png' } = {
  line: IconLineChart,
  candlestick: IconCandlestickChart,
};
const SelectedChartIcons: { [key in ChartType]: '*.png' } = {
  line: IconLineChartSelected,
  candlestick: IconCandlestickChartSelected,
};

const Ticks: { tick: number; initial: string; label: string }[] = [
  { tick: 5 * SECOND, initial: '5s', label: '5초' },
  { tick: 10 * SECOND, initial: '10s', label: '10초' },
  { tick: 15 * SECOND, initial: '15s', label: '15초' },
  { tick: 30 * SECOND, initial: '30s', label: '30초' },
  { tick: 1 * MINUTE, initial: '1m', label: '1분' },
  { tick: 2 * MINUTE, initial: '2m', label: '2분' },
  { tick: 5 * MINUTE, initial: '5m', label: '5분' },
  { tick: 10 * MINUTE, initial: '10m', label: '10분' },
  { tick: 15 * MINUTE, initial: '15m', label: '15분' },
  { tick: 30 * MINUTE, initial: '30m', label: '30분' },
  { tick: 1 * HOUR, initial: '1h', label: '1시간' },
  { tick: 2 * HOUR, initial: '2h', label: '2시간' },
  { tick: 4 * HOUR, initial: '4h', label: '4시간' },
  { tick: 8 * HOUR, initial: '8h', label: '8시간' },
  { tick: 12 * HOUR, initial: '12h', label: '12시간' },
  { tick: 1 * DAY, initial: '1d', label: '1일' },
  { tick: 7 * DAY, initial: '7d', label: '1주' },
  { tick: 30 * DAY, initial: '30d', label: '1달' },
];

const Periods: { period: number; initial: string; label: string }[] = [
  { period: 2 * MINUTE, initial: '2m', label: '2분' },
  { period: 5 * MINUTE, initial: '5m', label: '5분' },
  { period: 15 * MINUTE, initial: '15m', label: '15분' },
  { period: 30 * MINUTE, initial: '30m', label: '30분' },
  { period: 3 * HOUR, initial: '3h', label: '3시간' },
  { period: 1 * DAY, initial: '1d', label: '1일' },
  { period: 30 * DAY, initial: '30d', label: '30일' },
];

const orderString: string[] = ['최신 순', '오래된 순', '수익 높은 순', '수익 낮은 순'];

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export default withRouter(function FutureTrading(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  // 계정
  const [account, setAccount] = useState<any | null>(null);
  const [pointC, setPointC] = useState(0);

  // 게임 설정
  const [bettingPoint, setBettingPoint] = useState<string | number>(100);
  useEffect(() => {
    let num: string | number = bettingPoint || 0;

    if (!isFinite(Number(num))) return;
    num = num.toString();
    if (num !== '0' && !num.includes('.')) {
      num = num.replace(/^0+/, '');
    }

    setBettingPoint(num);
  }, [bettingPoint]);

  const [multiplier, setMultiplier] = useState<number>(1);
  const [currentAsk, setCurrentAsk] = useState(0);
  const [currentBid, setCurrentBid] = useState(0);

  // 마우스 이벤트
  const isDragging = useRef(false);
  const currentPosX = useRef(0);
  const startPosX = useRef(0);

  // 일반
  const [loading, setLoading] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [rawData, setRawData] = useState<PriceData[]>([]);
  const isDataLoding = useRef<boolean>(false);

  // 프레임
  const [frame, setFrame] = useState(0);
  const timestamp = useRef(0);

  // 그래프 값
  const [spread, setSpread] = useState(0);

  // label
  const [mouseEnter, setMouseEnter] = useState('none');

  // UI 데이터
  const [tradeList, setTradeList] = useState<FutureTradingResultInfo[]>([]);
  const [bettingList, setBettingList] = useState<FutureTradingBetInfo[]>([]);
  const [order, setOrder] = useState(0);

  // // about graph
  const [domainX, setDomainX] = useState<[number, number]>([0, 0]);
  const [domainY, setDomainY] = useState<[number, number]>([0, 0]);

  // graph menu
  const [chartType, setChartType] = useState<ChartType>('line');
  const [chartMenu, setChartMenu] = useState<'none' | 'chart' | 'period' | 'tick'>('none');
  const [autoTick, setAutoTick] = useState(false);
  const [tick, setTick] = useState<number>(Ticks[0].tick);

  const periodLabel = useMemo(() => {
    const diff = [
      ...Periods.map(({ period, ...other }) => {
        const diff = Math.abs(period - (domainX[1] - domainX[0]));
        return { diff, ...other };
      }).sort((a, b) => {
        return a.diff - b.diff;
      }),
    ];
    return diff[0].initial;
  }, [domainX]);

  // 계정 관련
  useEffect(() => {
    (async () => {
      let account;
      if (localStorage.getItem('accountType') == 'individual') account = await getAccount();
      else account = await getBusinessAccount();

      if (!account) {
        alert(t('challenge-login-first'));
        props.history.push('/signin');
        return;
      }

      setAccount(account);
      setPointC(account.pointC);
    })();
    (async () => {
      const response = await getFutureTradingSettings();
      if (response) {
        setMultiplier(response.multiplier);
        setBettingPoint(response.point);
      }
    })();
    (async () => {
      const response = await getFutureTradingBets();
      if (response) setBettingList(response);
    })();
    (async () => {
      const response = await getFutureTradingResults();
      if (response) setTradeList(response);
    })();
  }, []);

  // Start
  useEffect(() => {
    // 웹 소켓 (실시간으로 가격 받아오기)
    const ws = new WebSocket(`${StreamHost}/stream`);

    ws.onopen = () => {
      console.log('connected!');
      ws.send(
        JSON.stringify({
          method: 'SUBSCRIBE',
          params: ['btcusdt@ticker'],
          id: Date.now(),
        }),
      );
    };

    ws.onmessage = (e) => {
      const json = JSON.parse(e.data);
      if (!json.data) return;
      // 현재 가격

      // 현재 ask 와 bid
      const ask = Number(json.data.a);
      const bid = Number(json.data.b);

      const date = json.data.E;
      // const open = Number(json.data.o);
      // const high = Number(json.data.h);
      // const low = Number(json.data.l);
      const close = Number(json.data.c);

      // add new data
      addData([
        {
          x: date,
          price: close,
          close: close,
          open: close,
          low: close,
          high: close,
          end: date,
        },
      ]);

      setCurrentAsk(ask);
      setCurrentBid(bid);
      setCurrentPrice(close);
    };

    ws.onclose = () => {
      console.log('disconnected!');
    };

    (async () => {
      const now = Date.now();
      const startTime = now - startPeriod;
      const endTime = now;

      const tickMin = (startPeriod * tickWidthMin) / (chartWidth - yAxisWidth);
      const ticks = Ticks.map((e) => e.tick).sort((a, b) => Math.abs(a - tickMin) - Math.abs(b - tickMin));
      const tick = ticks[0];
      setTick(tick);

      const boundTime = (startPeriod * RightBound) / (chartWidth - yAxisWidth);

      setDomainX([startTime + boundTime, endTime + boundTime]);
      getData(startTime - startPeriod, endTime, tick);
    })();
  }, []);

  // set spread when ask, bid is updated (mount)
  useEffect(() => {
    if (currentAsk === 0 || currentBid === 0) return;
    // setSpread(Math.floor(clamp((currentAsk - currentBid) * 100, 100, 1000)));
    let spread = (Math.log10(Math.abs(currentAsk - currentBid)) / 2 + 2) * Math.log10(Number(bettingPoint)) * 10; // => (-1 ~ 0.5) -> (1 ~ 4)
    spread = Math.floor(spread);
    setSpread(spread);
  }, [currentAsk, currentBid]);

  // update frame by delta time
  useEffect(() => {
    setTimeout(() => {
      setFrame((frame) => frame + 1);
    }, SECOND / fps);

    // frame
    if (loading) return;

    // do something
    if (isDragging.current) return;
    const now = Date.now();
    const deltaTime = now - timestamp.current;

    if (timestamp.current > domainX[0] && timestamp.current < domainX[1]) {
      setDomainX((domain) => {
        domain[0] += deltaTime;
        domain[1] += deltaTime;
        return [...domain];
      });
    }

    timestamp.current = now;
  }, [frame]);

  // rawData to klineData
  const klineData: PriceData[] = useMemo<PriceData[]>(() => {
    const originData = rawData.map(({ x, ...other }) => {
      const x2 = x - (x % tick);
      return { x, x2, ...other };
    });

    type ArrayElem<A> = A extends readonly (infer T)[] ? T : never;
    type T = ArrayElem<typeof originData>;
    const pivots = new Map<number, T[]>();

    for (const item of originData) {
      const arr = pivots.get(item.x2);
      if (arr) arr.push(item);
      else pivots.set(item.x2, [item]);
    }

    const keys = Array.from(pivots.keys()).sort((a, b) => a - b);

    const data = keys.map((key) => {
      const duplicates = pivots.get(key)!;
      const open = duplicates[0].open;
      const close = duplicates[duplicates.length - 1].close;
      const low = Math.min(...duplicates.map((e) => e.low));
      const high = Math.max(...duplicates.map((e) => e.high));
      return {
        open,
        close,
        low,
        high,
        price: close,
        x: key,
        end: key + tick,
      };
    });

    data.map((e, i) => {
      if (i + 1 < data.length) {
        const next = data[i + 1];
        e.close = next.open;
      }
    });

    return data;
  }, [rawData, tick]);

  const onPeriodBtn = (period: number) => {
    const tickMin = (period * tickWidthMin) / (chartWidth - yAxisWidth);
    const ticks = Ticks.map((e) => e.tick).sort((a, b) => Math.abs(a - tickMin) - Math.abs(b - tickMin));
    const tick = ticks[0];
    setTick(tick);

    console.log(ticks);
    console.log(tick);
    setDomainX((domain) => {
      const now = Date.now();
      if (now < domain[1]) {
        const ratio = period / (domain[1] - domain[0]);
        domain[1] = now + (domain[1] - now) * ratio;
        domain[0] = now - (now - domain[0]) * ratio;
      } else {
        domain[0] = domain[1] - period;
      }

      getData(domain[0], domain[1], tick);
      return [...domain];
    });
  };

  const onTickBtn = (tick: number) => {
    // tick setting 하면서 period 체크 하기
    setTick(tick);
    getData(domainX[0], domainX[1], tick);

    const periodMax = (tick / tickWidthMin) * (chartWidth - yAxisWidth);

    const periods = Periods.map((e) => e.period).sort((a, b) => Math.abs(a - periodMax) - Math.abs(b - periodMax));
    const period = periods[0];

    setDomainX((domain) => {
      const now = Date.now();
      if (now < domain[1]) {
        const ratio = period / (domain[1] - domain[0]);
        domain[1] = now + (domain[1] - now) * ratio;
        domain[0] = now - (now - domain[0]) * ratio;
      } else {
        domain[0] = domain[1] - period;
      }

      getData(domain[0], domain[1], tick);
      return [...domain];
    });
  };

  const onBetting = async (long: boolean) => {
    const response = await betFutureTrading(long ? 'long' : 'short', Number(bettingPoint), multiplier);
    if (response) {
      setBettingList((currentData) => {
        currentData.push(response);
        return [...currentData];
      });
      setPointC((val) => val - response.point);
    }
  };

  const addData = (newData: PriceData[]) => {
    setRawData((data) => {
      data = data.concat(newData);

      data.sort((a, b) => a.x - b.x);

      if (data.length > 10000) {
        const period = domainX[1] - domainX[0];
        data = data.filter((d) => d.x > domainX[0] - period && d.x < domainX[1] + period);
      }

      return [...data];
    });
  };

  const aggDatasToKlineDatas = (aggDatas: AggregateData[]): PriceData[] => {
    console.log(aggDatas);
    const tick = minTick;

    type ArrayElem<A> = A extends readonly (infer T)[] ? T : never;
    type T = ArrayElem<typeof aggDatas>;
    const pivots = new Map<number, T[]>();

    for (const item of aggDatas) {
      const key = Math.floor(item.T / tick) * tick;
      const arr = pivots.get(key);

      if (arr) arr.push(item);
      else pivots.set(key, [item]);
    }

    const keys = Array.from(pivots.keys()).sort((a, b) => a - b);

    const data = keys.map((key) => {
      const duplicates = pivots.get(key)!;
      const open = Number(duplicates[0].p);
      const close = Number(duplicates[duplicates.length - 1].p);
      const low = Math.min(...duplicates.map((e) => Number(e.p)));
      const high = Math.max(...duplicates.map((e) => Number(e.p)));
      return {
        open,
        close,
        low,
        high,
        price: close,
        x: key,
        end: key + tick,
      };
    });

    data.map((e, i) => {
      if (i + 1 < data.length) {
        const next = data[i + 1];
        e.close = next.open;
      }
    });

    return data;
  };

  const getData = async (startTime: number, endTime: number, tick: number) => {
    if (isDataLoding.current) return;

    console.log(`get Data!, startTime: ${startTime}, endTime: ${endTime}`);

    isDataLoding.current = true;

    if (endTime > Date.now()) endTime = Date.now();
    startTime = Math.floor(startTime);
    endTime = Math.floor(endTime);
    // get data from binance api
    if (tick < MINUTE) {
      if (endTime - startTime > HOUR) {
        while (endTime > startTime) {
          // 데이터 받고
          const response = await fetch(
            `${ApiHost}/aggTrades?symbol=BTCUSDT&startTime=${Math.max(endTime - HOUR, startTime)}&endTime=${endTime}`,
          );
          if (response.status != 200) {
            console.log(await response.text());
            return;
          }
          const aggDatas: Array<AggregateData> = await response.json();
          console.log(aggDatas);

          addData(aggDatasToKlineDatas(aggDatas));

          endTime -= HOUR;
        }
      } else {
        const response = await fetch(`${ApiHost}/aggTrades?symbol=BTCUSDT&startTime=${startTime}&endTime=${endTime}`);
        if (response.status != 200) {
          console.log(await response.text());
          return;
        }
        const aggDatas: Array<AggregateData> = await response.json();

        addData(aggDatasToKlineDatas(aggDatas));
      }
    } else {
      let interval = '1m';
      if (tick > DAY) {
        interval = '1d';
      } else if (tick > 12 * HOUR) {
        interval = '12h';
      } else if (tick > 8 * HOUR) {
        interval = '8h';
      } else if (tick > 6 * HOUR) {
        interval = '6h';
      } else if (tick > 4 * HOUR) {
        interval = '4h';
      } else if (tick > 2 * HOUR) {
        interval = '2h';
      } else if (tick > HOUR) {
        interval = '1h';
      } else if (tick > 30 * MINUTE) {
        interval = '30m';
      } else if (tick > 15 * MINUTE) {
        interval = '15m';
      } else if (tick > 5 * MINUTE) {
        interval = '5m';
      } else if (tick > 3 * MINUTE) {
        interval = '3m';
      }

      const response = await fetch(`${ApiHost}/klines?symbol=BTCUSDT&interval=${interval}&startTime=${startTime}&endTime=${endTime}`);
      if (response.status != 200) {
        console.log(await response.text());
        return;
      }
      const klineData: Array<Array<any>> = await response.json();
      addData(
        klineData.map((e, i) => {
          const date = e[0];
          const open = Number(e[1]);
          const high = Number(e[2]);
          const low = Number(e[3]);
          const close = Number(e[4]);
          return {
            x: date,
            price: close,
            open,
            close,
            low,
            high,
            end: date + tick,
          };
        }),
      );
    }

    isDataLoding.current = false;
  };

  const getTopPos = (price: number): number => {
    const data = rawData.filter((d) => d.x > domainX[0] - tick && d.x < domainX[1] + tick);
    const min = Math.min(...data.map((e) => e.price), currentPrice - spread / 2);
    const max = Math.max(...data.map((e) => e.price), currentPrice + spread / 2);

    return ((max - price) / (max - min)) * chartHeight;
  };

  const getHeight = (): number => {
    const data = rawData.filter((d) => d.x > domainX[0] - tick && d.x < domainX[1] + tick);
    const min = Math.min(...data.map((e) => e.price), currentPrice - spread / 2);
    const max = Math.max(...data.map((e) => e.price), currentPrice + spread / 2);

    return (spread * chartHeight) / (max - min);
  };

  const onZoom = (e: any) => {
    setDomainX((domain) => {
      const periodMax = (tick * (chartWidth - yAxisWidth)) / tickWidthMin;
      const periodMin = (tick * (chartWidth - yAxisWidth)) / tickWidthMax;
      const ratioMax = periodMax / (domain[1] - domain[0]);
      const ratioMin = periodMin / (domain[1] - domain[0]);

      let ratio = 1 + Math.sign(e.deltaY) * deltaWheel;

      if (ratio >= ratioMax) {
        // 축소
        // autoTick 켜져있으면 tick 을 다음단계로 조절해야할 듯?
        if (autoTick) {
          const curTickIdx = Ticks.findIndex((e) => e.tick == tick);
          if (curTickIdx == Ticks.length - 1) {
            // 제일 큼
            ratio = ratioMax;
          } else {
            setTick(Ticks[curTickIdx + 1].tick);
          }
        } else {
          ratio = ratioMax;
        }
      } else if (ratio <= ratioMin) {
        // 확대
        if (autoTick) {
          const curTickIdx = Ticks.findIndex((e) => e.tick == tick);
          if (curTickIdx == 0) {
            // 제일 짧음
            ratio = ratioMin;
          } else {
            setTick(Ticks[curTickIdx - 1].tick);
          }
        } else {
          ratio = ratioMin;
        }
      }

      let date = Date.now();

      if (date > domain[1]) {
        date = domain[0] + (currentPosX.current / (chartWidth - yAxisWidth)) * (domain[1] - domain[0]);
      }
      domain[1] = date + (domain[1] - date) * ratio;
      domain[0] = date - (date - domain[0]) * ratio;

      return [...domain];
    });
  };

  const onDrag = (e: any) => {
    if (e && e.chartX) {
      if (isDragging.current) {
        // moveChart(e.chartX);
        const deltaX = e.chartX - currentPosX.current;
        const deltaTime = ((domainX[1] - domainX[0]) * deltaX) / (chartWidth - yAxisWidth);
        setDomainX((d) => {
          d[0] -= deltaTime;
          d[1] -= deltaTime;

          const bound = ((d[1] - Date.now()) * (chartWidth - yAxisWidth)) / (d[1] - d[0]);
          // console.log(bound);
          if (bound > RightBound) {
            const diff = ((bound - RightBound) * (d[1] - d[0])) / (chartWidth - yAxisWidth);
            d[0] -= diff;
            d[1] -= diff;
          }
          return [...d];
        });
      }
      currentPosX.current = e.chartX;
    }
  };

  useEffect(() => {
    // 범위랑 캔들 기간 바뀔 때 마다 비어있는 데이터 있는지 확인해야하고
    // 법위가 넓은 상태에서 짧은 상태로 바뀐거면 그걸 비우고 데이터를 채워야함
    // tick 이 줄었는지 넓어졌는지 어케 알 것?????
    if (isDataLoding.current) return;

    const now = Date.now();

    const left = domainX[0];
    const right = Math.min(domainX[1], now);
    // 현재 표시된 데이터들임 (klineData 기준)
    const clampedData = klineData.filter((d) => d.x >= left && d.x <= right);

    if (clampedData.length > 0) {
      const minX = clampedData[0].x;
      const maxX = clampedData[clampedData.length - 1].x;

      if (minX > left + tick) {
        getData(minX - (domainX[1] - domainX[0]), minX, tick);
      }
      if (maxX < right - tick) {
        getData(maxX, maxX + (domainX[1] - domainX[0]), tick);
      }

      if (clampedData.length < (maxX - minX) / tick) {
        getData(minX, maxX, tick);
      }
    } else {
      getData(domainX[0], domainX[1], tick);
    }
  }, [domainX, tick]);

  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} />
      <div
        style={{
          minWidth: '1920px',
          height: '1080px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          backgroundColor: '#1c1b1a',
          whiteSpace: 'pre-line',
        }}
      >
        <Header />
        {loading ? (
          <span style={{ margin: 'auto' }}>
            <div style={{ alignItems: 'center', justifyContent: 'center', color: 'white' }}>로딩중...</div>
          </span>
        ) : (
          <div style={{ margin: '20px auto', display: 'flex', justifyContent: 'center', alignItems: 'stretch' }}>
            <div className="FutureTrading-list">
              <div className="title">퓨처트레이딩</div>
              <div className="line" />
              <div className="sort">거래 내역</div>
              <div className="dropdown">
                <button className="dropdown-button">{orderString[order]}</button>
                <div className="dropdown-content">
                  <button onClick={() => setOrder(0)}>{orderString[0]}</button>
                  <button onClick={() => setOrder(1)}>{orderString[1]}</button>
                  <button onClick={() => setOrder(2)}>{orderString[2]}</button>
                  <button onClick={() => setOrder(3)}>{orderString[3]}</button>
                </div>
              </div>
              <div className="line" />
              <div style={{ margin: '10px 0', overflow: 'auto' }}>
                {tradeList
                  .sort((a, b) =>
                    order == 0
                      ? b.createdAt.getTime() - a.createdAt.getTime()
                      : order == 1
                      ? a.createdAt.getTime() - b.createdAt.getTime()
                      : order == 2
                      ? Math.floor(b.resultPoint - b.point) - Math.floor(a.resultPoint - a.point)
                      : Math.floor(a.resultPoint - a.point) - Math.floor(b.resultPoint - b.point),
                  )
                  .map((e, i) => TradeTicket(e))}
              </div>
            </div>
            <div style={{ width: '20px' }} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
              {/* 여기가 그래프 그릴 영역 */}
              <div style={{ width: '100%', height: '670px', display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
                {/* 쉽게 생각하면 여기가 창문 */}
                <div
                  className="FutureTrading-chart"
                  onWheel={(e) => onZoom(e)}
                  onMouseEnter={() => {
                    disableScroll.on();
                  }}
                  onMouseLeave={(e) => {
                    disableScroll.off();
                  }}
                >
                  {chartType == 'line' ? (
                    <AreaChart
                      width={chartWidth}
                      height={chartHeight}
                      data={klineData.filter((d) => d.x > domainX[0] - tick && d.x < domainX[1] + tick)}
                      margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                      onMouseDown={(e: any) => {
                        if (e.chartX) {
                          isDragging.current = true;
                          currentPosX.current = e.chartX;
                        }
                      }}
                      onMouseMove={(e: any) => onDrag(e)}
                      onMouseUp={(e: any) => {
                        isDragging.current = false;
                      }}
                      onMouseLeave={(e: any) => {
                        if (isDragging.current) {
                          isDragging.current = false;
                          currentPosX.current = -1;
                        }
                      }}
                    >
                      <defs>
                        <linearGradient id="fill" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#ffffff" stopOpacity={0.5} />
                          <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                      <XAxis
                        dataKey="x"
                        mirror={true}
                        tickSize={2}
                        opacity="1"
                        scale="time"
                        domain={domainX}
                        type="number"
                        allowDataOverflow={true}
                        tickFormatter={(t) => dayjs(t).format('HH:mm:ss')}
                      />
                      <YAxis
                        dataKey="price"
                        type="number"
                        mirror={false}
                        orientation={'right'}
                        allowDecimals={false}
                        allowDataOverflow={true}
                        width={yAxisWidth}
                        domain={[
                          (dataMin: number) => {
                            if (Date.now() < domainX[1]) return Math.min(dataMin, currentPrice - spread / 2);
                            else return dataMin;
                          },
                          (dataMax: number) => {
                            if (Date.now() < domainX[1]) return Math.max(dataMax, currentPrice + spread / 2);
                            else return dataMax;
                          },
                        ]}
                        fontSize={20}
                      />
                      <CartesianGrid opacity="0.15" />
                      <Tooltip
                        wrapperStyle={{ background: 'none', border: 'none' }}
                        contentStyle={{ backgroundColor: '#242322', boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.35)', borderRadius: '4px' }}
                        itemStyle={{ color: 'white' }}
                        labelStyle={{ color: 'white' }}
                        separator={''}
                        filterNull={false}
                        formatter={function (value: number | null, name: string) {
                          if (value == null) return ['', ''];
                          else return [`${t('challenge-tooltip-price')}: ${Math.floor(value * 100) / 100}`, ''];
                        }}
                        labelFormatter={function (value) {
                          return `${t('challenge-tooltip-time')}: ${dayjs(value).format('HH:mm:ss')}`;
                        }}
                      />
                      <Area
                        type="monotone"
                        connectNulls={true}
                        dataKey="price"
                        stroke="#ffffff"
                        fillOpacity={1}
                        fill="url(#fill)"
                        strokeWidth={1}
                        isAnimationActive={false}
                      />
                    </AreaChart>
                  ) : chartType == 'candlestick' ? (
                    <BarChart
                      width={chartWidth}
                      height={chartHeight}
                      data={klineData
                        .filter((d) => d.x > domainX[0] - tick && d.x < domainX[1] + tick)
                        .map(({ open, close, ...other }) => {
                          return { ...other, openClose: [open, close] };
                        })}
                      margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                      onMouseDown={(e: any) => {
                        if (e.chartX) {
                          isDragging.current = true;
                          currentPosX.current = e.chartX;
                        }
                      }}
                      onMouseMove={(e: any) => onDrag(e)}
                      onMouseUp={(e: any) => {
                        isDragging.current = false;
                      }}
                      onMouseLeave={(e: any) => {
                        if (isDragging.current) {
                          isDragging.current = false;
                          currentPosX.current = -1;
                        }
                      }}
                    >
                      <XAxis
                        dataKey="x"
                        mirror={true}
                        tickSize={2}
                        opacity="1"
                        scale="time"
                        domain={domainX}
                        type="number"
                        allowDataOverflow={true}
                        // ticks={d3
                        //   .scaleTime([startTime, endTime])
                        //   .ticks(d3.timeHour.every(1)!)
                        //   .map((e) => e.getTime())}
                        // minTickGap={40}
                        tickFormatter={(t) => dayjs(t).format('HH:mm:ss')}
                      />
                      <YAxis
                        dataKey="price"
                        type="number"
                        mirror={false}
                        orientation={'right'}
                        allowDecimals={false}
                        allowDataOverflow={true}
                        width={yAxisWidth}
                        domain={[
                          (dataMin: number) => Math.min(dataMin, currentPrice - spread / 2),
                          (dataMax: number) => Math.max(dataMax, currentPrice + spread / 2),
                        ]}
                        fontSize={20}
                      />
                      <CartesianGrid opacity="0.15" />
                      <Bar dataKey="openClose" isAnimationActive={false} shape={<OpenCloseBar />} />
                      {/* <Bar dataKey="lowhigh" isAnimationActive={false} shape={<LowHighBar />} /> */}
                    </BarChart>
                  ) : (
                    <></>
                  )}

                  <img src={BitcoinIcon} className="icon" />

                  <div className="label">BTC/USD(Binance)</div>
                  {/* 
                  <div className="btns">
                    <button onClick={() => onTimeUnitBtn(MINUTE)}>1분</button>
                    <button onClick={() => onTimeUnitBtn(5 * MINUTE)}>5분</button>
                    <button onClick={() => onTimeUnitBtn(15 * MINUTE)}>15분</button>
                    <button onClick={() => onTimeUnitBtn(30 * MINUTE)}>30분</button>
                    <button onClick={() => onTimeUnitBtn(HOUR)}>1시간</button>
                    <button onClick={() => onTimeUnitBtn(6 * HOUR)}>6시간</button>
                    <button onClick={() => onTimeUnitBtn(DAY)}>1일</button>
                  </div> */}

                  <div
                    style={{
                      width: `${chartWidth}px`,
                      height: '20px',
                      position: 'absolute',
                      left: '0',
                      top: `${getTopPos(currentPrice) - 10}px`,
                      display: 'flex',
                      alignItems: 'center',
                      pointerEvents: 'none',
                      zIndex: 2,
                    }}
                  >
                    <div style={{ width: `${chartWidth}px`, border: 'solid 1px #ffffff', opacity: 0.75 }}></div>
                    <div className="FutureTrading-pricetag">{Math.floor(currentPrice * 100) / 100}</div>
                  </div>

                  {bettingList.map((e, i) => {
                    const time = e.createdAt.getTime();
                    // if (time < domainX[0] || time > domainX[1]) return <></>;

                    const left = ((time - domainX[0]) * (chartWidth - yAxisWidth)) / (domainX[1] - domainX[0]);
                    let top = getTopPos(e.priceAtBet);
                    if (top < 0) top = 0;
                    if (top > chartHeight) top = chartHeight;

                    return (
                      <>
                        <div
                          style={{
                            width: `${chartWidth}px`,
                            height: '20px',
                            position: 'absolute',
                            left: '0',
                            top: `${top}px`,
                            display: 'flex',
                            alignItems: 'center',
                            pointerEvents: 'none',
                          }}
                          key={e.id}
                        >
                          <div
                            style={{ width: `${chartWidth}px`, border: `1px solid ${e.long ? '#91bc6a' : '#c96161'}`, opacity: 0.75 }}
                          ></div>
                          <div className={e.long ? 'FutureTrading-pricetag-long' : 'FutureTrading-pricetag-short'}>
                            {Math.floor(e.priceAtBet * 100) / 100}
                          </div>
                        </div>
                        {time > domainX[0] && time < domainX[1] && (
                          <>
                            {/* <div
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: left,
                                width: 0,
                                height: `${displayAreaHeight}px`,
                                border: `1px solid ${e.long ? '#91bc6a' : '#c96161'}`,
                                zIndex: 1,
                              }}
                            /> */}
                            <img
                              style={{
                                position: 'absolute',
                                top: top - 15,
                                left: left - 23,
                                width: '50px',
                                height: '50px',
                                padding: 0,
                                border: 'none',
                                zIndex: 2,
                              }}
                              src={e.long ? LongDot : ShortDot}
                            />
                          </>
                        )}
                      </>
                    );
                  })}

                  {Date.now() < domainX[1] && (
                    <div
                      style={{
                        position: 'absolute',
                        right: `${yAxisWidth}px`,
                        width: `${
                          ((domainX[1] - Math.floor(Date.now() / tick) * tick - (chartType == 'candlestick' ? tick / 2 : 0)) *
                            (chartWidth - yAxisWidth)) /
                          (domainX[1] - domainX[0])
                        }px`,
                        height: `${getHeight()}px`,
                        top: `${getTopPos(currentPrice + spread / 2)}px`,
                        pointerEvents: 'none',
                      }}
                    >
                      <div className="spread" />
                    </div>
                  )}

                  {/* 메뉴 */}
                  <OutsideClickHandler onOutsideClick={() => setChartMenu('none')}>
                    <div className="chart-menu">
                      <button disabled={chartMenu == 'chart'} onClick={() => setChartMenu('chart')}>
                        <img src={ChartIcons[chartType]} />
                      </button>
                      <div style={{ height: '5px' }} />
                      <button disabled={chartMenu == 'tick'} onClick={() => setChartMenu('tick')}>
                        <div className="text">{Ticks.find((e) => e.tick == tick)?.initial}</div>
                      </button>
                      <div style={{ height: '5px' }} />
                      <button disabled={chartMenu == 'period'} onClick={() => setChartMenu('period')}>
                        <div className="text">{periodLabel}</div>
                      </button>
                    </div>
                    {chartMenu == 'chart' ? (
                      <div className="chart-select">
                        <button
                          onClick={() => {
                            setChartMenu('none');
                            setChartType('line');
                          }}
                          disabled={chartType == 'line'}
                        >
                          <div style={{ width: '7px' }} />
                          <img src={chartType == 'line' ? IconLineChartSelected : IconLineChart} />
                          <div style={{ width: '7px' }} />
                          <h1>라인</h1>
                          <span style={{ margin: 'auto' }} />
                        </button>
                        <button
                          onClick={() => {
                            setChartMenu('none');
                            setChartType('candlestick');
                          }}
                          disabled={chartType == 'candlestick'}
                        >
                          <div style={{ width: '7px' }} />
                          <img src={chartType == 'candlestick' ? IconCandlestickChartSelected : IconCandlestickChart} />
                          <div style={{ width: '7px' }} />
                          <h1>촛대</h1>
                          <span style={{ margin: 'auto' }} />
                        </button>
                        {/* <button
                          onClick={() => {
                            setChartMenu('none');
                            setChartType('bar');
                          }}
                          disabled={chartType == 'bar'}
                        >
                          <div style={{ width: '7px' }} />
                          <img src={chartType == 'bar' ? IconBarChartSelected : IconBarChart} />
                          <div style={{ width: '7px' }} />
                          <h1>바 차트</h1>
                          <span style={{ margin: 'auto' }} />
                        </button>
                        <button
                          onClick={() => {
                            setChartMenu('none');
                            setChartType('heikin-ashi');
                          }}
                          disabled={chartType == 'heikin-ashi'}
                        >
                          <div style={{ width: '7px' }} />
                          <img src={chartType == 'heikin-ashi' ? IconCandlestickChartSelected : IconCandlestickChart} />
                          <div style={{ width: '7px' }} />
                          <h1>Heikin-Ashi</h1>
                          <span style={{ margin: 'auto' }} />
                        </button> */}
                      </div>
                    ) : chartMenu == 'tick' ? (
                      <div className="tick-select">
                        <h1>캔들 기간</h1>
                        <div style={{ height: '4px' }} />
                        <h2>* 특정 시간 간격의 캔들과 바 모두에 사용되는 가격범위입니다.</h2>
                        <div style={{ height: '10px' }} />
                        <div className="buttons">
                          {Ticks.map((e, i) => {
                            return (
                              <button
                                onClick={() => {
                                  console.log(e.tick);
                                  // setTickIndex(i);
                                  onTickBtn(e.tick);
                                  setChartMenu('none');
                                }}
                                disabled={tick == e.tick}
                                key={`tick${e.initial}`}
                              >
                                {e.label}
                              </button>
                            );
                          })}
                        </div>
                        <div style={{ height: '10px' }} />
                        <div className="auto">
                          <button className={autoTick ? 'on' : 'off'} onClick={() => setAutoTick((auto) => !auto)} />
                          <div style={{ width: '10px' }} />
                          <h1>자동 크기조절</h1>
                        </div>
                      </div>
                    ) : chartMenu == 'period' ? (
                      <div className="period-select">
                        <h1>기간</h1>
                        <div style={{ height: '4px' }} />
                        <h2>* 차트가 표시되는 시간 기간입니다.</h2>
                        <div style={{ height: '10px' }} />
                        <div className="buttons">
                          {Periods.map((e, i) => {
                            return (
                              <button
                                onClick={() => {
                                  console.log(e.period);
                                  // setPeriodIndex(i);
                                  onPeriodBtn(e.period);
                                  setChartMenu('none');
                                }}
                                // disabled={false}
                                key={`period${e.initial}`}
                              >
                                {e.label}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </OutsideClickHandler>
                </div>

                <div style={{ width: '20px' }} />
                <div className="FutureTrading-menu">
                  <h1>보유 금액</h1>
                  <div style={{ height: '4px' }} />
                  <h2>{pointC.toLocaleString()}P</h2>
                  <div style={{ height: '20px' }} />
                  <div className="box">
                    <h1>금액 (P)</h1>
                    <input
                      value={bettingPoint}
                      onChange={(e) => {
                        setBettingPoint(e.target.value);
                      }}
                      type="number"
                    />
                  </div>
                  <div style={{ height: '10px' }} />
                  <div className="box">
                    <h1>배율</h1>
                    <div className="dropdown">
                      <button className="dropdown-button">x{multiplier}</button>
                      <div className="dropdown-content">
                        <button onClick={() => setMultiplier(1)}>x1</button>
                        <button onClick={() => setMultiplier(2)}>x2</button>
                        <button onClick={() => setMultiplier(3)}>x3</button>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '10px' }} />
                  <div className="box">
                    <h1>볼륨</h1>
                    <h2>{Number(bettingPoint) * multiplier}P</h2>
                  </div>
                  <div style={{ height: '20px' }} />
                  <button
                    style={{ width: '125px', height: '125px', background: 'none', padding: '0', border: 'none' }}
                    onClick={() => onBetting(true)}
                    onMouseEnter={() => {
                      setMouseEnter('long');
                    }}
                    onMouseLeave={() => {
                      setMouseEnter('none');
                    }}
                  >
                    <img src={LongIcon} />
                  </button>
                  <div style={{ height: '10px' }} />
                  <button
                    style={{ width: '125px', height: '125px', background: 'none', padding: '0', border: 'none' }}
                    onClick={() => onBetting(false)}
                    onMouseEnter={() => {
                      setMouseEnter('short');
                    }}
                    onMouseLeave={() => {
                      setMouseEnter('none');
                    }}
                  >
                    <img src={ShortIcon} />
                  </button>
                  <div style={{ height: '20px' }} />
                  <div className="box">
                    <h1>스프레드</h1>
                    <h2>{spread}</h2>
                  </div>
                  <span style={{ margin: 'auto' }} />
                  {/* <button className="sell" onClick={() => setShowBettingList(!showBettingList)}>
              완료하기 <br />({`${bettingList.length}`})
            </button> */}
                </div>
              </div>
              <div style={{ height: '20px' }} />
              <div className="FutureTrading-positions">
                <div className="title">활성({bettingList.length})</div>
                <div className="line" />
                <div className="cols">
                  <h1>이름</h1>
                  <h1>유형</h1>
                  <h1>날짜</h1>
                  <h1>양</h1>
                  <h1>시작</h1>
                  <h1>현재 가격</h1>
                  <h1>수익 실현</h1>
                  <h1>순 이익/손실</h1>
                  <h1></h1>
                </div>
                <div className="line" />
                <span style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                  {bettingList.map((e, i) => (
                    <BettingPosition
                      info={e}
                      username={account ? account.username : ''}
                      currentPrice={currentPrice}
                      onComplete={async () => {
                        const response = await completeFutureTradingBet(e.id);
                        if (response) {
                          console.log(response);
                          setBettingList((currentData) => {
                            currentData.splice(currentData.indexOf(e), 1);
                            return [...currentData];
                          });
                          setTradeList((currentData) => {
                            currentData.push(response);
                            return [...currentData];
                          });
                          setPointC((val) => val + Math.floor(response.resultPoint));
                        }
                      }}
                    />
                  ))}
                </span>
              </div>
            </div>
          </div>
        )}
        <footer className="Challenge-footer">
          <div
            style={{ width: '1100px', height: '76px', display: 'flex', margin: '0 auto', alignItems: 'center', justifyContent: 'center' }}
          >
            <div className="Challenge-footer-copyrights">ⓒ MAX SIGNAL 2021 All rights reserved.</div>
            <div style={{ width: '40px' }} />
            <div className="Challenge-footer-support-title">{t('footer-support')}</div>
            <div style={{ width: '10px' }} />
            <div className="Challenge-footer-support">maxsignalhongkong@gmail.com</div>
          </div>
        </footer>
      </div>
    </>
  );
});

// UI Components
const TradeTicket = (props: FutureTradingResultInfo) => {
  let benefit = Math.floor(props.resultPoint - props.point);
  return (
    <div className="ticket" key={props.id}>
      <div className="point">
        {benefit > 0 ? <h1>+{benefit}</h1> : <h2>{benefit}</h2>}
        <h3>{dayjs(props.createdAt).format('YYYY-MM-DD HH:mm')}</h3>
      </div>
      <span style={{ margin: 'auto' }} />
      <h4>{props.point}p</h4>
      <div style={{ width: '5px' }} />
      {props.long ? <div className="arrow-long" /> : <div className="arrow-short" />}
    </div>
  );
};

const BettingPosition = (props: { username: string; info: FutureTradingBetInfo; currentPrice: number; onComplete: () => void }) => {
  const percent = (props.currentPrice / props.info.priceAtBet - 1) * (props.info.long ? 1 : -1);
  const result = Math.max(0, (percent * props.info.multiplier + 1) * props.info.point) - props.info.point;

  return (
    <div className={props.info.long ? 'long' : 'short'} key={props.info.id}>
      <h1>{props.username}</h1>
      {props.info.long ? <h2>LONG</h2> : <h3>SHORT</h3>}
      <h1>{dayjs(props.info.createdAt).format('YYYY-MM-DD HH:mm')}</h1>
      <h1>{props.info.point}P</h1>
      <h1>{props.info.priceAtBet}$</h1>
      <h1>{props.currentPrice}$</h1>
      {percent > 0 ? <h2>{percent.toFixed(2)}%</h2> : <h3>{percent.toFixed(2)}%</h3>}
      {result > 0 ? <h2>{result.toFixed(0)}P</h2> : <h3>{result.toFixed(0)}P</h3>}
      <button onClick={props.onComplete}>완료하기</button>
    </div>
  );
};

const OpenCloseBar = (props: any) => {
  const {
    openClose: [open, close],
    low,
    high,
    x,
    y,
    width,
    height,
  } = props;
  const isGrowing = open < close;
  const color = isGrowing ? 'green' : 'red';
  const ratio = open === close ? 0 : Math.abs(height / (open - close));

  return (
    <g stroke={color} fill={'none'} strokeWidth="2">
      <path
        fill={color}
        d={`
          M ${x},${y}
          L ${x},${y + height}
          L ${x + width},${y + height}
          L ${x + width},${y}
          L ${x},${y}
        `}
      />
      {/* bottom line */}
      {isGrowing ? (
        <path
          d={`
            M ${x + width / 2}, ${y + height}
            v ${(open - low) * ratio}
          `}
        />
      ) : (
        <path
          d={`
            M ${x + width / 2}, ${y}
            v ${(close - low) * ratio}
          `}
        />
      )}
      {/* top line */}
      {isGrowing ? (
        <path
          d={`
            M ${x + width / 2}, ${y}
            v ${(close - high) * ratio}
          `}
        />
      ) : (
        <path
          d={`
            M ${x + width / 2}, ${y + height}
            v ${(open - high) * ratio}
          `}
        />
      )}
    </g>
  );
};
