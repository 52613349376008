import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ChallengeImg from 'img/challenge_img.png';
import IntroduceBanner from 'img/main_introduce_banner.png';

import MainBanner from 'img/main_banner_2.png';
import DownArrow from 'img/main_down_arrow.png';

import Logo from 'img/signup_logo.png';
import SmallLogo from 'img/logo.png';

import DecoLeft from 'img/main_deco_left.png';
import DecoRight from 'img/main_deco_right.png';

import Screenshot1 from 'img/main_screenshot_1.png';
import Screenshot2 from 'img/main_screenshot_2.png';
import Screenshot3 from 'img/main_screenshot_3.png';

import { signout } from '../api';
import TokenContext from '../token-context';
import '../css/Main.css';

import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Main(): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);

  return (
    <div className="App">
      <Header backgroundColor={'#000'} />
      <div className="Main-banner">
        <img src={MainBanner} className="banner" />
        <div className="more">More Information</div>
        <img src={DownArrow} className="arrow" />

        <div className="main">
          <img src={Logo} className="logo" />
          <div style={{ height: '20px' }} />
          <h1>Digital Trading Education Platform</h1>
          <h2>디지털 트레이딩 교육 플랫폼</h2>
          <div style={{ height: '56px' }} />
          <div className="buttons">
            <Link to="/challenge-participate" className="link1">
              MAX4 챌린지 참여
            </Link>
            <div style={{ width: '10px' }} />
            <Link to="/future-trading" className="link1">
              퓨처 트레이딩 참여
            </Link>
          </div>
        </div>
      </div>

      <div className="Main-introduce">
        <div className="deco">
          <img src={DecoRight} className="right" />
          <img src={DecoLeft} className="left" />
        </div>
        <div className="main">
          <div className="group">
            <div className="circle" />
            <div style={{ height: '15px' }} />
            <h1>Try with $10 demo points</h1>
            <div style={{ height: '10px' }} />
            <h2>{'MAX4 Developed to train professional\ntraders and trading instructors'}</h2>
          </div>
          <div style={{ width: '60px' }} />
          <div className="group">
            <div className="circle" />
            <div style={{ height: '15px' }} />
            <h1>Reset your trading style with MAX4</h1>
            <div style={{ height: '10px' }} />
            <h2>
              {
                ' MAX4 is a digital trading education platform \ndeveloped to increase the winning rate to \npursue stable investment returns.'
              }
            </h2>
          </div>
          <div style={{ width: '60px' }} />
          <div className="group">
            <div className="circle" />
            <div style={{ height: '15px' }} />
            <h1>Winning habit will be developed</h1>
            <div style={{ height: '10px' }} />
            <h2>{'MAX4 is not a traditional Demo contest or \nlive competition method that is evaluated \nbased on returns.'}</h2>
          </div>
        </div>
      </div>

      <div className="Main-about">
        <img src={SmallLogo} className="logo" />
        <div style={{ height: '10px' }} />
        <h1>About MAX4</h1>
        <div style={{ height: '10px' }} />
        <h2>
          {
            '수익률을 기준으로 평가 받는 전통적이고 보수적인 모의 대회나\n 라이브 대회 방식이 아닌 승률을 높여서 안정적인 투자수익을\n 추구할 수 있도록 개발된 디지털 트레이딩 교육 플랫폼 MAX4는\n 전문 트레이더 및 트레이딩 강사를 육성하기 위해 개발되었습니다.'
          }
        </h2>
        <div style={{ height: '60px' }} />
        <div className="boxes">
          <div className="box">
            <div className="circle" />
            <div style={{ height: '24px' }} />
            <h1>초 단타 매매(스캘핑) 실력 향상</h1>
            <div style={{ height: '10px' }} />
            <h2>
              변동성이 강한 하이로우 방식의 옵션 트레이딩을 통해 BTC 시세를 예측하고 트레이딩함으로써 트레이더는 챌린지에 참가 할수록
              트레이딩에 필요한 정확한 진입 포인트를 예측할 수 있어 초 단타매매(스켈핑) 실력이 향상됩니다.
            </h2>
          </div>
          <div style={{ width: '20px' }} />
          <div className="box">
            <div className="circle" />
            <div style={{ height: '24px' }} />
            <h1>트레이딩 실력 평가</h1>
            <div style={{ height: '10px' }} />
            <h2>
              시대가 발전함에따라 투자처의 다변화로 투자하기 전 자신의 트레이딩 실력을 객관적으로 평가 받아 무분별한 투자의 위험 에서 손실을
              방지할 수 있습니다.
            </h2>
          </div>
          <div style={{ width: '20px' }} />
          <div className="box">
            <div className="circle" />
            <div style={{ height: '24px' }} />
            <h1>실력에 따라 트레이딩 포인트 지급</h1>
            <div style={{ height: '10px' }} />
            <h2>돈이 없어도 실력만 있으면 챌린지에 참가하여 트레이딩 레벨에 따라 트레이딩 포인트를 지급 받아 트레이딩을할 수 있습니다.</h2>
          </div>
        </div>
      </div>

      <div className="Main-games">
        <div className="group">
          <h1>MAX4 챌린지</h1>
          <div style={{ height: '16px' }} />
          <h2>
            수익률을 기준으로 평가 받는 전통적이고 보수적인 모의 대회 라이브 대회 방식이 아닌 승률을 높여서 안정적인 투자수익을 추구할 수
            있도록 개발된 디지털 트레이딩
          </h2>
          <div style={{ height: '40px' }} />
          <img src={Screenshot1} className="screenshot" />
          <div style={{ height: '21px' }} />
          <Link to="/challenge-participate" className="link1">
            MAX4 챌린지 참여
          </Link>
        </div>
        <div style={{ width: '20px' }} />
        <div className="group">
          <h1>퓨처트레이딩</h1>
          <div style={{ height: '16px' }} />
          <h2>
            수익률을 기준으로 평가 받는 전통적이고 보수적인 모의 대회 라이브 대회 방식이 아닌 승률을 높여서 안정적인 투자수익을 추구할 수
            있도록 개발된 디지털 트레이딩
          </h2>
          <div style={{ height: '40px' }} />
          <img src={Screenshot2} className="screenshot" />
          <div style={{ height: '21px' }} />
          <Link to="/future-trading" className="link1">
            퓨처 트레이딩 참여
          </Link>
        </div>
        <div style={{ width: '20px' }} />
        <div className="group">
          <h1>Coming soon</h1>
          <div style={{ height: '16px' }} />
          <h2></h2>
          <div style={{ height: '40px' }} />
          <img src={Screenshot3} className="screenshot" />
          <div style={{ height: '21px' }} />
          {/* <Link to="/future-trading" className="link1">
            퓨처 트레이딩 참여
          </Link> */}
        </div>
      </div>

      <div className="Main-start">
        <div className="deco">
          <img src={DecoRight} className="right" />
          <img src={DecoLeft} className="left" />
        </div>
        <div className="main">
          <h1>지금바로 시작하세요</h1>
          <div style={{ height: '20px' }} />
          <Link to="/signup" className="link2">
            MAX4 회원가입
          </Link>
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
}
