import React, { ReactElement, useContext, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';

import { signin, checkEmailUniqueness } from '../api';
import TokenContext from '../token-context';
import Logo from 'img/signup_logo.png';
import Footer from './Footer';

import Icon_Individual from 'img/signup/icon_individual.png';
import Icon_Business from 'img/signup/icon_business.png';

import '../css/Signin.css';
import { Motion, spring } from 'react-motion';

export default withRouter(function Login(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  const [emailModalOn, setEmailModalOn] = useState<boolean>(false);
  const [editEmail, setEditEmail] = useState<boolean>(false);
  const [newEmail, setNewEmail] = useState('');

  const [accountType, setAccountType] = useState<'individual' | 'business' | null>(null);

  async function onLogin() {
    const idValue = (id ?? '').trim();
    const pwValue = pw ?? '';

    if (!idValue || !pwValue) {
      alert(t('signin-alert-email-pw-required'));
      return;
    }

    const response = await signin(idValue, pwValue, accountType!);

    if (!response) return;

    if (!response.authenticated) {
      alert(t('signin-alert-failed'));
      return;
    }

    if (!response.authenticated.wasEmailVerified) {
      setEmailModalOn(true);
      return;
    }

    tokenContext.setToken(response.authenticated.wasEmailVerified.token);

    props.history.push('/');
  }

  async function changeEmail() {
    // 여기서 이메일 중복확인 할 것 없이
    // 변경하는 api 함수 안에서 이미 있는 이메일이면 그냥 에러 던지게
    // 해당 api 에서는 이메일 변경 이후 즉시 이메일 발송 할 수 있도록 하면 됨

    const result = await checkEmailUniqueness((newEmail ?? '').trim(), accountType!);

    if (!result) return;

    if (!result.uniqueness) {
      alert(t('signin-email-conflict'));
      return;
    }

    // 성공적으로 변경 완료
    setEditEmail(false);
    toast.dark(t('signin-edit-email-success'), {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      closeButton: false,
    });
  }

  return (
    <div className="App">
      <ToastContainer />

      {accountType == null && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ height: '100px' }} />
            <img style={{ width: '207px', height: '207px' }} src={Logo} />
            <div style={{ height: '24px' }} />
            <div className="Signup-select-h1">MAX4</div>
            <div style={{ height: '9px' }} />
            <div className="Signup-select-h2">Best Trading Education Program</div>
            <div style={{ height: '80px' }} />
            <Motion defaultStyle={{ x: 0 }} style={{ x: spring(1) }}>
              {(value) => (
                <>
                  <div
                    style={{
                      width: `${value.x * 620}px`,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    <div className="Signup-select-btn">
                      <h1>일반 회원</h1>
                      <div style={{ height: '40px' }} />
                      <div
                        style={{
                          height: '155px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={Icon_Individual} style={{ width: '130px', height: '155px' }} />
                      </div>
                      <div style={{ height: '40px' }} />
                      <h2>Max Signal을 이용하는 일반 회원입니다.</h2>
                      <br />
                      <div style={{ height: '20px' }} />
                      <button onClick={() => setAccountType('individual')}>로그인</button>
                    </div>
                    <div className="Signup-select-btn">
                      <h1>비즈니스 회원</h1>
                      <div style={{ height: '40px' }} />
                      <div
                        style={{
                          height: '155px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={Icon_Business} style={{ width: '140px', height: '124px' }} />
                      </div>
                      <div style={{ height: '40px' }} />
                      <h2>별도의 가입비를 지급해야 가입이 가능한 비즈니스 회원입니다.</h2>
                      <div style={{ height: '20px' }} />
                      <span style={{ margin: 'auto' }} />
                      <button onClick={() => setAccountType('business')}>로그인</button>
                    </div>
                  </div>

                  <div style={{ height: '20px' }} />
                  <div
                    style={{
                      width: `${value.x * 620}px`,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    <Link className="Signin-infoside-backbtn" to="/">
                      {t('signin-back')}
                    </Link>
                  </div>
                </>
              )}
            </Motion>
          </div>
          <div style={{ height: '220px' }} />
        </>
      )}

      {accountType != null && (
        <>
          <div className="Signin-main">
            <div className="Signin-logoside">
              <img src={Logo} className="ignin-logoside-logo" />
              <div style={{ height: '38px' }} />
              <div className="Signin-logoside-h1">MAX4</div>
              <div style={{ height: '16px' }} />
              <div className="Signin-logoside-h2">Best Trading Education Program</div>
            </div>
            <div className="Signin-infoside">
              <Link className="Signin-infoside-backbtn" to="/">
                {t('signin-back')}
              </Link>
              <div style={{ height: '20px' }} />
              <div className="Signin-infoside-box">
                <div style={{ height: '63px' }}>
                  <div className="Signin-box-title">{t('main-menu-signin')}</div>
                </div>
                <div style={{ height: '30px' }}>
                  <div className="Signin-info-label">{t('mypage-info-username-label')}</div>
                </div>
                <div style={{ height: '42px' }}>
                  <input
                    className="Signin-input-full"
                    placeholder={t('signin-input-id')}
                    type="text"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                  />
                </div>
                <div style={{ height: '30px' }}>
                  <div className="Signin-info-label">{t('signin-pw-placeholder')}</div>
                </div>
                <div style={{ height: '42px' }}>
                  <input
                    className="Signin-input-full"
                    placeholder={t('mypage-input-password')}
                    type="password"
                    value={pw}
                    onChange={(e) => setPw(e.target.value)}
                  />
                </div>
                <div className="Signin-forget-area">
                  <div className="Signin-forget-label">{t('signin-not-user')}</div>
                  <span style={{ margin: 'auto' }} />
                  <Link to="/signup" className="Signin-forget-button" type="button">
                    {t('main-menu-signup')}
                  </Link>
                </div>
                <div style={{ height: '8px' }} />
                <div className="Signin-forget-area">
                  <div className="Signin-forget-label">{t('signin-signin-lostandfound-label')}</div>
                  <span style={{ margin: 'auto' }} />
                  <Link to="/forget-accounts" className="Signin-forget-button" type="button">
                    {t('signin-signin-lostandfound-text')}
                  </Link>
                </div>
                <div style={{ height: '40px' }} />
                <button className="Signin-button" type="button" onClick={onLogin}>
                  {t('main-menu-signin')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <span style={{ margin: 'auto' }} />
      <Footer />
      <Modal
        isOpen={emailModalOn}
        onAfterOpen={() => {
          // asdf
          setEditEmail(false);
          setNewEmail('');
        }}
        className="Signin-modal"
        style={{ overlay: { background: 'rgba(0, 0, 0, 0.7)' } }}
      >
        <div style={{ height: '35px', display: 'flex' }}>
          <div className="Signin-modal-h1">{t('signin-verify-email')}</div>
          <span style={{ margin: 'auto' }} />
          <button
            className="Signin-modal-cancelbtn"
            onClick={() => {
              setEmailModalOn(false);
            }}
          >
            {t('cancel-label')}
          </button>
        </div>
        <div style={{ height: '20px' }} />
        <div className="Signin-modal-h2">{t('signin-verify-email-description')}</div>
        {/* {editEmail ? (
          <>
            <div style={{ height: '20px' }} />
            <div className="Signin-modal-h4">{t('signup-email')}</div>
            <div style={{ height: '10px' }} />
            <div style={{ display: 'flex', height: '32px', alignItems: 'start', justifyContent: 'space-between' }}>
              <div style={{ width: '220px' }}>
                <input
                  className="Signin-modal-input"
                  placeholder={t('signin-input-new-email')}
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
              </div>
              <div style={{ width: '140px' }}>
                <button className="Signin-modal-btn" onClick={changeEmail}>
                  {t('signin-change-email')}
                </button>
              </div>
            </div>
          </>
        ) : (
          <> */}
        <div style={{ height: '4px' }} />
        <div style={{ display: 'flex' }}>
          <div className="Signin-modal-h2">{t('signin-resend-email-title')}</div>
          <div style={{ width: '10px' }} />
          <button
            className="Signin-modal-h3"
            onClick={() => {
              (async () => {
                await onLogin();
                toast.dark(t('signin-resend-email-success'), {
                  position: 'top-center',
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  closeButton: false,
                });
              })();
            }}
          >
            {t('signin-resend-label')}
          </button>
          <div style={{ width: '10px' }} />
          {/* <button
                className="Signin-modal-h3"
                onClick={() => {
                  setEditEmail(true);
                  setNewEmail('');
                }}
              >
                {t('signin-change-email-label')}
              </button> */}
        </div>
        {/* </>
        )} */}
      </Modal>
    </div>
  );
});
