import React, { ReactElement, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { getBusinessAccount, getBusinessRewards, getBusinessTotalReward, getBusinessTree, IncentiveInfo, StructedUserTree } from '../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/BusinessTree.css';

import Header from './Header';
import Footer from './Footer';

import PrevIcon from 'img/arrow_prev.png';
import NextIcon from 'img/arrow_next.png';

import dayjs from 'dayjs';
import styled from 'styled-components';

const Box = styled.div`
  width: 820px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  h1 {
    font-family: NotoSansKR;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e0be7a;
  }
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 150px 320px 320px;
  align-items: center;
  h1 {
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-align: left;
  }
  h2 {
    opacity: 0.6;
    font-family: NotoSansKR;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
  }
  h3 {
    font-family: NotoSansKR;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e0be7a;
    text-align: left;
  }
  h4 {
    font-family: NotoSansKR;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
  }
  h5 {
    opacity: 0.6;
    font-family: NotoSansKR;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
`;

export default withRouter(function BusinessIncentives(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const maxUserCount = 10;
  const maxPageCount = 9;

  const [page, setPage] = useState<number>(0);

  const [account, setAccount] = useState<{
    username: string;
    email: string;
    phone: string;
    wallet: string;
    membership?: string;
    wasMembershipApproved: boolean;
    wasMembershipApprovedAt?: Date;
    totalLevel7Sales: string;
    pointA: number;
    pointB: number;
    pointC: number;
    pointD: number;
    pendingPointA: number;
    pendingPointB: number;
    pendingPointC: number;
    pendingPointD: number;
  } | null>(null);
  const [totalReward, setTotalReward] = useState<{
    pointA: number;
    pointB: number;
    pointC: number;
    pointD: number;
  } | null>(null);
  const [rewards, setRewards] = useState<IncentiveInfo[]>([]);

  useEffect(() => {
    (async () => {
      const account = await getBusinessAccount();
      if (!account) {
        alert(t('challenge-login-first'));
        props.history.push('/signin');
        return;
      }

      setAccount(account);
    })();
    (async () => {
      const reward = await getBusinessTotalReward();
      if (reward) setTotalReward(reward);
    })();
    (async () => {
      const rewards = await getBusinessRewards();
      if (rewards) setRewards(rewards);
    })();
  }, []);

  function copyText(text: string) {
    if (!document.queryCommandSupported('copy')) {
      return alert(t('cannot-copy'));
    }
    const textarea = document.createElement('textarea');
    textarea.value = text;

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.dark(t('copy-success'), {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      closeButton: false,
    });
  }

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', width: '820px', margin: '80px auto 180px' }}>
        <Link className="BusinessTree-btn" to="/business-mypage">
          이전 화면으로 돌아가기
        </Link>
        <div style={{ height: '20px' }} />
        <Box>
          <h1>인센티브 정산</h1>
          <div style={{ height: '24px' }} />
          <Columns>
            <h1>수당 지급일</h1>
            <h1>포인트</h1>
            <h1>수당 분류</h1>
          </Columns>
          <div style={{ height: '10.5px' }} />
          <div style={{ height: '1px', border: 'solid 1px #ffffff' }} />
          {rewards.slice(page * 10, Math.min((page + 1) * 10, rewards.length)).map((reward) => (
            <RowData info={reward} />
          ))}
          {rewards.length > maxUserCount && (
            <>
              <div style={{ height: '10px' }} />
              <div style={{ display: 'flex', margin: '0 auto' }}>
                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.max(0, val - 1));
                  }}
                >
                  <img src={PrevIcon} />
                </button>
                {Math.ceil(rewards.length / maxUserCount) > maxPageCount ? (
                  <>
                    <button className="admin-page" onClick={() => setPage(0)} disabled={page == 0}>
                      1
                    </button>
                    {page > maxPageCount / 2 && <div className="admin-page">…</div>}
                    {Array.from({ length: maxPageCount - 2 }, (v, i) => {
                      const pageNum =
                        Math.min(
                          Math.max(Math.floor(maxPageCount / 2), page),
                          Math.ceil(rewards.length / maxUserCount) - Math.ceil(maxPageCount / 2),
                        ) +
                        i -
                        Math.floor((maxPageCount - 2) / 2);
                      return (
                        <button className="admin-page" onClick={() => setPage(pageNum)} disabled={page == pageNum}>
                          {pageNum + 1}
                        </button>
                      );
                    })}
                    {page < Math.ceil(rewards.length / maxUserCount) - Math.ceil(maxPageCount / 2) && <div className="admin-page">…</div>}
                    <button
                      className="admin-page"
                      onClick={() => setPage(Math.ceil(rewards.length / maxUserCount) - 1)}
                      disabled={page == Math.ceil(rewards.length / maxUserCount) - 1}
                    >
                      {Math.ceil(rewards.length / maxUserCount)}
                    </button>
                  </>
                ) : (
                  <>
                    {Array.from({ length: Math.ceil(rewards.length / maxUserCount) }, (v, i) => {
                      return (
                        <button className="admin-page" onClick={() => setPage(i)} disabled={page == i}>
                          {i + 1}
                        </button>
                      );
                    })}
                  </>
                )}

                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.min(val + 1, Math.ceil(rewards.length / maxUserCount) - 1));
                  }}
                >
                  <img src={NextIcon} />
                </button>
              </div>
            </>
          )}
          <div style={{ height: '20px' }} />
        </Box>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});

const RowData = (props: { info: IncentiveInfo }) => {
  return (
    <>
      <Row>
        <Columns>
          <h2>{dayjs(props.info.createdAt).format('YYYY.MM.DD')}</h2>
          <div style={{ display: 'flex' }}>
            <h3>
              {props.info.point == 'pointA'
                ? '데모 포인트'
                : props.info.point == 'pointB'
                ? '토너먼트 포인트'
                : props.info.point == 'pointC'
                ? '라이브 포인트'
                : props.info.point == 'pointD'
                ? '이더리움 포인트'
                : '알 수 없는 포인트'}
            </h3>
            <h4>{props.info.amount}p 지급</h4>
          </div>
          <h5>{props.info.reason}</h5>
        </Columns>
      </Row>
    </>
  );
};
