import React, { ReactElement, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

import { checkEmailUniqueness, checkPhoneUniqueness, checkUsernameUniqueness, generateApiUrl, signup } from '../api';

import Footer from './Footer';

import '../css/PurchaseMembership.css';
import { useEffect } from 'react';

import Logo from 'img/signup_logo.png';
import Icon_KR from 'img/korea.png';
import Icon_Vietnam from 'img/vietnam.png';
import Icon_Individual from 'img/signup/icon_individual.png';
import Icon_Business from 'img/signup/icon_business.png';

import Icon_MAX1 from 'img/signup/icon_max1.png';
import Icon_MAX2 from 'img/signup/icon_max2.png';
import Icon_MAX3 from 'img/signup/icon_max3.png';
import Icon_MAX4 from 'img/signup/icon_max4.png';
import Icon_MAX5 from 'img/signup/icon_max5.png';

import styled from 'styled-components';
import { Motion, spring } from 'react-motion';
import { toast, ToastContainer } from 'react-toastify';

const CountryInfo = [
  { number: '+82', icon: Icon_KR },
  { number: '+84', icon: Icon_Vietnam },
];

export default withRouter(function PurchaseMembership(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const [price, setPrice] = useState<number>(3297.08);

  const [page, setPage] = useState<string>('select-membership');
  const [agreeToggle, setAgreeToggle] = useState<boolean>(false);
  const [businessType, setBusinessType] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(generateApiUrl('/business-accounts/current-eth'), {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        });
        setPrice((await response.json()).currentETH);
        // eslint-disable-next-line no-empty
      } catch {}
    })();
  }, []);

  function copyText(text: string) {
    if (!document.queryCommandSupported('copy')) {
      return alert(t('cannot-copy'));
    }
    const textarea = document.createElement('textarea');
    textarea.value = text;

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.dark(t('copy-success'), {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      closeButton: false,
    });
  }

  return (
    <div className="App">
      <ToastContainer />

      {page == 'select-membership' && (
        <>
          <div className="Signup-select-business">
            <div style={{ height: '100px' }} />
            <img src={Logo} style={{ width: '207px', height: '207px' }} />
            <div style={{ height: '24px' }} />
            <h1>MAX4</h1>
            <div style={{ height: '9px' }} />
            <h2>Best Trading Education Program</h2>
            <div style={{ height: '29px' }} />
            <h3>비즈니스 가입을 위한 회원권 등급을 선택해주세요.</h3>
            <div style={{ height: '56px' }} />
            <Motion defaultStyle={{ x: 0 }} style={{ x: spring(1) }}>
              {(value) => (
                <>
                  <div
                    style={{
                      width: `${value.x * 760}px`,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    <button
                      className={businessType == 'MAX1' ? 'Signup-select-business-box-selected' : 'Signup-select-business-box'}
                      onClick={() => setBusinessType('MAX1')}
                    >
                      <h1>MAX1 회원권</h1>
                      <h2>가입비: 100$</h2>
                      <div style={{ height: '13px' }} />
                      <img src={Icon_MAX1} />
                    </button>
                    <button
                      className={businessType == 'MAX2' ? 'Signup-select-business-box-selected' : 'Signup-select-business-box'}
                      onClick={() => setBusinessType('MAX2')}
                    >
                      <h1>MAX2 회원권</h1>
                      <h2>가입비: 500$</h2>
                      <div style={{ height: '13px' }} />
                      <img src={Icon_MAX2} />
                    </button>
                    <button
                      className={businessType == 'MAX3' ? 'Signup-select-business-box-selected' : 'Signup-select-business-box'}
                      onClick={() => setBusinessType('MAX3')}
                    >
                      <h1>MAX3 회원권</h1>
                      <h2>가입비: 1,000$</h2>
                      <div style={{ height: '13px' }} />
                      <img src={Icon_MAX3} />
                    </button>
                  </div>
                  <div style={{ height: '20px' }} />
                  <div
                    style={{
                      width: `${value.x * 500}px`,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    <button
                      className={businessType == 'MAX4' ? 'Signup-select-business-box-selected' : 'Signup-select-business-box'}
                      onClick={() => setBusinessType('MAX4')}
                    >
                      <h1>MAX4 회원권</h1>
                      <h2>가입비: 5,000$</h2>
                      <div style={{ height: '13px' }} />
                      <img src={Icon_MAX4} />
                    </button>
                    <button
                      className={businessType == 'MAX5' ? 'Signup-select-business-box-selected' : 'Signup-select-business-box'}
                      onClick={() => setBusinessType('MAX5')}
                    >
                      <h1>MAX5 회원권</h1>
                      <h2>가입비: 10,000$</h2>
                      <div style={{ height: '13px' }} />
                      <img src={Icon_MAX5} />
                    </button>
                  </div>
                </>
              )}
            </Motion>
            <div style={{ height: '40px' }} />
            <button
              className="Signup-select-business-b1"
              onClick={() => {
                if (businessType == '') return;
                setPage('purchase-membership');
              }}
            >
              완료
            </button>
            <div style={{ height: '40px' }} />
            <Link to="/business-mypage" className="Signin-infoside-backbtn" onClick={() => setPage('business')}>
              {t('signin-back')}
            </Link>
            <div style={{ height: '180px' }} />
          </div>
        </>
      )}

      {page == 'purchase-membership' && (
        <>
          <div className="Signin-main">
            <div className="Signin-logoside">
              <img src={Logo} className="ignin-logoside-logo" />
              <div style={{ height: '38px' }} />
              <div className="Signin-logoside-h1">MAX4</div>
              <div style={{ height: '16px' }} />
              <div className="Signin-logoside-h2">Best Trading Education Program</div>
            </div>
            <div className="Signin-infoside">
              <button className="Signin-infoside-backbtn" onClick={() => setPage('select-membership')}>
                {t('signin-back')}
              </button>
              <div style={{ height: '20px' }} />
              <div className="Purchase-box ">
                <div className="Purchase-box-g1">
                  {businessType === 'MAX1' && (
                    <>
                      <h1>MAX1 회원권</h1>
                      <div style={{ width: '10px' }} />
                      <h2>가입비 100$</h2>
                      <div style={{ width: '10px' }} />
                      <h3>{Math.ceil((100 * 100000) / price) / 100000}ETH</h3>
                    </>
                  )}
                  {businessType === 'MAX2' && (
                    <>
                      <h1>MAX2 회원권</h1>
                      <div style={{ width: '10px' }} />
                      <h2>가입비 500$</h2>
                      <div style={{ width: '10px' }} />
                      <h3>{Math.ceil((500 * 100000) / price) / 100000}ETH</h3>
                    </>
                  )}
                  {businessType === 'MAX3' && (
                    <>
                      <h1>MAX3 회원권</h1>
                      <div style={{ width: '10px' }} />
                      <h2>가입비 1000$</h2>
                      <div style={{ width: '10px' }} />
                      <h3>{Math.ceil((1000 * 100000) / price) / 100000}ETH</h3>
                    </>
                  )}
                  {businessType === 'MAX4' && (
                    <>
                      <h1>MAX4 회원권</h1>
                      <div style={{ width: '10px' }} />
                      <h2>가입비 5000$</h2>
                      <div style={{ width: '10px' }} />
                      <h3>{Math.ceil((5000 * 100000) / price) / 100000}ETH</h3>
                    </>
                  )}
                  {businessType === 'MAX5' && (
                    <>
                      <h1>MAX5 회원권</h1>
                      <div style={{ width: '10px' }} />
                      <h2>가입비 10000$</h2>
                      <div style={{ width: '10px' }} />
                      <h3>{Math.ceil((10000 * 100000) / price) / 100000}ETH</h3>
                    </>
                  )}
                </div>
                <div style={{ height: '10px' }} />
                <h1>회원권 구매를 위한 이더리움 입금</h1>
                <div style={{ height: '10px' }} />
                <div className="Purchase-box-box">
                  <h1>이더리움 입금 지갑 주소</h1>
                  <div style={{ height: '4px' }} />
                  <h2>0xEd42656cdd44f26Ff1c1f71ae21dc766b406Db20</h2>
                  <div style={{ height: '10px' }} />
                  <button className="Purchase-box-box-b1" onClick={() => copyText('0xEd42656cdd44f26Ff1c1f71ae21dc766b406Db20')}>
                    복사하기
                  </button>
                  <div style={{ height: '10px' }} />
                  <h6>* 거래소에서(업비트, 빗썸 등) 전송 금지</h6>
                  <h3>반드시 등록된 개인지갑(트러스트월렛, 메타마스크 등) 에서 전송해 주시기 바랍니다.</h3>
                  <div style={{ height: '5px' }} />
                  <h4>
                    * 회원가입시 입력한 지갑주소와 동일한 지갑에서 출금이 되어 해당 이더리움 지갑 주소로 입금을 진행해야 정상적인 회원권
                    구매 확인이 가능합니다.
                  </h4>
                  <div style={{ height: '5px' }} />
                  <h4>
                    * 회원권 비용 계산 기준은 회원님이 이더리움을 전송한 당시의 컨트렉트를 확인하여 해당하는 회원권의 비용인지 아닌지를 확인
                    하므로, 전송시에 정확한 회원권의 비용 만큼 이더리움을 입금해주시기 바랍니다. 만약 부족하게되면 관리자가 별도로 회원님께
                    이메일로 연락을 드립니다.
                  </h4>
                  <div style={{ height: '5px' }} />
                  <h3>* 입금 주소를 잘못 입력하여 전송한 경우 되찾을 수 없습니다!</h3>

                  <div style={{ height: '20px' }} />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button
                      className={agreeToggle ? 'SendEnquiry-toggle-on' : 'SendEnquiry-toggle-off'}
                      onClick={() => setAgreeToggle(!agreeToggle)}
                    />
                    <div style={{ width: '10px' }} />
                    <h5>
                      * 회원권 구매 입금 이더리움 지갑주소로
                      <br />
                      {businessType === 'MAX1' && (
                        <>MAX1 회원권({Math.ceil((100 * 100000) / price) / 100000} ETH)의 이더리움을 입금하였습니다.</>
                      )}
                      {businessType === 'MAX2' && (
                        <>MAX2 회원권({Math.ceil((500 * 100000) / price) / 100000} ETH)의 이더리움을 입금하였습니다.</>
                      )}
                      {businessType === 'MAX3' && (
                        <>MAX3 회원권({Math.ceil((1000 * 100000) / price) / 100000} ETH)의 이더리움을 입금하였습니다.</>
                      )}
                      {businessType === 'MAX4' && (
                        <>MAX4 회원권({Math.ceil((5000 * 100000) / price) / 100000} ETH)의 이더리움을 입금하였습니다.</>
                      )}
                      {businessType === 'MAX5' && (
                        <>MAX4 회원권({Math.ceil((10000 * 100000) / price) / 100000} ETH)의 이더리움을 입금하였습니다.</>
                      )}
                    </h5>
                  </div>
                  <div style={{ height: '10px' }} />
                  <button
                    className="Purchase-box-box-b2"
                    disabled={!agreeToggle}
                    onClick={() => {
                      props.history.push('/business-mypage');
                    }}
                  >
                    입금 완료
                  </button>
                  <div style={{ height: '10px' }} />
                </div>
                {/* <div style={{ height: '10px' }} />
                <h1>회원권 구매 입금확인</h1>
                <div style={{ height: '10px' }} />
                <div className="Purchase-box-box2">
                  <div>
                    <h1>구매한 회원권</h1>
                    <h1>MAX1 회원권</h1>
                  </div>
                  <div style={{ height: '10px' }} />
                  <div>
                    <h1>회원권 입금완료 신청날짜</h1>
                    <h1>2021. 8. 13.</h1>
                  </div>
                  <div style={{ height: '10px' }} />
                  <div>
                    <h1>관리자 입금확인 날짜</h1>
                    <h1>2021. 8. 13.</h1>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}

      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});
