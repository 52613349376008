import React, { ReactElement, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import Main from './components/Main';
import Signup from './components/Signup';
import Login from './components/Login';
import MyPage from './components/MyPage';
import CustomerCare from './components/CustomerCare';
import Notice from './components/Notice';

import AuthContext from './token-context';
import VerifyEmail from './components/VerifyEmail';
import Challenge from './components/Challenge';
import Introduction from './components/Introduction';
import ForgetAccounts from './components/ForgetAccounts';
import SendEnquiry from './components/SendEnquiry';
import ResetPassword from './components/ResetPassword';
import PurchaseMembership from './components/PurchaseMembership';
import VerifyBusinessEmail from './components/VerifyBusinessEmail';
import ResetBusinessPassword from './components/ResetBusinessPassword';
import BusinessMyPage from './components/BusinessMyPage';
import VerifyMembership from './components/VerifyMembership';
import BusinessChallenge from './components/BusinessChallenge';
import BusinessTree from './components/BusinessTree';
import BusinessIncentives from './components/BusinessIncentives';
import FutureTrading from './components/FutureTrading';
import UpdateWalletAddress from './components/UpdateWalletAddress';

export default function App(): ReactElement {
  const [token, setToken] = useState(localStorage.getItem('session'));

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      <Router>
        <Switch>
          <Route path="/" exact={true}>
            <Main />
          </Route>
          <Route render={(props) => <Signup {...props} />} path="/signup" exact />
          <Route render={(props) => <Login {...props} />} path="/signin" exact />
          <Route render={(props) => <MyPage {...props} />} path="/mypage" />
          <Route render={(props) => <BusinessMyPage {...props} />} path="/business-mypage" />
          <Route render={(props) => <BusinessIncentives {...props} />} path="/business-incentives" />
          <Route render={(props) => <VerifyBusinessEmail {...props} />} path="/business-accounts/verify-email" />
          <Route render={(props) => <VerifyEmail {...props} />} path="/accounts/verify-email" />
          <Route render={(props) => <VerifyMembership {...props} />} path="/business-accounts/verify-membership" />
          <Route render={(props) => <ResetBusinessPassword {...props} />} path="/business-accounts/reset-pw" />
          <Route render={(props) => <ResetPassword {...props} />} path="/accounts/reset-pw" />
          <Route render={(props) => <UpdateWalletAddress {...props} />} path="/business-accounts/update-wallet-address" />
          <Route render={(props) => <Challenge {...props} />} path="/challenge-participate" />
          <Route render={(props) => <BusinessChallenge {...props} />} path="/business-challenge-participate" />
          <Route render={(props) => <FutureTrading {...props} />} path="/future-trading" />
          <Route render={(props) => <CustomerCare {...props} />} path="/customercare" exact />
          <Route render={(props) => <SendEnquiry {...props} />} path="/customercare/send-enquiry" />
          <Route render={(props) => <Notice {...props} />} path="/notice" />
          <Route render={(props) => <ForgetAccounts {...props} />} path="/forget-accounts" />
          <Route render={(props) => <PurchaseMembership {...props} />} path="/purchase-membership" />
          <Route render={(props) => <BusinessTree {...props} />} path="/business-tree" />
          <Route path="/challenge-info">
            <Introduction />
          </Route>
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}
