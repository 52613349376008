import React, { ReactElement, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

import { checkEmailUniqueness, checkPhoneUniqueness, checkUsernameUniqueness, checkWalletUniqueness, signup } from '../api';

import Footer from './Footer';

import '../css/Signup.css';
import { useEffect } from 'react';

import Logo from 'img/signup_logo.png';
import Icon_KR from 'img/korea.png';
import Icon_Vietnam from 'img/vietnam.png';
import Icon_Individual from 'img/signup/icon_individual.png';
import Icon_Business from 'img/signup/icon_business.png';

import Icon_MAX1 from 'img/signup/icon_max1.png';
import Icon_MAX2 from 'img/signup/icon_max2.png';
import Icon_MAX3 from 'img/signup/icon_max3.png';
import Icon_MAX4 from 'img/signup/icon_max4.png';
import Icon_MAX5 from 'img/signup/icon_max5.png';

import styled from 'styled-components';
import { Motion, spring } from 'react-motion';

const CountryInfo = [
  { number: '+82', icon: Icon_KR },
  { number: '+84', icon: Icon_Vietnam },
];

export default withRouter(function Signup(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const [id, setId] = useState('');
  const [idValidation, setIdValidation] = useState(false);
  const [email, setEmail] = useState('');
  const [emailValidation, setEmailValidation] = useState(false);
  const [pw, setPw] = useState('');
  const [pwConfirm, setPwConfirm] = useState('');
  const [phone, setPhone] = useState('');
  const [recommender, setRecommender] = useState('');
  const [currentCountry, setCurrentCountry] = useState<number>(0);
  const [dropdownOn, setDropdownOn] = useState(false);
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [walletValidation, setWalletValidation] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState<boolean>(false);

  const [accountType, setAccountType] = useState<'individual' | 'business' | null>(null);
  const [agreeTermToggle, setAgreeTermToggle] = useState<boolean>(false);
  const [agreeTerm, setAgreeTerm] = useState<boolean>(false);

  async function onSignup() {
    const idValue = (id ?? '').trim();
    const emailValue = (email ?? '').trim();
    const pwValue = pw ?? '';
    const pwConfirmValue = pwConfirm ?? '';
    const phoneValue = (phone ?? '').trim();
    let recommenderValue = (recommender ?? '').trim();
    const walletAddressValue = (walletAddress ?? '').trim();

    if (!idValue) {
      alert(t('signup-alert-id-required'));
      return;
    }

    if (!idValidation) {
      alert(t('signup-alert-id-confirm-required'));
      return;
    }

    if (!emailValue) {
      alert(t('signup-alert-email-required'));
      return;
    }

    if (!emailValidation) {
      alert(t('signup-alert-email-confirm-required'));
      return;
    }

    if (!pwValue) {
      alert(t('signup-alert-pw-required'));
      return;
    }

    if (!pwConfirmValue) {
      alert(t('signup-alert-pw-confirm-required'));
      return;
    }

    if (!phoneValue) {
      alert(t('signup-alert-phone-required'));
      return;
    }

    if (!phoneValidation) {
      alert(t('signup-alert-phone-confirm-required'));
      return;
    }

    if (!validator.isEmail(emailValue)) {
      alert(t('signup-alert-invalid-email'));
      return;
    }

    if (pwValue.length < 6) {
      alert(t('signup-alert-pw-too-short'));
      return;
    }

    if (72 < new TextEncoder().encode(pwValue).length) {
      alert(t('signup-alert-pw-too-long'));
      return;
    }

    if (pwValue !== pwConfirmValue) {
      alert(t('signup-alert-pw-not-match'));
      return;
    }

    if (recommenderValue) {
      const response = await checkUsernameUniqueness(recommenderValue, accountType!);

      if (!response) return;

      if (response.uniqueness) {
        alert(t('signup-alert-recommender-not-exists'));
        return;
      }
    } else {
      if (accountType == 'business') recommenderValue = 'MAX4';
    }

    if (!walletAddressValue) {
      alert(t('signup-alert-wallet-address-required'));
      return;
    }

    const response2 = await signup(
      idValue,
      emailValue,
      pwValue,
      CountryInfo[currentCountry].number + phoneValue,
      recommenderValue,
      walletAddressValue,
      accountType!,
    );

    console.log(accountType);

    if (!response2) return;

    if (!response2.noConflict) {
      alert(t('signup-alert-conflict'));
      return;
    }

    if (response2.noConflict.badWalletAddress) {
      alert(t('signup-alert-bad-wallet-address'));
      return;
    }

    alert(t('signup-alert-successful'));

    props.history.push('/signin');
  }

  async function confirmId() {
    // 서버에서 중복확인해서 오케이면
    const result = await checkUsernameUniqueness((id ?? '').trim(), accountType!);

    if (!result) return;

    if (result.uniqueness) {
      alert(t('signup-id-available'));
      setIdValidation(true);
    } else {
      alert(t('signup-id-not-available'));
    }
  }

  async function confirmEmail() {
    const result = await checkEmailUniqueness((email ?? '').trim(), accountType!);

    if (!result) return;

    if (result.uniqueness) {
      alert(t('signup-email-available'));
      setEmailValidation(true);
    } else {
      alert(t('signup-email-not-available'));
    }
  }

  async function confirmPhone() {
    const phoneValue = (phone ?? '').trim();

    if (!phoneValue) {
      alert(t('signup-alert-phone-required'));
      return;
    }

    const result = await checkPhoneUniqueness(CountryInfo[currentCountry].number + phoneValue, accountType!);

    if (!result) return;

    if (result.uniqueness) {
      alert(t('signup-phone-available'));
      setPhoneValidation(true);
    } else {
      alert(t('signup-phone-not-available'));
    }
  }

  async function confirmWallet() {
    const result = await checkWalletUniqueness((walletAddress ?? '').trim(), accountType!);

    if (!result) return;

    if (result.uniqueness) {
      alert('사용 가능한 지갑 주소입니다.');
      setWalletValidation(true);
    } else {
      alert('이미 사용중인 지갑 주소입니다.');
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const recommender = urlParams.get('recommender');
    const accountType = urlParams.get('accountType');
    if (recommender) setRecommender(recommender);
    if (accountType && accountType) setAccountType(accountType as 'individual' | 'business');
  }, []);

  return (
    <div className="App">
      {accountType == null && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ height: '100px' }} />
            <img style={{ width: '207px', height: '207px' }} src={Logo} />
            <div style={{ height: '24px' }} />
            <div className="Signup-select-h1">MAX4</div>
            <div style={{ height: '9px' }} />
            <div className="Signup-select-h2">Best Trading Education Program</div>
            <div style={{ height: '80px' }} />
            <Motion defaultStyle={{ x: 0 }} style={{ x: spring(1) }}>
              {(value) => (
                <>
                  <div
                    style={{
                      width: `${value.x * 620}px`,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    <div className="Signup-select-btn">
                      <h1>일반 회원가입</h1>
                      <div style={{ height: '40px' }} />
                      <div
                        style={{
                          height: '155px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={Icon_Individual} style={{ width: '130px', height: '155px' }} />
                      </div>
                      <div style={{ height: '40px' }} />
                      <h2>
                        Max Signal을 이용하는 일반 회원들을 위한 가입절차입니다.
                        <br />
                        무료로 가입이 가능합니다.
                      </h2>
                      <div style={{ height: '20px' }} />
                      <button onClick={() => setAccountType('individual')}>가입하기</button>
                    </div>
                    <div className="Signup-select-btn">
                      <h1>비즈니스 회원가입</h1>
                      <div style={{ height: '40px' }} />
                      <div
                        style={{
                          height: '155px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={Icon_Business} style={{ width: '140px', height: '124px' }} />
                      </div>
                      <div style={{ height: '40px' }} />
                      <h2>별도의 가입비를 지급해야 가입이 가능한 비즈니스 회원가입 절차입니다</h2>
                      <div style={{ height: '20px' }} />
                      <span style={{ margin: 'auto' }} />
                      <button onClick={() => setAccountType('business')}>가입하기</button>
                    </div>
                  </div>

                  <div style={{ height: '20px' }} />
                  <div
                    style={{
                      width: `${value.x * 620}px`,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    <Link className="Signin-infoside-backbtn" to="/">
                      {t('signin-back')}
                    </Link>
                  </div>
                </>
              )}
            </Motion>
          </div>
          <div style={{ height: '220px' }} />
        </>
      )}

      {accountType != null && agreeTerm == false && (
        <>
          <div className="Signin-main">
            <div className="Signin-logoside">
              <img src={Logo} className="Signin-logoside-logo" />
              <div style={{ height: '38px' }} />
              <div className="Signin-logoside-h1">MAX4</div>
              <div style={{ height: '16px' }} />
              <div className="Signin-logoside-h2">Best Trading Education Program</div>
            </div>
            <div className="Signin-infoside">
              <div className="Signup-term">
                <h1>이용약관</h1>
                <div style={{ height: '20px' }} />
                <h2>
                  MASIGNAL LIMITED (이하, 「당사」라 합니다.)는 홍콩법에 근거하여 홍콩에 설립된 회사이며, 웹사이트 https://www.max4.io이하,
                  「본 웹사이트 디지털 자산거래교육 및 관련 서비스」 이하 「본 서비스」라 합니다.）가 제공하는 디지털 자산거래교육
                  플랫폼입니다. 본 계약서의 문구의 편의를 위해, 당사 및 웹사이트는 본 약관에서는 「당사」라 합니다. 이 웹사이트에 로그온하는
                  기존 회원, 신규 신청자들 모두 이 웹사이트의 사용자로 간주합니다. 본 계약서의 문구의 편의를 위해, 모든 사용자를
                  「고객」이라 합니다. 고객의 편의를 도모하여 본 웹사이트의 모든 콘텐츠는 여러 언어로 이용 가능합니다. 서로 다른 언어 콘텐츠
                  혹은 언어 특성에 의한 누락, 조어 등으로 인해 모순이 발생한 경우에는 영문판이 우선됩니다. 주요 확인사항; 1. MAX4 비즈니스
                  회원은 추천을 통해 유저를 확보하기 위한 프로그램이므로 추천을 하지 않는 사람이나 투자목적으로 가입하거나 권유해서는
                  안됩니다. 2. MAX4 비즈니스 회원은 회원 가입비를 입금하면 준회원이 되며 승인을 받은 후에 정회원이 되기 때문에 15일이내
                  환불이 가능하고 회원탈퇴도 가능하나, 회원가입후 15일이 지나거나 즉시승인을 받아 정회원이 될경우에는 15일이내 회원 탈퇴를
                  하여도 환불이 되지 않습니다. 3. MAX4 플랫폼은 트레이딩 실력을 향상시키기위한 플랫폼이므로 시상금이나 인센티브로 받은
                  포인트로만 이용 가능하며 코인이나 현금 등을 입금하여 거래하는 플랫폼이 아닙니다. 단, 토너먼트 챌린지는 $10의 참가비를
                  이더리움으로 전송하여 플랫폼 이용가능합니다. 4. MAX4의 코인은 보너스로 받은 라이브포인트로만 교환 가능하며 코인을 P2P로
                  구매하거나 판매할 수 없습니다. 기본사항 1. 당사는 사용자가 본 계약을 위반하거나, 사용자의 담당(국가, 행정 등) 지역에서
                  서비스를 제공 또는 사용하는 것이 위법하다고 판단되는 경우, 당사는 언제든지 사용자의 계정이나 본 서비스의 사용 또는 디지털
                  자산 거래 처리를 일시 정지 혹은 종료할 수 있습니다. 미국 내에 거주하는 자에 의한 본 서비스의 사용은 금지되어 있습니다.
                  디지털 자산 거래는 매우 큰 위험을 동반하기 때문에 일반 고객에게 적합하지 않습니다. 고객은 디지털 자산에 대한 투자가 일부
                  혹은 전체적 손실이 발생할 가능성이 있는 것을 인식하고 이해한 후 고객의 손실 부담 허용 범위에서 투자액을 결정할 것을
                  권고합니다. 고객은 디지털 자산이 파생적 위험이 발생할 가능성이 있는 것을 인정하고 이해해 주십시오. 또한 위에서 언급한 위험
                  외에도 예측할 수 없는 위험도 있을 수 있습니다. 따라서, 디지털 자산의 매매를 결정하기 전에 고객님의 자산 상태 및 위에
                  언급한 위험을 평가하기 위해 주의 깊게 고려하고 명확한 판단을 할 것을 권고합니다. 이 위험으로 인해 발생하는 모든 손실은
                  고객님의 부담이 되며 어떤 때에도 당사는 책임을 지지 않습니다. 알림 1. 본 사이트의 내용은 미리 통보하지 않고 수시로 변경할
                  수 있습니다. 당사는 이미 합리적인 조치를 취해 사이트 콘텐츠의 정확성을 확보하였으나 그 정확성의 정도를 확보하지
                  못하였습니다. 또한 본 사이트의 콘텐츠, 또는 인터넷 연결의 실패, 통보 및 정보의 발신 및 수신 시 발생하는 지연 또는 실패로
                  인한 직접적이나 간접적인 어떠한 손실도 부담하지 않습니다. 2. 인터넷 방식을 활용한 거래 시스템은 위험성을 가지고 있으며 그
                  위험성은 소프트웨어, 하드웨어 및 인터넷 연결의 실패 등을 포함하나 꼭 여기에만 제한되지 않습니다. 저희가 인터넷의 신뢰도 및
                  가용성을 지배할 수 없으므로 저희는 변조, 지연 및 연결 실패에 어떠한 책임도 부담하지 않습니다. 3. https://www.max4.io 본
                  사이트의 유일한 대외적 정보 발표 플랫폼입니다. 4. 본 사이트의 어떠한 서비스도 신용카드 결제를 지원하지 않습니다. 5. 본
                  사이트를 이용한 자금세탁, 밀수, 상업적인 뇌물수수등 일체 불법적인 거래 행위 또는 불법행위는 모두 금지됩니다. 만일 불법
                  거래 또는 불법 행위가 발견되면 본 사이트는 모든 가능한 방법을 사용할 것 입니다. 그 방법은 계정 동결, 관련 권리 기관에 대한
                  통보 등을 포함하나 꼭 여기에만 제한되지 않습니다. 또한 당사는 이로 인한 어떠 한 책임도 지지 않으며 관련 고객에게 책임을
                  물을 권리를 가지고 있습니다. 6. 본 사이트를 이용하여 시장을 조작, 부적당 거래 등 모든 불법 거래 활동을 해서는 안되고, 이런
                  행위가 적발되었을시 본 사이트에서는 모든 가격의 조작 행위, 시스템에 영향을 주는 등 부적당 행위에 대해 경고, 사용 금지,
                  계정 중지 등 예방성 보호조치를 취할 수 있으며, 당사는 이에 따른 모든 책임을 지지 않으며 관련자에게 문책할 권리를 가집니다.
                  1. 총칙 1.1. 사용자 규약(이하 본 규약 이라 함)는 본문, 프라이버시 조항, 고객님의 거래처 및 자금세탁방지 정책에 대한 이해
                  및 본 사이트에서 이미 발표하거나 발표할 각종 규칙, 성명, 설명 등으로 구성됩니다. 1.2. 고객님은 본 사이트에서 제공하는 각종
                  서비스를 사용하기 전에 본 계약을 잘 열람해야 합니다. 이해하지 못하는 사항이나 기타 필요성이 있으시면 전문 변호사와
                  상당하는 것을 권장합니다. 고객님은 본 계약 또는 그에 대한 수정에 동의하지 않으시면 바로 본 사이트에서 제공하는 서비스에
                  대한 사용을 중단하거나 다시 본 사이트에 접속하지 않기 바랍니다. 본 사이트에 접속하고 본 사이트의 서비스에 대한 사용 행위
                  또는 기타 유사한 행위는 고객님이 본 계약 각 조항의 내용을 이미 이해하고 이에 동의했다는 것을 의미합니다. 그 내용에 본
                  사이트가 본 계약에 대한 수시로 실시하는 모든 수정을 포함됩니다. 1.3. 고객님은 본 사이트의 요구에 따라 관련 정보를 입력하고
                  기타 관련 절차를 거쳐 회원에 가입하면 본 사이트의 회원(이하 회원이라 함)이 됩니다. 회원가입 절차에서의 동의 버튼에 대한
                  클릭 행위는 전자 서명의 형식으로 당사와 합의한다는 것을 의미합니다. 또한 본 사이트를 사용하는 과정에 동의 또는 유사한
                  의미를 가지고 있는 버튼에 대한 클릭 행위, 또는 본 사이트가 허용한 모든 방식으로 본 사이트에서 제공하는 서비스를 실제적으로
                  사용하는 행위는 모두 고객님이 본 약관의 모든 조항을 이해하고 이에 동의하며 그 구속을 받는다는 것을 의미합니다. 고객님의
                  서면적인 필기 서명이 없는 경우에도 본 계약이 고객님에 대한 법적 구속력이 발생합니다. 1.4. 본 사이트의 회원이 된 후
                  고객님은 회원 계정 및 비밀번호를 발급받게 됩니다. 해당 회원 계정 및 비밀번호는 회원이 보관하며 회원은 자신의 계정을 이용한
                  모든 활동 및 사건에 법적 책임을 부담해야 합니다. 1.5. 본 사이트의 회원이 되어야만 본 사이트에서 제공하는 디지털 자산 교육
                  플랫폼에서 거래를 진행하거나 본 사이트에서 규정된 회원만 제공받을 수 있는 서비스를 사용할 수 있습니다. 회원에 가입하지
                  않을 경우 사이트 접속 권한, 사이트 열람 권한 및 기타 본 사이트에서 규정된 서비스만 제공받을 수 있습니다. 1.6. 회원 가입이
                  완료되고 본 사이트에서 제공하는 서비스 및 기능을 사용하는 행위는 고객님이 이미 다음 사항을 열람하고 이해했다는 것으로
                  간주합니다. 1.6.1 본 약관의 모든 조항 및 조건의 구속을 받습니다. 1.6.2 고객님은 이미 만 18세이상 이거나 적용이 가능한 각종
                  법률에 따라 계약서를 체결할 수 있는 법정 연령임을 보증합니다. 고객님이 본 사이트에서의 가입, 판매 또는 구매, 정보 발표 등
                  본 사이트 서비스에 동의하는 행위는 고객님 관할 국가 또는 지역의 관련 법률 법규를 따라야 합니다. 또한 이러한 조항에
                  동의하고 거래를 진행하며 본 사이트를 이용하여 디지털 자산을 거래할 수 있는 능력을 가지고 있음을 보증합니다. 1.6.3 고객님은
                  회원가입비 및 거래에 따른 고객님에게 귀속되는 디지털 자산이 모두 합법적으로 획득된 것이라는 것을 확인합니다. 1.6.4
                  고객님은 고객님의 거래 또는 비거래 행위의 수익 또는 손실에 대한 모든 책임을 부담한다는 것에 동의합니다. 1.6.5 고객님은
                  회원가입시 제공한 정보가 모두 진실하며 정확한 것이라고 약속합니다. 1.6.6 고객님은 관련 법률의 규정을 준수한다고
                  약속합니다. 이중에 과세를 위한 모든 거래 이윤에 대한 보고를 포함합니다. 1.6.7 고객님은 본 사이트에서 제공한 서비스와
                  관계없이 본 사이트 또는 회사의 이익을 해치는 행위 또는 활동에 참여해서는 안됩니다. 1.6.8 본 약관은 고객님과 당사 사이에
                  합의된 권리 및 의무 관계에 대한 구속이며 본 사이트 사용자들 사이 및 기타 사이트와 고객님 사이에 디지털 자산의 거래로 인한
                  법적 관계 및 법적 분쟁에 연관되지 않습니다. 2. 당사는 수시로 본 계약을 수정하고 홈페이지에서 공시하는 방식으로 이를
                  공지하며 고객님에게 따로 통보하지 않을 권리를 보유합니다. 변경된 계약은 본 계약의 첫 페이지에 변경 시점을 표기합니다. 본
                  사이트에 발표된 시점으로부터 그 효력이 자동적으로 발생합니다. 고객님은 본 계약의 업데이트 변경 시점 및 업데이트 내용을
                  수시로 열람하고 확인해야 합니다. 해당 변경에 동의하지 않을 경우 본 사이트의 서비스에 대한 사용을 바로 중단해야 합니다. 본
                  사이트의 서비스를 계속 사용할 경우 고객님이 수정된 약관의 구속을 받는다는 것에 동의한다는 것을 의미합니다. 3. 회원가입 3.1
                  고객님은 다음 사항을 확인하고 약속해야 합니다. 고객님이 회원가입 절차를 완료하거나 본 사이트가 허용하는 다른 방식으로 본
                  사이트에서 제공하는 서비스를 사용할 때 고객님은 적용이 가능한 법률에 따라 본 약관에 서명할 수 있으며 본 사이트 서비스를
                  사용할 수 있는 능력을 보유한 일반인, 법인 또는 조직이어야 합니다. 고객님이 회원가입 동의 버튼을 클릭한다는 것은 고객님
                  또는 고객님의 승인 대리인이 본 약관의 내용에 동의하며 고객님의 해당 대리인이 고객님을 대신하여 본 사이트에 대한 회원가입
                  및 서비스 사용에 동의한다는 것을 의미합니다. 고객님이 상기 주체 자격을 보유하지 않을 경우 고객님 또는 고객님의 승인
                  대리인은 이로 인한 모든 결과에 대해 책임을 지셔야 합니다. 또한 당사는 고객님의 계정을 삭제하거나 영구적으로 동결하며
                  고객님 또는 고객님의 승인 대리인에게 책임을 물을 수 있는 권리를 보유합니다. 3.2 고객님은 다음 사항을 확인하고 약속합니다.
                  본 사이트 회원의 가입 목적이 법률 법규를 위배하거나 본 사이트의 디지털 자산 거래 질서를 파괴하기 위한 것이 아닙니다. 3.3
                  회원가입절차 3.3.1 고객님은 본 사이트 회원가입 페이지의 요구에 따라 유효한 이메일 주소, 휴대폰 번호 등 정보을 제공하는
                  것에 동의합니다. 고객님은 고객님이 제공하거나 확인한 이메일 주소, 휴대폰 번호 또는 본 사이트가 허용한 기타 본 사이트
                  접속용 방식을 사용할 수 있습니다. 필요한 경우 각종 법률 범주에 따른 법률 법규에 따라 고객님은 고객님의 진실한 성명,
                  신분증명서 등 법률 법규와 프라이버시 조항 및 자금세탁방지 규정에 따른 관련 정보를 제공하고 회원가입 자료를 지속적으로
                  업데이트하여 즉시성, 상세성, 정확성의 요구를 만족하도록 해야 합니다. 모든 원시적으로 입력된 자료는 회원가입 자료로
                  이용됩니다. 고객님은 해당 정보의 진실성, 완전성 및 정확성에 책임지고 이로 인한 모든 직접적이나 간접적인 손실 및 불리한
                  결과에 대해 책임을 져야합니다. 3.3.2 만약 고객님 소재의 주권 국가 또는 지역의 법률 법규, 규칙, 명령 등 규정이 휴대폰
                  번호에 대해 실명제 요구가 있을 경우 고객님이 회원가입 시 등록한 휴대폰 번호가 실명 등록된 번호인 것에 동의해야 합니다.
                  만약 규정에 따라 제공하지 않으면 이로 인한 모든 직접적이나 간접적인 손실 및 불리한 결과는 고객님이 부담해야합니다. 3.3.3
                  고객님은 회원가입을 위한 필요한 정보를 합법적으로 완전하게 제공하고 인증을 받으면 본 사이트에서 제공하는 계정과 비밀번호를
                  발급받게 됩니다. 고객님이 본 사이트의 계정과 비밀번호를 발급받는 시점은 회원가입 성공으로 간주되며 본 사이트에 회원
                  신분으로 접속할 수 있습니다. 3.3.4 고객님은 본 사이트에서 발신하는 본 사이트의 관리, 운영에 관련한 이메일 및 메시지에 대한
                  수신에 동의합니다. 4. 서비스 본 사이트는 고객님이 본 사이트를 통해 디지털 자산 거래 활동(디지털 자산 거래 등 서비스를
                  포함하나 꼭 여기에만 제한되지 않음)을 위해 인터넷 거래 교육 플랫폼 서비스를 제공합니다. 본 사이트는 매입자 또는 매수자가
                  디지털 자산의 매매 행위 주체로 하지 않습니다. 본 사이트는 특정 국가 법정 화폐의 충전 및 인출에 관련한 서비스도 제공하지
                  않습니다. 4.1 서비스 내용 4.1.1 고객님은 본 사이트에서 각종 디지털 자산 제품의 시세 및 거래 정보를 열람할 권리를 보유하게
                  되며 본 사이트를 통해 디지털 자산 거래 주문을 하고 디지털 자산 거래를 종료할 권한을 보유하게 됩니다. 4.1.2 고객님은 본
                  사이트에서 본 사이트에 회원으로 가입된 계정에 따른 정보를 확인할 권한이 있으며 본 사이트에서 제공하는 기능을 이용할 권리를
                  보유하고 있습니다. 4.1.3 고객님은 본 사이트에서 발표하는 활동 규칙에 따라 본 사이트에서 조직된 온라인 활동을 참여할 권한을
                  보유하고 있습니다. 4.2 고객님은 아래와 같은 본 사이트의 규칙을 준수한다고 약속합니다. 4.2.1 고객님은 법률 법규, 규정 및
                  정책 요구를 준수하고 계정에 있는 모든 디지털 자산 출처의 합법성을 확보해야 합니다. 고객님은 본 사이트에서 또는 본 사이트를
                  이용하여 기타 장소에서 불법적인 행위나 본 사이트 또는 제3자의 권익을 침해하는 행위를 취하지 못합니다. 예를 들어 불법
                  행위나 규정을 위배하거나 타인 권익을 침해하는 정보를 발신하거나 수신할 경우, 다단계 판매 재료 또는 다른 위험성 정보나
                  언론을 발신하거나 수신할 경우, 본 사이트에서 승인을 받지 않는 상태에서 본 사이트의 전자 메일 제목을 사용하거나 위조할 경우
                  등입니다. 4.2.2 고객님은 법률 법규를 준수하고 본 사이트의 계정과 로그인 비밀번호, 자금 비밀번호와 회원가입시 연동된 휴대폰
                  번호 및 휴대폰으로 수신된 휴대폰 인증 코드를 타당하게 사용하고 보관하며 그 안전성을 확보해야 합니다. 고객님은 본 사이트의
                  계정 및 로그인 비밀 번호, 자금 번호, 휴대폰으로 수신된 인증 코드를 이용하여 실시하는 모든 조작과 결과에 대해 일체 책임을
                  부담해야합니다. 본 사이트의 계정 및 로그인 비밀 번호, 자금 번호, 인증 코드가 승인을 받지 않은 제3자에 의해 사용된 것을
                  발견하거나 기타 계정상 문제가 있을 경우 바로 효과적인 방식으로 본 사이트에 통보해야 하고 본 사이트에서 해당 계정의
                  서비스를 일시 중단하도록 합니다. 본 사이트는 합리적인 기간에 고객님이 제출한 해당 요구에 대해 조치를 취할 권한이 있으나 본
                  사이트가 조치를 취하기 전에 이미 발생한 결과(고객님에게 발생하는 모든 손실을 포함하나 꼭 여기에만 제한되지 않음)에 대해
                  어떠 한 책임도 부담하지 않습니다. 본 사이트로부터 승인을 받지 않을 경우 본 사이트의 계정을 타인에게 증여, 대여, 임대,
                  이전하거나 다른 방식으로 발급할 수 없습니다. 4.2.3 고객님은 본 사이트에서의 고객님의 계정, 비밀번호를 통해 실시하는 모든
                  행위(정보 공지, 정보 발표, 인터넷에서의 동의 버튼 클릭 또는 각종 규칙과 협의, 온라인 약관 연장에 대한 승인 및 서비스 구매
                  등을 포함하나 꼭 여기에만 제한되지 않음)에 부담한다는 것에 동의합니다. 4.2.4 고객님은 본 사이트에서 디지털 자산 거래를
                  진행할 경우 디지털 자산 거래의 정상 진행에 지장을 주거나 거래 질서를 파괴할 수 없습니다. 또한 기술적인 방법이나 다른
                  방식으로 본 사이트의 정상 운전에 지장을 주거나 본 사이트 서비스에 대한 다른 사용자의 사용에 지장을 줄 수 없습니다. 사실
                  위조 등 방식으로 본 사이트의 상업적인 신용과 명예를 훼손할 수 없습니다. 4.2.5 고객님은 인터넷 거래로 다른 사용자와 분쟁이
                  발생할 경우 사법적이나 행정적인 방법을 제외한 방법으로 본 사이트에 관련 자료를 청구할 수 없습니다. 4.2.6 고객님이 본
                  사이트에서 제공하는 서비스를 사용하는 과정에 발생하는 과세 또는 하드웨어, 소프트웨어, 서비스 및 기타 비용은 모두 고객님이
                  판단 및 부담합니다. 4.2.7 고객님은 본 사이트가 수시로 발표하거나 업데이트하는 본 약관과 기타 서비스 조항 및 조작 규칙을
                  준수해야 합니다. 고객님은 본 사이트에서 제공하는 서비스의 사용을 수시로 중단할 권리를 보유하고 있습니다. 4.3 제품 규칙
                  4.3.1 (제품 거래 규칙)고객님은 본 사이트에서 로그인해서 본 사이트를 통해 기타 사용자와 거래를 진행하는 과정에 다음과 같은
                  거래 규칙을 지켜야 합니다. 4.3.2 (거래 정보의 열람)고객님은 본 사이트에서 거래 정보를 열람할 때 거래 정보에 포함된 모든
                  내용을 잘 열람해야 합니다 4.3.3 (거래내역의 확인)고객님은 해당 거래의 기록을 확인하고 자신의 구체적인 거래기록을 확인할 수
                  있습니다. 5. 본 사이트의 권리와 의무 5.1 만약 고객님이 본 약관 규정된 등록 자격을 가지지 않으면 본 사이트는 고객님의
                  등록을 거부할 권리를 가집니다. 이미 등록한 경우에는 본 사이트는 고객님의 회원계정을 취소할 권리를 지니며, 그리고
                  고객님이나 대리인의 책임을 지울 권리를 지닙니다. 또한 본 웹사이트는 다른 어떠한 경우에도 등록 신청을 수락할지의 여부를
                  결정하는 권한을 가집니다. 5.2 본 사이트는 고객님이 교육에 적합하지 않다고 판단되는 경우 고객님의 계정 및 모든 관련 계정의
                  사용을 중단 혹은 중지시킬 수 있는 권리를 가집니다. 5.3 본 사이트는 계정의 사용자가 본 웹사이트의 등록자가 아닌 것을 발견한
                  경우 해당 계정의 사용을 일시 정지 혹은 종료시킬 권리를 가집니다. 5.4 본 사이트는 기술적인 테스트, 수동 샘플링등의 방법으로
                  고객님이 제공한 오류정보, 무효정보 및 불완전한 정보등에 대해 의구심이 드는 합리적 이유가 있는 경우에는 변경시키고
                  업데이트하게 하거나 서비스를 중지할 권리를 가집니다. 5.5 본 사이트에서 제공하는 어떤 정보에 대해 확실하게 오류가 있다는
                  것을 발견하면 본 사이트에 게재되어 있는 정보를 수정할 권리를 가집니다. 5.6 본 사이트는 수시로 서비스를 수정, 중지 및
                  중단할 권리를 지니며, 고객님께 통지 없이 수정 및 중지 서비스를 할 수도 있습니다. 본 사이트는 하나 및 여러개의 서비스를
                  중지할 경우에는 중지공고가 발표된 날부터 효력이 생깁니다. 5.7 본 사이트는 필요적인 기술수단과 관리조치로 정상적인 운영을
                  확보하며, 필요성과 신뢰성이 있는 환경과 서비스를 제공하고 디지털 자산의 거래 및 교육 환경을 유지합니다. 5.8 일년동안 본
                  사이트의 회원계정과 패스워드로 로그인하지 않을 경우 본 사이트는 고객님의 계정을 취소할 권리를 가집니다. 계정이 취소된 후
                  본 사이트에서는 취소된 회원 아이디를 다른 사람이 사용하도록 할 수 있습니다. 5.9 본 사이트는 기술적 정보 수집 강화, 안전
                  방비 향상 등의 조치로 고객님의 정보의 안전을 확보하도록 하며, 고객님의 계정이 예측 가능한 위험성이 있을 때 미리 알려 주는
                  의무를 가집니다. 5.10 본 사이트는 수시로 각 종류의 법령, 법규 및 본 사이트 규정에 맞지 않는 컨텐츠 및 정보를 언제든지
                  삭제할 권리를 가지며, 이 권리를 행사할 때 고객님에게 사전 통지하지 않아도 됩니다. 5.11 본 사이트는 고객님이 거주하고 있는
                  국가나 지역의 법률, 법규, 규칙, 명령 등 규범의 요구에 따라 고객님에게 더 많은 정보나 자료 등을 제공할 권리를 가집니다.
                  현지의 기준 요건을 만족시키기 위해 합리적인 조치를 취할 것이며, 고객님은 법령 등이 요구하는 조치에 대해 적절하제 정보를
                  제공할 의무가 있습니다. 본 사이트는 소속된 주권국 국가나 지역의 법률, 법규, 규칙, 명령 등의 규범에 따라 고객님에게 개방된
                  부분 및 사이트의 전 서비스를 임시 중지나 영구 중지시킬 권리를 가집니다. 6. 손해배상 6.1 어떤 경우에 있어서도 고객님의 직접
                  손해에 대한 당사의 책임은 본 사이트 서비스를 지속적으로 3개월동안 사용할 때 생기는 총비용을 초과하지 않을 것입니다. 6.2
                  고객님이 본 계약이나 다른 법률, 법규 등 상황을 위반하면 당사에 최소 200만 미국달러를 배상하고 이로인해 발생되는 전체
                  비용(변호사 수임료를 포함)을 책임져야 합니다. 그런 보상이 실제의 손실을 메우지 못할 경우에는 차액분을 납부해야합니다. 6.3
                  금지 명령 구제의 권리로서 당사와 고객 쌍방은 계약합의 조항위반 또는 잠재적 계약의 위반에 대해 일반 법령에 의하여 손실을
                  충당하기에 충분하다는 것을 인정하고 계약 위반 또는 계약 위반 가능성이있는 경우 위반하지 않은 당사자는 금지 명령 구제 및
                  관습법 또는 국내법에서 허용되는 기타 모든 구제를 요구할 권리를 가집니다. 7. 당사와 고객님은 합의 위반이나 계약 위반
                  가능성에 대한 일반적인 법률적 구제책이 당사가 유지하는 모든 손실을 보상하기에는 불충분할 수 있다는 것을 인정합니다.
                  따라서, 계약 위반 또는 계약 위반이 발생할 가능성이 있는 경우, 비침해 당사자는 일반적인 법률 또는 형평에 따라 허용되는 다른
                  모든 구제책뿐만 아니라, 불법 구제를 요청할 권리를 가집니다. 8. 책임 제한과 면책 8.1 당사는 아래와 같은 사항에 대해 책임을
                  지지 않을 것이며, 고객님은 이를 알고 동의해야 합니다. 8.1.1 가입비 및 보너스 포인트의 수익손실 8.1.2 거래 이익이나
                  계약상의 손실 및 가입 후 14일이 지난 후 가입비 환불 8.1.3 사업의 중단 8.1.4 통화가치(디지털자산 포함)폭락으로 인한 손실
                  8.1.5 정보의 손실 8.1.6 기회 손실 이나 평가가치의 저하 8.1.7 데이터 손상 및 소실 8.1.8 대체 제품이나 서비스를 구매한 원가
                  8.1.9 발생의 가능성에 대해서 사전에 통지 유무에 관계없이 권리침해 (고의・과실 포함), 계약 위반이나 다른 어떤 원인으로 인해
                  생기는 간접적, 부차적인 손실이나 손해(합리적으로 예측할 수 있는 것을 막론합니다.) 8.1.10 8.1.1조부터 8.1.9조는 서로
                  독립적인 것이다. 8.2 고객님은 아래와 같은 어떤 상황으로 인해 생기는 어떤 손해에 대해 배상을 책임지지 않다는 것을 알고
                  동의합니다.: 8.2.1 고객님이 중대한 위법이나 법령 혹은 계약 위반이 포함될 가능성이 있다고 당사가 합리적이고 적절하게 판단한
                  경우 8.2.2 본 사이트 상에서 고객님의 행위가 위법 혹은 도덕적이지 못하다고 당사가 합리적으로 판단한 경우 8.2.3 본 사이트
                  서비스를 통해 어떤 데이터, 정보를 구매, 획득 및 거래로 인해 발생하는 비용과 손실 8.2.4 본 사이트 서비스에 대한 고객님의
                  오해 8.2.5 본 사이트의 서비스에 관련된 문제(손실등)중 당사가 원인이 아닌 것 8.3 정보인터넷 설비 유지, 정보인터넷 연결
                  장애, 컴퓨터, 통신 및 다른 시스템의 고장, 전력고장, 날씨원인, 우발사고, 파업, 노동쟁의, 폭동, 봉기, 소란, 생산력 및
                  생산수단부족, 화재, 홍수, 폭풍, 폭발, 전쟁, 은행 및 다른 합작측의 원인, 디지털자산 시장붕괴, 정부 사법이나 행정기관의
                  명령, 다른 저희의 제어 가능한 범위에 어긋나는 것 및 제어하지 못하는 행위, 제3자가 원인으로 인해 생기는 서비스 불가능 및
                  서비스 지연, 그리고 고객님에게 미치는 손실에 대해에는 당사는 책임지지 않습니다. 8.4 본 사이트에 포함된 모든 정보, 프로그램
                  ,텍스트등 매우 안전하고, 어떤 바이러스, 트로잔 바이러스 등 악의가 있는 프로세스의 방해나 파괴 받지 않는다는 것을 보증하지
                  못합니다. 때문에 로그인하여 본 사이트의 어떤 서비스 사용하거나, 이 앱의 어떤 프로세스, 정보 데이터 등을 다운로드하고
                  사용하는 것은 모두 고객님의 개인 결정이며, 고객님은 발생하는 여러 위험성과 손실을 책임집니다. 8.5 본 사이트에 링크된 어떤
                  제3자 사이트의 정보, 제품 및 업무, 다른 어떤 형식의 당사사 주체 내역에 속하지 않다는 것에 대해 어떠한 보증과 약속을 하지
                  않습니다. 고객님은 제3자 사이트가 제공한 서비스, 정보 및 제품을 사용하는 것을 스스로 결정하며, 그리고 이로 인해 생기는
                  모든 책임을 져야합니다. 8.6 본 사이트의 서비스를 사용하는 것에 대해 당사는 어떠한 명시적, 묵시적인 보증도 하지 않으며, 본
                  사이트 제공한 서비스의 적용성, 오류와 누락이 없고, 지속성, 정확성, 신뢰성, 어느 특정한 용도를 포함하지만 한정되지는
                  않습니다. 그리고 본 사이트에서 제공한 서비스에서 다루는 기술과 정보의 유효성, 정확성, 신뢰성, 품질, 안전, 완전성과
                  적시성에 대해 당사는 어떤 약속과 보증을 하지 않습니다. 본 사이트를 등록하거나 사용하는 것은 고객님의 결정이며, 스스로
                  위험성과 발생할수 있는 손실을 책임진다. 저희는 디지털자산 시장, 가치 및 가격 등에 대해 어떤 명시적, 묵시적인 보증을 하지
                  않습니다. 디지털자산 시장이 불안정하고, 가격과 가치가 수시로 변하고 폭락할 가능성도 있으며, 디지털자산을 거래하는 것은
                  고객님의 개인 선택이고 스스로 위험성과 가능한 손실을 책임진다는 것을 이해하고 동의합니다. 8.7 본 계약에 규정된 당사의
                  보증과 약속은 본 계약과 본 사이트가 제공한 서비스에 대한 당사의 유일한 보증과 진술이며, 그리고 다른 어떤 경로와 방식으로
                  나타난 보증과 약속을 대체하고 서면이나 구두, 명시적, 묵시적인 것을 막론합니다. 이 모든 보증과 진술은 모두 당사 자체의
                  약속과 보증이며, 어떤 제3자가 본 약관 중의 보증과 약속을 준수하는 것을 보증하는 것은 아닙니다. 8.8 본 계약에서 기재
                  되어있지 않은 권리 및 적용되는 법률에서의 최대한의 범위에서 당사의 손해배상책임을 제한, 면제, 상쇄한 권리를 포기하지 않을
                  것입니다. 8.9 고객님이 본 사이트에 등록하면 본 협의 중 규정된 규칙에 따라 당사에서 행해지는 여러 조작을 인정하며, 고객님은
                  이것으로 인해 발생할 수 있는 위험성을 책임져야 합니다. 9. 해약조항 9.1 본 사이트는 본 계약의 규정에 의거 고객님의 계정를
                  취소할 권리를 지니며, 본 협의는 고객의 계정이 해약된 날부터 종료됩니다. 9.2 본 사이트는 본 계약에 의거 모든 서비스를
                  중지할 권리를 지니며, 본 계약은 본 사이트의 고객님에게 제공되는 모든 서비스가 중지되는 날에 종료됩니다. 9.3 본 계약이
                  중지된 후, 고객님은 본 사이트에 계속 어떤 서비스를 제공하거나 어떤 다른 의무를 이행할 것을 요구할 권리가 없습니다. 또한
                  고객님은 고객님의 기존 계좌로 취득한 정보를 제 3자에게 전송할수 있는 권리가 없습니다. 9.4 본 계약의 종료는 감시
                  당사자(당사)가 위반 당사자(관련자)에 책임질 것을 요구하는 것을 막는 것이 아닙니다. 10. 지적 재산권 10.1 본 사이트에
                  포함되는 모든 지적 재산은 웹 사이트 표지, 데이터베이스, 웹 디자인, 문자, 그래픽, 소프트웨어, 사진, 비디오, 음악, 음성 및
                  전술한 파일의 여러 조합, 그리고 소프트웨어의 편집권, 관련 소스 코드, 소프트웨어( 작은응용 프로그램, 스크립트 포함)등의
                  지적 재산권은 본 사이트가 소유합니다. 고객님은 상업적 목적으로 위에 기술된 어떤 자료나 내용을 복제, 변조, 전송 및 사용할
                  수 없습니다. 10.2 본 사이트에 포함된 모든 권리(영업권, 상표, 로고 등을 포함하며 이것만으로는 한정하지 않습니다.)는 모두
                  당사가 소유합니다. 10.3 본 계약에 동의한다면 고객님 자신의 자유의지에 따라서 본 사이트상에 공개되어있는 여러 형식의 정보의
                  모든 저작권을 본 사이트에 독점적이고 무료로 양도하는 것으로 됩니다. 저작권, 복사권, 발행권, 대여권, 전람권, 연출권,
                  방영권, 방송권, 정보네트워크전발권, 제작권, 각색권, 번역권, 휘편권 및 기타 저작소유자가 양도할 권리 그리고 본 사이트는
                  그러한 저작권의 침해를 소송하고 그러한 침해에 대한 완전한 보상을 얻을 권리가 있습니다. 본 계약은 본 계약의 서명 전후에
                  컨텐츠의 생성 유무에 상관하지 않고 본 사이트 상에서 고객님에 의해 공개된 저작권법에 보호되고 있는 모든 컨텐츠에 적용
                  됩니다. 10.4 고객님이 본 사이트의 서비스를 사용하는 과정 중, 본 사이트와 다른 사람의 지적 소유권 권리를 불법으로
                  사용하거나 처분하지 못합니다. 본 사이트에서 발표한 정보를 어떤 형식으로 발표하거나 다른 사이트(매체)에 공개 하는 것을
                  금지합니다. 10.5 고객님이 본 사이트에 등록하거나 본 사이트 제공하는 모든 서비스를 사용하는 것은 당사가 고객님에게 지적
                  소유권을 양도하는 것이 아닙니다. 11. 정보의 보호 11.1 적용 범위 11.1.1 고객님이 본 사이트에 계좌를 등록하거나 사용할 때
                  사이트의 요구에 따라 개인 등록정보를 제공하며, 전화번호, 이메일 주소, 신분증 정보를 포함하지만 제한되지 않는 개인의
                  등록정보를 본 사이트의 요건에 따라서 제공해야 합니다. 11.1.2 고객님이 본 사이트의 서비스를 사용하거나 사이트에 방문할 때
                  본 사이트는 고객님의 웹 브라우저의 서버 정보를 자동적으로 수신하여 기록할 것입니다. IP주소등 데이터 및 원하는 웹 페이지
                  기록을 포함하지만 제한되지 않습니다. 11.1.3 본 사이트는 관련 데이터로서 고객님이 본 사이트에서 거래한 관련 거래 기록을
                  포함한 본 사이트상에서의 거래 데이터등 수집 되는 모든 데이터를 수집합니다. 11.1.4 본 사이트가 고객님에 의해 합법적으로
                  취득한 고객의 기타 개인 정보 11.2 정보의 사용 11.2.1 고객님이 본 사이트에 등록 완료 했다는 것은, 본 사이트에서
                  고객님으로부터 특별한 동의 없이, 정보를 수집해서 사용하는 것에 동의한것으로 판단합니다. 11.1에 규정 된것과 같이,고객님은
                  본 사이트에 의해 수집된 고객님의 정보를 이하의 특정 목적을 위해서 사용할 수 있는 것을 인정하고 동의해야합니다. 11.2.1.1 본
                  사이트가 고객님에게 제공하는 서비스를 위하여 11.2.1.2 관련된 주권 국가 또는 지역 관할당국의 명령에 기준하여, 규제 당국에
                  보고 할 경우 11.2.1.3 본 사이트가 제공하는 서비스를 사용할 때 , 본 사이트가 고객님에게 제공한 제품과 서비스의 안전성을
                  확보하기 위해 본 사이트는 고객님의 정보를 본인확인, 고객 서비스, 안전, 부정감시, 시장 홍보, 파일링과 백업용도 또는 제3자와
                  협력하여 사이트 홍보등 용도로 이용하는 경우 11.2.1.4 본 사이트가 신제품 및 서비스를 설계하여 본 사이트의 현재 서비스
                  개선을 목적으로 한 정보 수집과 처리 11.2.1.5 사이트 서비스의 구제적인 내용을 알리기 위해서, 본 사이트가 보내는 마케팅
                  이벤트 통지, 상업성 전자정보 및 일반적 홍보를 고객님에게 송신하는 것에 동의합니다. 11.2.1.6 본 사이트는 자산 합병, 분할,
                  인수 및 자산양도를 목적으로 본 사이트의 관련 당사자가 아닌 제3자에 고객님의 정보를 양도 혹은 개시할수 있습니다. 11.2.1.7
                  소프트웨어 인증 또는 관리 소프트웨어 업그레이드 할 경우 11.2.1.8 본 웹사이트에서 제공되고 있는 서비스에 관련해서
                  앙케이트에 참가 하도록 초대하는 경우 11.2.1.9 정부 기관, 공공 사무 기구, 협회 등과의 데이터 분석에 협력할 경우 11.2.1.10
                  기타 모든 법적 목적 및 고객님의 승인을 받은 목적을 위해 사용하는것. 11.2.2 본 사이트는 고객님의 동의를 사전에 받지 않으면
                  본 사이트는 다른 어떤 자에게 고객님의 개인정보를 판매하거나 빌려 주지 않을 것입니다. 또한 본 사이트에서는 제3자에 의한
                  고객님의 개인정보를 수집, 편집, 판매 및 무료배포하는 것을 금지합니다. 11.3 본 사이트는 취득한 고객 식별 정보 및 거래
                  정보등을 기밀로 하며, 관련 주권국가 및 지역의 법률, 법규,행정 당국의 명령을 제외하고 어떠한 개인 식별정보 혹은 거래정보도
                  제공하지 않습니다. 계산에 대하여 : 모든 거래의 계산은 당사에 의해 검증되어 그 계산 방법은 본 사이트에 공시되어있습니다만
                  본 사이트를 사용하는 것이 방해받거나 에러가 없다는 것을 보증하지는 않습니다. 수출관리에 대해서 : 홍콩의 관련법에 따라, 본
                  사이트상의 어떠한 소재(소프트웨어를 포함)도 수출, 재수출, 수입 혹은 양도 해서는 안됩니다.는 것을 이해하고 인식해야합니다.
                  때문에 고객은 자주적으로 위에 기술된 수출, 관련된 양도 혹은 다른 적용을 위반하는 법률, 법규의 행위를 자발적으로 실시하거나
                  협조하거나 참여하는 것을 금지합니다. 본 계약에서 합의된 권리와 의무는 이익을 취하는 당사자인 피양도인, 상속인, 집행자 및
                  관리자에 대하여 동등한 구속력을 지닙니다. 당사의 허가가 없는한, 고객님은 본 계약에 근거하여 고객님의 권리 및 의무를
                  제3자에게 양도할 수 없습니다. 그러나 당사는 언제든지 본 계약에 근거한 당사의 권리 및 의무를 30일전에 고객에게 통지하는
                  것으로 제3자에게 양도할 수 있습니다. 가분할성 : 본 계약의 어떤 한 조항이 관할 재판소로부터 법적 강제력이 없고, 무효 혹은
                  위법이라고 판단된 경우에도, 본 계약의 남은 조항의 유효성에는 영향을 미치지 않습니다. 본 계약서에서의 별도 규정이 없는 한
                  어떠한 대리인도 고객님의 대리인, 위탁인 및 다른 대표인으로서 취급되는 것은 인정하지 않습니다. 권리포기 : 본 계약에서
                  합의한 다른 당사자 또는 다른 책임의 불이행에 대해 다른 사람을 구속할 권리를 포기하거나 권리 또는 구제를 행사하지 않았다고
                  하더라도 그 외의 위반자에 대해서도 권리포기로 해석되는 것은 아닙니다. 타이틀 : 여기의 모든 타이틀은 계약을 서술하는 문구의
                  편의만을 위한 것이며, 본 계약 조항 및 조건의 내용 범위를 확대 혹은 한정하는 것이 아닙니다. 적용 법률： 본 계약의
                  전체내용은 전부 홍콩의 법률에 근거하여 체결한 계약서이다. 그에 따를 체결, 해석 내용 및 집행은 모두 홍콩 관련 법률규정에
                  적용됩니다. 본 계약에 합의된 서비스로 인해 발생하는 손해 배상 소송은 모두 홍콩 법률에 의거하여 관할되고 해석되며 집행될
                  것이다. 이 조항은 당사에 대한 권리침해 배상에 명확이 적용됩니다. 당사에 대한 혹은 당사와의 관계에 있어서 어떠한 청구 혹은
                  공소에 대해서도、관할 제판소 및 소송지는 홍콩에 존재합니다. 고객님은 재판 수속에 있어서 독점적 관할권 및 홍콩의 제판소에
                  있어서의 제판 수속과 공소에 대해서 신청할 권리를 가지고 있다. 본 계약의 효력및 해석 20.1 본 계약은 고객님이 본 사이트의
                  등록페이지를 클릭하여 등록수속을 완료하고, 본 사이트의 고객님의 계정과 패스워드를 취득할 때부터 효력이 생깁니다. 20.2 본
                  계약의 최종 해석권은 본 사이트에 귀속됩니다. 본인확인과 자금세탁방지를 위한 정책 서론 1.1 당사는 본인 확인 및
                  자금세탁방지와 관련된 법률, 법규를 엄격히 준수할 것이며, 또한 고의로 이 정책을 위반하지 않습니다. 합리적인 관리 범위
                  내에서 자금세탁으로 인한 손실을 최대한 막기 위해 필요한 조치와 기술을 채용하여 안심되고 안전한 서비스를 고객에세 제공할
                  것입니다. 1.2 당사의 본인 확인 및 자금세탁방지 정책은 고객님이 거주하고 있는 관할구역을 포함한 포괄적 국제정책체계입니다.
                  당사의 규범화된 프레임은 전세계적인 규제요건과 규제 기준을 만족하고, 당사 사이트의 지속적인 운영상의 지속가능성을
                  보증합니다. 본인확인과 자금세탁방지를 위한 정책 2.1 당사는 관렵된 법령에 의해 정의된 기준을 만족시킬수 있도록 본인 확인 및
                  자금세탁방지 정책을 반포하고 갱신합니다. 2.2 당사는 본 사이트의 운영에 관련하여 지도원칙과 규칙을 반포, 갱신 할것이며
                  당사의 직원은 이 원칙과 규정에 따라 서비스를 제공할 것입니다. 2.3 당사는 가능한 범위내에서 신분검증 등의 내부 감시와
                  거래를 제어하는 규칙을 디자인하고 완성하도록 합니다. 자금세탁방지를 제한합니다. 2.4 당사는 고객과 관련하여 실사 및
                  지속적인 감독을 수행하기 위해 위험 예방 기반 접근방식을 채택합니다. 2.5 정기적으로 거래에 대해 검사합니다. 2.6 의심스러운
                  거래를 발견한 경우에는 관할관청에 보고합니다. 2.7 가입시 제공된 고객정보는 최소 6년동안 유지될 것이다. 만일 이 증명문서가
                  규제당국에 제출 되는 경우가 있어도, 이일이 고객님에게 통지되는 일은 없습니다. 2.8 신용카드를 이용한 거래는
                  금지되어있습니다. 본인 확인과 검증 3.1 식별정보 3.1.1 다른 사법관할구의 다른 규정 및 다른 실체유형에 따라, 당사가 수집한
                  고객님의 정보는 불일치의 가능성이 있습니다. 때문에 원칙적으로 등록할 사람에게 다음과 같은 정보를 수집할 것입니다.
                  개인정보：고객님의 성함, 주소(영구주소 포함,불일치한 경우), 생년월일 및 국적 등 취득할 수 있는 다른신분증명은 정부당국이나
                  다른 유사한 권위기구가 발급한 문서(여권,신분증 및 기타 다른 관활구 요구한 신분증명문서)에 의거하여 검증합니다. 고객님이
                  제공한 주소는 고객님이 사용하는 교통수단인 승차권이나, 고객님 명의의 청구서 혹은 유권자등록부의 체크등 적정한 방법으로
                  검증됩니다. 유효한 사진：등록하기 전에 신분증을 가슴 앞에 내려 놓는 사진을 제공해야 합니다. 연락처：전화/휴대폰
                  번호/이메일 주소. 3.1.2 고객이 회사 또는 기타 종류의 법인일 경우, 당사는 계정 또는 귀사의 신탁계정 최종 수익자를 결정하기
                  위해 아래의 정보를 수집합니다. 회사등록증명서. 정관의 사본 및 회사 각서, 회사의 체제구조, 주주 구성의 상세한 자료,
                  웹사이트 회사의 계정 개설 및 집행을 담당하는 회사의 인정대리인 지정에 관한 이사회 결정서. 이사의 신분증, 회사의 주요주주
                  및 회사 웹사이트 계정의 승인된 서명자. 회사 본사의 소재지와 본사 소재지가 다른 경우, 배송지 주소와 소재지가 본사의
                  소재지와 다른 경우 등일 경우에는 리스크가 높은 고객으로 간주하여 추가 서류를 제출하도록 요구됩니다. 기타 인증문서,
                  관할당국이 발행한 문서 및 기타 문서는 관련된 관할구역의 법률 및 규제에 비추어 고객 사업체의 특정한 성질에 비추어
                  필요하다고 여겨질 때 등 제시를 요구할 수 있습니다. 3.1.3 당사는 고객의 신분 정보를 영문판과 중국어판, 한국어판 만을
                  받습니다. 고객의 신분정보가 3개의 언어 어느쪽에도 해당되지 않는 경우에는 귀하는 귀하의 신분정보를 영어로 번역하여 정식
                  공증을 받을 필요가 있습니다. 3.2 검증과 확인 3.2.1 당사는 신분증명서등을 요청할 수 있습니다. 3.2.2 고객님의 신분 증명서는
                  고객님의 가슴부근에 들고 찍은 사진을 제공할 필요가 있습니다. 3.2.3 보통 증명문서의 복제본은 원본과 대조 학인해야 합니다.
                  그러나 인증인(印)이 복제본과 원본이 일치한다는 것을 증명할 수 있다면 복제본을 받을 수 있다. 이러한 인증인(印)은 대사,
                  사법위원, 지방치안관 등이 포함됩니다. 3.2.4 수익자 및 계좌의 관리자의 식별은 어느 고객이 최종적으로 소유, 관리하고
                  있는지의 결정 및 진행 중인 거래가 다른 사람에 의해 이루어지고 있다는 결정에 근거하는 것으로 합니다. 고객이 법인일 경우, 그
                  주요주주(예를 들어, 그러한 사업체에 대해 의결권의 10%이상을 보유하고 있는 사람)의 신원을 확인해야 합니다. 일반적으로 회사
                  주식의 25%를 보유하는 주주는 평균적인 리스크수준에 있다고 여겨지며 의결권 또는 주식의 10%이상을 보유하는 주주는 높은
                  수준의 리스크를 수반한다고 간주되어 주주의 신원확인이 필요합니다. 거래관리 4.1 당사는 안전성과 실제거래의 상황에 따라
                  수시로 일상거래와 디지털자산 출금의 최고한도를 설정하고 조정합니다. 4.2 고객이 등록한 계정으로 빈번하게 거래가 집중되어
                  합리적 범위를 넘어간 경우, 당사의 전문팀이 그 거래가 수상한지를 평가 결정할 것입니다. 4.3 당사는 특정의 거래가
                  의심스럽다고 판단한 경우 거래를 중지시키거나 거래를 거부하는 등의 제한적 조치를 취할 수 있고, 귀하에 통지하지 않고, 거래를
                  취소하고 관할당국에 보고할 수 있습니다. 4.4 당사는 국제 자금세탁방지 기구의 표준 관할 구역에 맞지 않는 구역에서 온
                  사람이나 정치 공인으로 생각되는 사람들의 등록신청을 보류(거절)할 것입니다. 당사 자체의 판단으로 의심스러운 거래라고 생각한
                  경우에는, 당사는 즉시 해당 거래를 잠시 중지하고 중단시킬 권리가 있습니다. 그러나 당사가 이렇게 하는 것은 고객님에 대한
                  어떤 의무와 책임을 위반하지 않을 것입니다.
                </h2>
                <div style={{ height: '10px' }} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <button
                    className={agreeTermToggle ? 'SendEnquiry-toggle-on' : 'SendEnquiry-toggle-off'}
                    onClick={() => setAgreeTermToggle(!agreeTermToggle)}
                  />
                  <div style={{ width: '10px' }} />
                  <h3>Max Signal의 이용약관을 잘 읽고 동의함.</h3>
                </div>
                <div style={{ height: '60px' }} />
                <button
                  className="Signup-term-button"
                  onClick={() => {
                    if (agreeTermToggle) setAgreeTerm(true);
                  }}
                >
                  이용약관에 동의합니다
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {accountType != null && agreeTerm == true && (
        <>
          <div className="Signin-main">
            <div className="Signin-logoside">
              <img src={Logo} className="Signin-logoside-logo" />
              <div style={{ height: '38px' }} />
              <div className="Signin-logoside-h1">MAX4</div>
              <div style={{ height: '16px' }} />
              <div className="Signin-logoside-h2">Best Trading Education Program</div>
            </div>
            <div className="Signin-infoside">
              <button
                className="Signin-infoside-backbtn"
                onClick={() => {
                  setAccountType(null);
                  setAgreeTermToggle(false);
                  setAgreeTerm(false);
                }}
              >
                {t('signin-back')}
              </button>
              <div style={{ height: '20px' }} />
              <div className="Signup-infoside-box">
                <div style={{ height: '35px', display: 'flex', alignItems: 'center' }}>
                  <div className="Signup-box-title">
                    {accountType == 'individual' ? t('signin-signin-signup-text') : '비즈니스 회원가입'}
                  </div>
                  <div style={{ width: '20px' }} />
                  <div className="Signup-box-alert">{t('signup-asterisk-required')}</div>
                </div>
                <div style={{ height: '28px' }} />
                <div style={{ height: '20px', display: 'flex', alignItems: 'center' }}>
                  <div className="Signup-info-label">{t('signup-id')}</div>
                  <div style={{ width: '4px' }} />
                  <div className="Signup-info-asterisk">*</div>
                </div>
                <div style={{ height: '10px' }} />
                <div style={{ height: '32px', display: 'flex', justifyContent: 'space-between' }}>
                  <input
                    className="Signup-input"
                    placeholder={t('signup-alert-id-required')}
                    type="text"
                    value={id}
                    onChange={(e) => {
                      setId(e.target.value);
                      setIdValidation(false);
                    }}
                  />
                  <button className="Signup-confirm" type="button" onClick={confirmId}>
                    {t('signup-check-duplicates')}
                  </button>
                </div>
                <div style={{ height: '28px' }} />
                <div style={{ height: '20px', display: 'flex', alignItems: 'center' }}>
                  <div className="Signup-info-label">{t('signup-pw')}</div>
                  <div style={{ width: '4px' }} />
                  <div className="Signup-info-asterisk">*</div>
                </div>
                <div style={{ height: '10px' }} />
                <div style={{ height: '32px' }}>
                  <input
                    className="Signup-input-full"
                    placeholder={t('signup-password-conditions')}
                    type="password"
                    value={pw}
                    onChange={(e) => setPw(e.target.value)}
                  />
                </div>
                <div style={{ height: '10px' }} />
                <div style={{ height: '20px', display: 'flex', alignItems: 'center' }}>
                  <div className="Signup-info-label">{t('signup-pw-confirm')}</div>
                  <div style={{ width: '4px' }} />
                  <div className="Signup-info-asterisk">*</div>
                </div>
                <div style={{ height: '10px' }} />
                <div style={{ height: '32px' }}>
                  <input
                    className="Signup-input-full"
                    placeholder={t('signup-confirm-password-placeholder')}
                    type="password"
                    value={pwConfirm}
                    onChange={(e) => setPwConfirm(e.target.value)}
                  />
                </div>
                <div style={{ height: '28px' }} />
                <div style={{ height: '20px', display: 'flex', alignItems: 'center' }}>
                  <div className="Signup-info-label">{t('signup-email')}</div>
                  <div style={{ width: '4px' }} />
                  <div className="Signup-info-asterisk">*</div>
                </div>
                <div style={{ height: '10px' }} />
                <div style={{ height: '32px', display: 'flex', justifyContent: 'space-between' }}>
                  <input
                    className="Signup-input"
                    placeholder="example@gmail.com"
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailValidation(false);
                    }}
                  />
                  <button className="Signup-confirm" type="button" onClick={confirmEmail}>
                    {t('signup-check-duplicates')}
                  </button>
                </div>
                <div style={{ height: '28px' }} />
                <div style={{ height: '20px', display: 'flex', alignItems: 'center' }}>
                  <div className="Signup-info-label">{t('signup-phone')}</div>
                  <div style={{ width: '4px' }} />
                  <div className="Signup-info-asterisk">*</div>
                </div>
                <div style={{ height: '10px' }} />
                <div style={{ height: '42px', display: 'flex' }}>
                  <div style={{ width: '80px', height: '32px', position: 'relative' }}>
                    <button className="Signup-dropdown" onClick={() => setDropdownOn((val) => !val)}>
                      <img className="Signup-dropdown-img" src={CountryInfo[currentCountry].icon} />
                      <div className="Signup-dropdown-label">{CountryInfo[currentCountry].number}</div>
                      <div className="Signup-dropdown-arrow" />
                    </button>
                    {dropdownOn && (
                      <div className="Signup-dropdown-control">
                        {CountryInfo.map((e, i) => {
                          return (
                            <button
                              type="button"
                              className="signup-dropdown-menu"
                              onClick={() => {
                                setDropdownOn(false);
                                setCurrentCountry(i);
                              }}
                            >
                              <img className="Signup-dropdown-img" src={e.icon} />
                              <div style={{ width: '4px' }} />
                              <div className="Signup-dropdown-label">{e.number}</div>
                            </button>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div style={{ width: '10px' }} />
                  <input
                    className="Signup-phone-input"
                    placeholder="1012345678"
                    type="text"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setPhoneValidation(false);
                    }}
                  />
                  <span style={{ margin: 'auto' }} />
                  <button className="Signup-confirm" type="button" onClick={confirmPhone}>
                    {t('signup-check-duplicates')}
                  </button>
                </div>
                <div style={{ height: '28px' }} />
                <div style={{ height: '30px' }}>
                  <div className="Signup-info-label">{t('signup-recommender')}</div>
                </div>
                <input
                  className="Signup-input"
                  placeholder={t('signup-input-recommender')}
                  type="text"
                  value={recommender}
                  onChange={(e) => setRecommender(e.target.value)}
                />
                <div style={{ height: '28px' }} />
                <div style={{ height: '20px', display: 'flex', alignItems: 'center' }}>
                  <div className="Signup-info-label">{t('signup-wallet')}</div>
                  <div style={{ width: '4px' }} />
                  <div className="Signup-info-asterisk">*</div>
                </div>
                <div style={{ height: '10px' }} />
                {accountType == 'individual' ? (
                  <div style={{ height: '32px' }}>
                    <input
                      className="Signup-input-full"
                      placeholder={t('signup-input-wallet')}
                      type="text"
                      value={walletAddress}
                      onChange={(e) => setWalletAddress(e.target.value)}
                    />
                  </div>
                ) : (
                  <div style={{ height: '32px', display: 'flex', justifyContent: 'space-between' }}>
                    <input
                      className="Signup-input"
                      placeholder={t('signup-input-wallet')}
                      type="text"
                      value={walletAddress}
                      onChange={(e) => {
                        setWalletAddress(e.target.value);
                        setWalletValidation(false);
                      }}
                    />
                    <button className="Signup-confirm" type="button" onClick={confirmWallet}>
                      {t('signup-check-duplicates')}
                    </button>
                  </div>
                )}

                <div style={{ height: '10px' }} />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                  <div className="Signup-info-h1">{t('signup-wallet-p1')}</div>
                  <div className="Signup-info-h2">{t('signup-wallet-p2')}</div>
                  <div style={{ height: '4px' }} />
                  <div className="Signup-info-h2">{t('signup-wallet-p3')}</div>
                </div>
                <div style={{ height: '40px' }} />
                <button className="Signup-button" type="button" onClick={onSignup}>
                  {t('signup-title')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});
