import dayjs from 'dayjs';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Label,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
} from 'recharts';
import { Motion, spring } from 'react-motion';
import { ReactMotionLoop } from 'react-motion-loop';
import classNames from 'classnames';
import Modal from 'react-modal';

import styled from 'styled-components';

import Header from '../components/Header';

import LongIcon from 'img/long_icon.png';
import ShortIcon from 'img/short_icon.png';
import Icon_C1 from 'img/game_continuous_black.png';
import Icon_C2 from 'img/game_continuous_gold.png';
import Icon_F1 from 'img/game_fixed_black.png';
import Icon_F2 from 'img/game_fixed_gold.png';
import ResultDecoLeft from 'img/result_deco_left.png';
import ResultDecoRight from 'img/result_deco_right.png';
import RoundEndIcon from 'img/round_end_icon.png';
import BetStartIcon from 'img/bet_start_icon.png';
import DotLong from 'img/dot_long.png';
import DotShort from 'img/dot_short.png';
import ModeBtn1 from 'img/mode_btn_1.png';
import ModeBtn2 from 'img/mode_btn_2.png';
import BitcoinIcon from 'img/bitcoin_icon.png';

import { useTranslation } from 'react-i18next';
import {
  createGame,
  getGameState,
  sendBetting,
  getAccount,
  getActivatedGame,
  getRewards,
  getGameHistory,
  stopGame,
  getBusinessAccount,
} from '../api';
import '../css/Challenge.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const host = 'wss://stream.binance.com:9443';

const fps = 5;
const deltaTime = 1000 / fps;

interface PriceData {
  price: number | null;
  time: number;
}

interface GameState {
  token: string;
  bet: string | null;
  endRoundAt: Date;
  closeBetAt: Date;
  createdAt: Date;
  phase: string;
  priceAtBet: number | null;
  priceAtClose: number | null;
  round: number;
  won: number;
  serverTime: Date;
  clientTime: Date;
  endReason: string | null;
}

export default withRouter(function BusinessChallenge(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const [account, setAccount] =
    useState<{
      username: string;
      email: string;
      phone: string;
      wallet: string;
      membership?: string;
      wasMembershipApproved: boolean;
      pointA: number;
      pointB: number;
      pointC: number;
      pointD: number;
    } | null>(null);

  useEffect(() => {
    (async () => {
      const account = await getBusinessAccount();
      if (!account) {
        alert(t('challenge-login-first'));
        props.history.push('/signin');
        return;
      }

      setAccount(account);

      const result = await getActivatedGame();
      console.log(result);
      if (result) {
        setMode(result.mode);
        setStage(result.stage);
        gameState.token = result.token;
        getResult(false);
      } else {
        setPhase('mode');
      }
    })();
  }, []);

  // 차트에 쓰는 변수들
  const [pendingData, setPendingData] = useState<PriceData>({ price: null, time: 0 });
  const [data, setData] = useState<PriceData[]>([]);

  const [phase, setPhase] = useState<string>('loading');

  const [stage, setStage] = useState<string>('none');
  const [mode, setMode] = useState<string>('none');

  const [rewards, setRewards] = useState<Array<number>>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [showResult, setShowResult] = useState(false);

  const gameState = useMemo<GameState>(() => {
    return {
      token: '',
      bet: null,
      endRoundAt: new Date(),
      closeBetAt: new Date(),
      createdAt: new Date(),
      phase: 'betting',
      priceAtBet: 0,
      priceAtClose: 0,
      round: 1,
      serverTime: new Date(),
      clientTime: new Date(),
      won: 0,
      endReason: null,
    };
  }, []);

  const ws = useMemo(() => {
    const ws = new WebSocket(`${host}/stream`);

    ws.onopen = () => {
      console.log('connected!');
      ws.send(
        JSON.stringify({
          method: 'SUBSCRIBE',
          params: ['btcbusd@miniTicker'],
          id: Date.now(),
        }),
      );
    };
    ws.onclose = () => {
      console.log('disconnected!');
    };

    return ws;
  }, []);

  useEffect(() => {
    ws.onmessage = (e) => {
      if (gameState.phase == 'end') return;
      const json = JSON.parse(e.data);
      if (!json.data) return;
      const price = Number(json.data.c);
      const time = json.data.E;
      setPendingData({ price, time });
    };
  }, []);

  const [mouseEnter, setMouseEnter] = useState('none');
  const [frame, setFrame] = useState(0);
  const [remainTime, setRemainTime] = useState(0);
  const [priceDomain, setPriceDomain] = useState([0, 0]);
  const [currentPrice, setCurrentPrice] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [betIndex, setBetIndex] = useState<number>(-1);
  const [latestPrice, setLatestPrice] = useState<PriceData | null>(null);
  const [gameTime, setGameTime] = useState(0);

  useEffect(() => {
    if (gameState.phase == 'end') return;

    setTimeout(() => {
      setFrame((frame) => frame + 1);
    }, deltaTime);

    // do something
    setRemainTime(
      Math.max(
        0,
        (gameState.phase === 'betting' && gameState.bet == null ? gameState.closeBetAt.getTime() : gameState.endRoundAt.getTime()) -
          gameState.serverTime.getTime() +
          gameState.clientTime.getTime() -
          new Date().getTime(),
      ),
    );

    setGameTime(
      Math.max(
        0,
        Math.floor(
          3600 -
            (new Date().getTime() - gameState.createdAt.getTime() - gameState.clientTime.getTime() + gameState.serverTime.getTime()) / 1000,
        ),
      ),
    );

    setData((currentData) => {
      if (currentData.length == 0) {
        if (pendingData.price != null) {
          currentData.push(pendingData);
          for (let i = 1; i < 90 * fps; i++) {
            currentData.push({ price: null, time: pendingData.time + i * deltaTime });
          }
          setLatestPrice(currentData[0]);
        }
      } else {
        const count = currentData.filter((d) => d.price != null).length;

        if (pendingData.price != null) {
          const startIndex = latestPrice ? currentData.indexOf(latestPrice) : count;
          const index = currentData.findIndex((d) => Math.abs(d.time - pendingData.time) < deltaTime);
          const startPrice = currentData[startIndex].price;
          for (let i = startIndex + 1; i <= index; i++) {
            currentData[i].price = startPrice! + ((pendingData.price - startPrice!) * (i - startIndex)) / (index - startIndex);
          }
          setCurrentPrice(pendingData.price);
          setLatestPrice(currentData[index]);
        } else {
          const elem = currentData[count - 1];
          currentData[count].price = elem.price;
          setCurrentPrice(elem.price!);
        }

        if (gameState.bet == null) {
          for (let i = 0; i < currentData.filter((d) => d.price != null).length - 30 * fps; i++) {
            currentData.splice(0, 1);
            const elem = currentData[currentData.length - 1];
            currentData.push({ price: null, time: elem.time + deltaTime });
          }
        }
      }

      setCurrentIndex(currentData.filter((d) => d.price != null).length - 1);
      setPendingData({ price: null, time: 0 });
      setPriceDomain([
        Math.floor(Math.min(...currentData.map((d) => (d.price ? d.price : 0)).filter((p) => p != 0)) / 5) * 5 - 10,
        Math.floor(Math.max(...currentData.map((d) => (d.price ? d.price : 0))) / 5) * 5 + 10,
      ]);

      return [...currentData];
    });
  }, [frame]);

  const [gameHistory, setGameHistory] = useState<
    Array<{
      time: number;
      win: number;
      lose: number;
    } | null>
  >([null, null, null, null, null]);
  const [pendingResult, getResult] = useState<boolean | null>(null);

  useEffect(() => {
    if (pendingResult == null) return;
    if (pendingResult) {
      (async () => {
        // 챌린지 결과 받아오기
        for (;;) {
          const state = await getGameState(mode, gameState.token);
          if (state) {
            if (state.phase == 'end') {
              // 졌음
              gameState.phase = state.phase;
              gameState.won = state.won == null ? 0 : state.won;
              gameState.round = state.round;
              gameState.endReason = state.endReason;
              break;
            } else if (state.phase == 'waiting') {
              // 아직 챌린지 안 끝남
            } else if (state.phase == 'betting') {
              // 이겼고 다음 라운드 진행
              if (state.round > gameState.round) {
                if (mode == 'fixed' && (!state.won || (state.won && state.won == gameState.won))) {
                  toast.dark(t('challenge-round-lose'), {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    closeButton: true,
                  });
                } else {
                  toast.dark(t('challenge-round-win'), {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    closeButton: true,
                  });
                }
              }
              gameState.round = state.round;
              gameState.phase = state.phase;
              gameState.bet = state.bet;
              gameState.createdAt = state.createdAt;
              gameState.closeBetAt = state.closeBetAt;
              gameState.endRoundAt = state.endRoundAt;
              gameState.priceAtBet = state.priceAtBet;
              gameState.priceAtClose = state.priceAtClose;
              gameState.serverTime = state.time;
              gameState.clientTime = new Date();
              gameState.endReason = state.endReason;
              gameState.won = state.won == null ? 0 : state.won;
              break;
            } else {
              throw new Error(`unexpected result, phase : ${state.phase}`);
            }
          } else {
            // 챌린지 결과 받아오기 실패
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        setShowResult(gameState.phase == 'end');
        getResult(false);
      })();
    } else {
      // pendingResult 가 false -> 챌린지 시작했거나 아니면 라운드가 끝나고 결과를 다 받아온 것
      if (phase == 'game') {
        // 챌린지 결과를 다 받아오고 난 것.
        // 챌린지 진거면 그냥 끝내
        if (gameState.phase == 'end') {
          ws.close();
          return;
        }

        setBetIndex(-1);
        setData((currentData) => {
          const count = Math.max(0, currentData.filter((d) => d.price != null).length - 30 * fps);
          const elem = currentData[currentData.length - 1];
          currentData.splice(0, count);
          for (let i = 0; i < count; i++) {
            currentData.push({ price: null, time: elem.time + (i + 1) * deltaTime });
          }
          return [...currentData];
        });

        setTimeout(() => {
          if (gameState.phase != 'end') gameState.phase = 'waiting';
        }, gameState.closeBetAt.getTime() - gameState.serverTime.getTime());

        setTimeout(() => {
          // 베팅에 성공이든 아니든 결과는 받아와야할듯
          if (gameState.phase != 'end') getResult(true);
        }, gameState.endRoundAt.getTime() - gameState.serverTime.getTime());
      } else {
        (async () => {
          const rewards = await getRewards(mode, stage);
          if (!rewards) {
            return;
          }
          const history = await getGameHistory(mode, stage);
          if (!history) {
            return;
          }

          setGameHistory(history);
          setRewards(rewards);

          // console.log(history);
          // console.log(rewards);

          if (phase == 'stage') {
            setPhase('loading');
            const result = await createGame(mode, stage);
            if (result) {
              gameState.token = result.token;
            } else {
              // 챌린지 생성 실패
              props.history.push('/');
              return;
            }
          }
          // 로딩중
          const state = await getGameState(mode, gameState.token);
          if (state) {
            gameState.bet = state.bet;
            gameState.createdAt = state.createdAt;
            gameState.closeBetAt = state.closeBetAt;
            gameState.endRoundAt = state.endRoundAt;
            gameState.phase = state.phase;
            gameState.priceAtBet = state.priceAtBet;
            gameState.priceAtClose = state.priceAtClose;
            gameState.round = state.round;
            gameState.serverTime = state.time;
            gameState.clientTime = new Date();
            gameState.endReason = state.endReason;
            gameState.won = state.won == null ? 0 : state.won;
          }

          if (gameState.phase == 'end') {
            ws.close();
            alert(t('challenge-already-end'));
            return;
          }

          if (gameState.round == 1 && gameState.bet == null && phase == 'loading') {
            ws.close();
            await stopGame(mode, gameState.token);
            location.reload();
            return;
          }

          setTimeout(() => {
            if (gameState.phase != 'end') gameState.phase = 'waiting';
          }, gameState.closeBetAt.getTime() - gameState.serverTime.getTime());

          setTimeout(() => {
            // 베팅에 성공이든 아니든 결과는 받아와야할듯
            if (gameState.phase != 'end') getResult(true);
          }, gameState.endRoundAt.getTime() - gameState.serverTime.getTime());
          setPhase('game');
        })();
      }
    }
  }, [pendingResult]);

  function getRewardName(amount: number) {
    if (amount == 0) {
      return `0 ${t('challenge-point')}`;
    } else if (amount < 0) {
      return `${Math.abs(amount)} ${stage == 'game1' ? t('demo-point') : stage == 'game2' ? t('tournament-point') : t('live-point')}`;
    } else {
      return `${amount} ${stage == 'game1' ? t('tournament-point') : stage == 'game2' ? t('live-point') : t('eth-point')}`;
    }
  }

  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} />
      <div
        style={{
          minWidth: '1460px',
          height: '1080px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          backgroundColor: '#1c1b1a',
          whiteSpace: 'pre-line',
        }}
      >
        <Header />

        {phase == 'loading' && (
          <span style={{ margin: 'auto' }}>
            <div style={{ alignItems: 'center', justifyContent: 'center', color: 'white' }}>로딩중...</div>
          </span>
        )}

        {(phase == 'mode' || phase == 'stage') && (
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0 auto',
              justifyContent: 'center',
              alignItems: 'stretch',
            }}
          >
            <div>
              {phase == 'mode' && (
                <Motion defaultStyle={{ x: 0 }} style={{ x: spring(1) }}>
                  {(value) => (
                    <div
                      style={{
                        width: `${value.x * 820}px`,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        margin: 'auto',
                        overflow: 'hidden',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto' }}>
                        <button
                          className={mode == 'continuous' ? 'Challenge-modebtn-selected' : 'Challenge-modebtn'}
                          onClick={() => {
                            setMode('continuous');
                          }}
                        >
                          {mode != 'continuous' && <img style={{ position: 'absolute', left: 0, top: 0 }} src={ModeBtn1} />}
                          <div className="Challenge-modebtn-content">
                            <div style={{ height: '127px', alignItems: 'center', justifyContent: 'center' }}>
                              <img src={mode == 'continuous' ? Icon_C1 : Icon_C2} />
                            </div>
                            <div style={{ height: '19px' }} />
                            <div className={mode == 'continuous' ? 'Challenge-modebtn-label-selected' : 'Challenge-modebtn-label'}>
                              {t('continuous-mode')}
                            </div>
                          </div>
                        </button>
                        <div style={{ height: '20px' }} />
                        <div className="Challenge-modebtn-h1">{t('challenge-continuous-description')}</div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto' }}>
                        <button
                          className={mode == 'fixed' ? 'Challenge-modebtn-selected' : 'Challenge-modebtn'}
                          onClick={() => {
                            setMode('fixed');
                          }}
                        >
                          {mode != 'fixed' && <img style={{ position: 'absolute', left: 0, top: 0 }} src={ModeBtn2} />}
                          <div className="Challenge-modebtn-content">
                            <div style={{ height: '127px', alignItems: 'center', justifyContent: 'center' }}>
                              <img src={mode == 'fixed' ? Icon_F1 : Icon_F2} />
                            </div>
                            <div style={{ height: '19px' }} />
                            <div className={mode == 'fixed' ? 'Challenge-modebtn-label-selected' : 'Challenge-modebtn-label'}>
                              {t('fixed-mode')}
                            </div>
                          </div>
                        </button>
                        <div style={{ height: '20px' }} />
                        <div className="Challenge-modebtn-h1">{t('challenge-fixed-description')}</div>
                      </div>
                    </div>
                  )}
                </Motion>
              )}

              {phase == 'stage' && (
                <Motion defaultStyle={{ x: 0 }} style={{ x: spring(1) }}>
                  {(value) => (
                    <div
                      style={{
                        width: `${value.x * 980}px`,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        margin: 'auto',
                        overflow: 'hidden',
                      }}
                    >
                      <button
                        className={stage == 'game1' ? 'Challenge-stagebtn-selected' : 'Challenge-stagebtn'}
                        onClick={() => {
                          setStage('game1');
                        }}
                      >
                        {t('demo-challenge')}
                      </button>
                      <button
                        className={stage == 'game2' ? 'Challenge-stagebtn-selected' : 'Challenge-stagebtn'}
                        onClick={() => {
                          setStage('game2');
                        }}
                      >
                        {t('tournament-challenge')}
                      </button>
                      <button
                        className={stage == 'game3' ? 'Challenge-stagebtn-selected' : 'Challenge-stagebtn'}
                        onClick={() => {
                          setStage('game3');
                        }}
                      >
                        {t('live-challenge')}
                      </button>
                    </div>
                  )}
                </Motion>
              )}
            </div>

            <div className="Challenge-gameinfo-box">
              <div className="Challenge-gameinfo-box-h1">{t('challenge-settings')}</div>
              <div style={{ height: '20px' }} />
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', height: '29px' }}>
                <div className="Challenge-gameinfo-box-h2">{t('challenge-mode')}</div>
                <div className="Challenge-gameinfo-box-h2">{t('challenge-uses-point')}</div>
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', height: '60px' }}>
                <div className="Challenge-gameinfo-box-h3">
                  {mode == 'none' ? '-' : mode == 'fixed' ? t('fixed-mode') : t('continuous-mode')}
                </div>
                <div className="Challenge-gameinfo-box-h3">
                  {stage == 'none' ? '-' : stage == 'game1' ? t('demo-point') : stage == 'game2' ? t('tournament-point') : t('live-point')}
                </div>
              </div>
              <button
                className="Challenge-gameinfo-prebtn"
                onClick={() => {
                  if (phase == 'stage') setPhase('mode');
                }}
                disabled={!(phase == 'stage')}
              >
                {t('challenge-previous')}
              </button>
              <div style={{ height: '20px' }} />
              <button
                className="Challenge-gameinfo-nextbtn"
                onClick={() => {
                  if (phase == 'mode') setPhase('stage');
                  else if (phase == 'stage') getResult(false);
                }}
                disabled={!((phase == 'mode' && mode != 'none') || (phase == 'stage' && stage != 'none'))}
              >
                {t('challenge-next')}
              </button>
            </div>
          </span>
        )}

        {phase == 'game' && (
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0 auto',
              justifyContent: 'center',
              alignItems: 'stretch',
            }}
          >
            <div style={{ display: 'flex', width: '1460px', height: '558px', justifyContent: 'space-between' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '1140px',
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
                }}
              >
                <div style={{ height: '45px', display: 'flex', justifyContent: 'space-between' }}>
                  <div className="Challenge-stage">
                    <div className={stage == 'game1' ? 'Challenge-stage-label1' : 'Challenge-stage-label2'}>{t('demo-challenge')}</div>
                    <div className={stage == 'game2' ? 'Challenge-stage-label1' : 'Challenge-stage-label2'}>
                      {t('tournament-challenge')}
                    </div>
                    <div className={stage == 'game3' ? 'Challenge-stage-label1' : 'Challenge-stage-label2'}>{t('live-challenge')}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="Challenge-time-h1">{t('challenge-remain-time')}</div>
                    <div className="Challenge-time-h2">
                      {Math.floor(gameTime / 60)
                        .toString()
                        .padStart(2, '0')}
                      :{(gameTime % 60).toString().padStart(2, '0')}
                    </div>
                  </div>
                  <div className="Challenge-mode">
                    <div className={mode == 'continuous' ? 'Challenge-stage-label1' : 'Challenge-stage-label2'}>{t('continuous-mode')}</div>
                    <div className={mode == 'fixed' ? 'Challenge-stage-label1' : 'Challenge-stage-label2'}>{t('fixed-mode')}</div>
                  </div>
                </div>
                <div style={{ position: 'relative', width: '1140px', height: '493px' }}>
                  {gameState.bet != null && (
                    <div
                      style={{
                        position: 'absolute',
                        width: '5px',
                        height: '493px',
                        left: `${(1140 * betIndex) / data.length}px`,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <div className="Challenge-betstart-line" />
                      <img src={BetStartIcon} />
                      <div className="Challenge-betstart-label">START</div>
                    </div>
                  )}
                  {data.findIndex((val) => Math.abs(gameState.endRoundAt.getTime() - val.time) < deltaTime) >= 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        width: '5px',
                        height: '493px',
                        left: `${
                          (1140 * data.findIndex((val) => Math.abs(gameState.endRoundAt.getTime() - val.time) < deltaTime)) / data.length
                        }px`,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <div className="Challenge-roundend-line" />
                      <img src={RoundEndIcon} />
                      <div className="Challenge-roundend-label">END</div>
                    </div>
                  )}

                  {mouseEnter == 'long' && (
                    <div
                      style={{
                        width: '1140px',
                        height: `${((priceDomain[1] - currentPrice) / (priceDomain[1] - priceDomain[0])) * 493}px`,
                        position: 'absolute',
                        top: '0',
                        backgroundColor: '#91bc6a',
                        opacity: 0.2,
                      }}
                    ></div>
                  )}
                  {mouseEnter == 'short' && (
                    <div
                      style={{
                        width: '1140px',
                        height: `${493 - ((priceDomain[1] - currentPrice) / (priceDomain[1] - priceDomain[0])) * 493}px`,
                        position: 'absolute',
                        bottom: '0',
                        backgroundColor: '#c96161',
                        opacity: 0.2,
                      }}
                    ></div>
                  )}
                  <div
                    style={{
                      width: '1140px',
                      height: '20px',
                      position: 'absolute',
                      left: '0',
                      top: `${((priceDomain[1] - currentPrice) / (priceDomain[1] - priceDomain[0])) * 493 - 10}px`,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ width: '1085px', border: 'solid 1px #ffffff', opacity: 0.75 }}></div>
                    <div className="Challenge-pricetag">{Math.floor(currentPrice * 100) / 100}</div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      left: `${(1140 * currentIndex) / data.length}px`,
                      top: `${((priceDomain[1] - currentPrice) / (priceDomain[1] - priceDomain[0])) * 493 - 3}px`,
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      backgroundColor: '#ffffff',
                    }}
                  />
                  <img src={BitcoinIcon} style={{ position: 'absolute', left: '10px', top: '10px', width: '24px', height: '24px' }} />
                  <div className="Challenge-bitcoin-label">BTC/USD(Binance)</div>
                  {betIndex > -1 && (
                    <div
                      style={{
                        width: '1140px',
                        height: '20px',
                        position: 'absolute',
                        left: '0',
                        top: `${((priceDomain[1] - data[betIndex].price!) / (priceDomain[1] - priceDomain[0])) * 493 - 10}px`,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{ width: '1085px', border: `solid 1px ${gameState.bet == 'long' ? '#91bc6a' : '#c96161'}`, opacity: 0.75 }}
                      ></div>
                      <div className={gameState.bet == 'long' ? 'Challenge-pricetag-long' : 'Challenge-pricetag-short'}>
                        {Math.floor(data[betIndex].price! * 100) / 100}
                      </div>
                    </div>
                  )}
                  {betIndex > -1 && (
                    <img
                      src={gameState.bet == 'long' ? DotLong : DotShort}
                      style={{
                        width: '42px',
                        height: '42px',
                        position: 'absolute',
                        left: `${(1140 * betIndex) / data.length - 21}px`,
                        top: `${((priceDomain[1] - data[betIndex].price!) / (priceDomain[1] - priceDomain[0])) * 493 - 21}px`,
                        zIndex: 1,
                      }}
                    />
                  )}
                  <AreaChart width={1140} height={493} data={data} style={{ position: 'relative' }}>
                    <defs>
                      <linearGradient id="fill" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#ffffff" stopOpacity={0.5} />
                        <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="time"
                      mirror={true}
                      tickSize={2}
                      minTickGap={fps * 10}
                      tickFormatter={(time) => dayjs(time).format('HH:mm:ss')}
                      opacity="1"
                    />
                    <YAxis dataKey="price" domain={priceDomain} mirror={true} hide={true} />
                    <CartesianGrid
                      verticalPoints={data.filter((d) => d.time % 10000 < deltaTime).map((d) => (data.indexOf(d) * 1140) / data.length)}
                      opacity="0.15"
                    />
                    <Tooltip
                      wrapperStyle={{ background: 'none', border: 'none' }}
                      contentStyle={{ backgroundColor: '#242322', boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.35)', borderRadius: '4px' }}
                      itemStyle={{ color: 'white' }}
                      labelStyle={{ color: 'white' }}
                      separator={''}
                      filterNull={false}
                      formatter={function (value: number | null, name: string) {
                        if (value == null) return ['', ''];
                        else return [`${t('challenge-tooltip-price')}: ${Math.floor(value * 100) / 100}`, ''];
                      }}
                      labelFormatter={function (value) {
                        return `${t('challenge-tooltip-time')}: ${dayjs(value).format('HH:mm:ss')}`;
                      }}
                    />
                    <Area
                      type="monotone"
                      connectNulls={true}
                      dataKey="price"
                      stroke="#ffffff"
                      fillOpacity={1}
                      fill="url(#fill)"
                      strokeWidth={3}
                      isAnimationActive={false}
                    />
                  </AreaChart>
                </div>
              </div>
              <div className="Challenge-infobox">
                <div className="Challenge-infobox-h1">
                  {stage == 'game1' ? t('demo-challenge') : stage == 'game2' ? t('tournament-challenge') : t('live-challenge')}
                </div>
                <div style={{ height: '1px', opacity: 0.15, border: 'solid 1px #ffffff' }} />
                <div style={{ margin: '19px 20px 40px', display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '20px 40px' }}>
                  <div className="Challenge-infobox-h2">{t('challenge-round')}</div>
                  <div className="Challenge-infobox-h2">
                    {gameState.phase === 'betting' && gameState.bet == null
                      ? t('challenge-remaining-time-to-betting')
                      : t('challenge-remaining-time-to-round')}
                  </div>
                  <div className="Challenge-infobox-h3">{gameState.round}</div>
                  <div className="Challenge-infobox-h3">{`${Math.floor(remainTime / 1000)
                    .toString()
                    .padStart(2, '0')}:${Math.floor((remainTime % 1000) / 10)
                    .toString()
                    .padStart(2, '0')}`}</div>
                </div>
                <div style={{ margin: '0 20px', display: 'grid', gridTemplateColumns: '1fr 1fr', height: '20px' }}>
                  <div className="Challenge-infobox-h2">{mode == 'fixed' ? t('fixed-mode') : t('continuous-mode')}</div>
                  <div className="Challenge-infobox-h2">{t('Challenge-my-points')}</div>
                </div>
                <div style={{ margin: '0 20px', display: 'grid', gridTemplateColumns: '1fr 1fr', height: 'auto' }}>
                  <div className="Challenge-infobox-h3">
                    {mode == 'fixed'
                      ? `${gameState.won == null ? 0 : gameState.won}${t('challenge-win')} ${
                          gameState.round - 1 - (gameState.won == null ? 0 : gameState.won)
                        }${t('challenge-lose')}`
                      : `${gameState.round - 1}${t('challenge-straight-win')}`}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: '10px' }} />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={stage == 'game1' ? PointAIcon : stage == 'game2' ? PointBIcon : PointCIcon}
                        style={{ width: '28px', height: '28px' }}
                      />
                      <div style={{ width: '10px' }} />
                      <div className="Challenge-infobox-h4">
                        {(stage == 'game1' ? account?.pointA : stage == 'game2' ? account?.pointB : account?.pointC)!
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                    </div>
                    <div style={{ height: '10px' }} />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={stage == 'game1' ? PointBIcon : stage == 'game2' ? PointCIcon : PointDIcon}
                        style={{ width: '28px', height: '28px' }}
                      />
                      <div style={{ width: '10px' }} />
                      <div className="Challenge-infobox-h4">
                        {(stage == 'game1' ? account?.pointB : stage == 'game2' ? account?.pointC : account?.pointD)!
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                    </div>
                  </div>
                </div>
                <span style={{ margin: 'auto' }} />
                <div className="Challenge-infobox-control">
                  <div
                    style={{
                      width: '260px',
                      display: 'grid',
                      gridTemplateColumns: '125px 125px',
                      gridTemplateRows: '125px 26px',
                      gap: '10px',
                      margin: '10px auto',
                    }}
                  >
                    <button
                      className="Challenge-bet-btn"
                      type="button"
                      disabled={gameState.phase !== 'betting'}
                      onClick={() => {
                        if (gameState.phase !== 'betting') return;
                        gameState.bet = 'long';
                        gameState.phase = 'waiting';
                        setBetIndex(currentIndex);
                        setMouseEnter('none');
                        (async () => {
                          const result = await sendBetting(mode, gameState.token, 'long');

                          if (result?.success == true) {
                            toast.success(t('challenge-toast-bet-long'), {
                              position: 'top-right',
                              autoClose: 3000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: false,
                              draggable: true,
                              closeButton: true,
                            });
                          } else {
                            alert(t('challenge-alert-not-enough-point'));
                            props.history.push('/');
                          }
                        })();
                      }}
                      onMouseEnter={() => {
                        setMouseEnter('long');
                      }}
                      onMouseLeave={() => {
                        setMouseEnter('none');
                      }}
                    >
                      <img src={LongIcon} />
                    </button>
                    <button
                      className="Challenge-bet-btn"
                      type="button"
                      disabled={gameState.phase !== 'betting'}
                      onClick={() => {
                        if (gameState.phase !== 'betting') return;
                        gameState.bet = 'short';
                        gameState.phase = 'waiting';
                        setBetIndex(currentIndex);
                        setMouseEnter('none');
                        (async () => {
                          const result = await sendBetting(mode, gameState.token, 'short');
                          if (result?.success == true) {
                            toast.error(t('challenge-toast-bet-short'), {
                              position: 'top-right',
                              autoClose: 3000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: false,
                              draggable: true,
                              closeButton: true,
                            });
                          } else {
                            alert(t('challenge-alert-not-enough-point'));
                            props.history.push('/');
                          }
                        })();
                      }}
                      onMouseEnter={() => {
                        setMouseEnter('short');
                      }}
                      onMouseLeave={() => {
                        setMouseEnter('none');
                      }}
                    >
                      <img src={ShortIcon} />
                    </button>
                    <div className="Challenge-infobox-long-label">LONG</div>
                    <div className="Challenge-infobox-short-label">SHORT</div>
                  </div>
                  <div className="Challenge-infobox-control-label">CONTROL PANEL</div>
                </div>
                <button
                  className="Challenge-infobox-stopbtn"
                  disabled={gameState.bet != null}
                  onClick={() => {
                    if (gameState.bet != null) return;
                    (async () => {
                      const result = await stopGame(mode, gameState.token);
                      if (result?.success == true) {
                        // 챌린지 종료 성공임
                        getResult(true);
                      } else {
                        alert('Error occured');
                        props.history.push('/');
                      }
                    })();
                  }}
                >
                  {mode == 'continuous' ? t('challenge-stop-challenge') : t('challenge-end-normal')}
                </button>
              </div>
            </div>
            <div style={{ height: '20px' }} />
            <div style={{ display: 'flex', height: '270px', justifyContent: 'space-between' }}>
              <div className="Challenge-rewards-box">
                <div
                  style={{
                    height: '42px',
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className="Challenge-rewards-title">
                    {mode == 'continuous' ? t('challenges-continuous-rewards') : t('challenge-rewards')}
                  </div>
                  <div className="Challenge-rewards-h1">
                    * STOP/보상 받기 버튼을 클릭하지 않고 다음 라운드를 진행하여 예측 실패시 획득한 보상을 얻을 수 없습니다.
                  </div>
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(10, 1fr)',
                    gridTemplateRows: 'repeat(2, 1fr)',
                    height: '228px',
                  }}
                >
                  {rewards.map((e, i) => {
                    return (
                      <RewardInfo
                        index={i + 1}
                        reward={e}
                        state={
                          mode == 'fixed'
                            ? i < gameState.won
                              ? 'win'
                              : i <= 20 - gameState.round + gameState.won
                              ? 'none'
                              : 'lose'
                            : i < gameState.round - 1
                            ? 'win'
                            : 'none'
                        }
                        stage={stage}
                      />
                    );
                  })}
                </div>
              </div>
              <div style={{ width: '620px', display: 'flex', flexDirection: 'column' }}>
                <div className="Challenge-record-box">
                  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gridTemplateRows: '43px' }}>
                    <div className="Challenge-record-top-h1">TIME</div>
                    <div className="Challenge-record-top-h2">WIN</div>
                    <div className="Challenge-record-top-h1">LOSS</div>
                    <div className="Challenge-record-top-h1">{t('challenge-total-rewards-label')}</div>
                  </div>
                </div>
                <div
                  style={{
                    height: '225px',
                    width: '620px',
                    display: 'grid',
                    gridTemplateRows: 'repeat(5, 1fr)',
                    overflow: 'hidden',
                  }}
                >
                  {gameHistory.map((e) => {
                    return (
                      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4,1fr)', width: '620px' }}>
                        <div className="Challenge-record-h1">
                          {e != null
                            ? `${Math.floor(e.time / 60000)
                                .toString()
                                .padStart(2, '0')}:${Math.floor((e.time % 60000) / 1000)
                                .toString()
                                .padStart(2, '0')}`
                            : ''}
                        </div>
                        <div className="Challenge-record-h2">{e != null ? e.win : ''}</div>
                        <div className="Challenge-record-h3">{e != null ? e.lose : ''}</div>
                        <div className="Challenge-record-h4">
                          {e != null
                            ? mode == 'continuous' && e.lose > 0
                              ? 0
                              : e.win == 0
                              ? 0
                              : rewards[e.win - 1] == 0
                              ? 0
                              : `${Math.abs(rewards[e.win - 1])}P`
                            : ''}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Modal isOpen={showResult} className="Challenge-result-background" style={{ overlay: { background: 'rgba(0, 0, 0, 0.7)' } }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="Challenge-result-modal">
                  <div className="Challenge-result-modal-h1">
                    {gameState.endReason == 'ended'
                      ? t('challenge-end-normal')
                      : gameState.endReason == 'expired'
                      ? t('challenge-end-expired')
                      : t('challenge-end-lose')}
                  </div>
                  <div style={{ height: '20px' }} />
                  <div className="Challenge-result-modal-b1">
                    <div className="Challenge-result-modal-h2">{t('challenge-result-stage')}</div>
                    <div className="Challenge-result-modal-h3">
                      {stage == 'game1' ? t('demo-challenge') : stage == 'game2' ? t('tournament-challenge') : t('live-challenge')}
                    </div>
                    <div className="Challenge-result-modal-h2">{t('challenge-mode')}</div>
                    <div className="Challenge-result-modal-h3">{mode == 'fixed' ? t('fixed-mode') : t('continuous-mode')}</div>
                  </div>
                  <div style={{ height: '40px' }} />
                  <div className="Challenge-result-modal-h4">{t('challenge-result-your-records')}</div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={ResultDecoLeft} />
                    <div className="Challenge-result-modal-h5">
                      {mode == 'fixed'
                        ? `${gameState.won == null ? 0 : gameState.won}${t('challenge-win')} ${
                            gameState.round - 1 - (gameState.won == null ? 0 : gameState.won)
                          }${t('challenge-lose')}`
                        : `${gameState.round - 1}${t('challenge-straight-win')}`}
                    </div>
                    <img src={ResultDecoRight} />
                  </div>
                  <div className="Challenge-result-modal-h4">{t('challenge-result-total-points')}</div>
                  <div style={{ height: '10px' }} />
                  <div className="Challenge-result-modal-h6">
                    {gameState.endReason != 'ended' || (mode == 'fixed' && gameState.won == 0) || gameState.round == 1
                      ? `0 ${t('challenge-point')}`
                      : getRewardName(rewards[(mode == 'fixed' ? gameState.won : gameState.round - 1) - 1])}
                  </div>
                  <div style={{ height: '40px' }} />
                  <button
                    type="button"
                    className="Challenge-result-modal-b2"
                    onClick={() => {
                      location.reload();
                    }}
                  >
                    {t('challenge-result-confirm')}
                  </button>
                </div>
              </div>
            </Modal>
          </span>
        )}

        <footer className="Challenge-footer">
          <div
            style={{ width: '1100px', height: '76px', display: 'flex', margin: '0 auto', alignItems: 'center', justifyContent: 'center' }}
          >
            <div className="Challenge-footer-copyrights">ⓒ MAX SIGNAL 2021 All rights reserved.</div>
            <div style={{ width: '40px' }} />
            <div className="Challenge-footer-support-title">{t('footer-support')}</div>
            <div style={{ width: '10px' }} />
            <div className="Challenge-footer-support">maxsignalhongkong@gmail.com</div>
          </div>
        </footer>
      </div>
    </>
  );
});

import WinIcon from 'img/win.png';
import LoseIcon from 'img/lose.png';
import PointAIcon from 'img/point_a.png';
import PointBIcon from 'img/point_b.png';
import PointCIcon from 'img/point_c.png';
import PointDIcon from 'img/point_d.png';

const RewardInfo = (props: { index: number; reward: number; state: string; stage: string }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <div className="Challenge-rewards-round">{props.index}</div>
      <div className="Challenge-rewards-round-reward">
        {props.state == 'win' && <img src={WinIcon} />}
        {props.state == 'lose' && <img src={LoseIcon} />}
        {props.state == 'none' &&
          props.reward != 0 &&
          (props.reward < 0 ? (
            <>
              <img
                src={props.stage == 'game1' ? PointAIcon : props.stage == 'game2' ? PointBIcon : PointCIcon}
                style={{ width: '56px', height: '56px' }}
              />
              <div className="Challenge-rewards-label">+{Math.abs(props.reward)}$</div>
              <div className="Challenge-rewards-label-shadow">+{Math.abs(props.reward)}$</div>
            </>
          ) : (
            <>
              <img
                src={props.stage == 'game1' ? PointBIcon : props.stage == 'game2' ? PointCIcon : PointDIcon}
                style={{ width: '56px', height: '56px' }}
              />
              <div className="Challenge-rewards-label">+{props.reward}$</div>
              <div className="Challenge-rewards-label-shadow">+{props.reward}$</div>
            </>
          ))}
      </div>
    </div>
  );
};
