import React, { ReactElement, useContext, useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import Logo from 'img/logo.png';
import LanguageIcon from 'img/language_icon.png';
import KoreaIcon from 'img/korea.png';
import VietnamIcon from 'img/vietnam.png';
import USAIcon from 'img/usa.png';

import { signout } from '../api';
import TokenContext from '../token-context';
import '../css/Header.css';

const languageLabel = new Map([
  ['ko-KR', 'KR'],
  ['en-US', 'EN'],
  ['vi', 'VI'],
]);

export default function Header(props: { backgroundColor?: string }): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);

  const [langOn, setLangOn] = useState<boolean>(false);

  const [accountType, setAccountType] = useState<string | null>(null);

  useEffect(() => {
    setAccountType(localStorage.getItem('accountType'));
  }, []);

  return (
    <header
      style={{
        height: '76px',
        boxShadow: 'inset 0 -1px 0 0 #a89670',
        backgroundColor: props.backgroundColor ? props.backgroundColor : '#1c1b1a',
      }}
    >
      <div className="App-header">
        <Link to="/" className="Header-logo">
          <img src={Logo}></img>
        </Link>
        <Link to="/challenge-info" className="Header-link">
          {t('main-menu-challenge-info')}
        </Link>
        <Link
          to={accountType && accountType == 'business' ? '/business-challenge-participate' : '/challenge-participate'}
          className="Header-link"
        >
          {t('main-menu-challenge-participate')}
        </Link>
        <Link to="future-trading" className="Header-link">
          {'퓨처 트레이딩'}
        </Link>
        <Link to="/customercare" className="Header-link">
          {t('main-menu-customercare')}
        </Link>
        <Link to="/notice" className="Header-link">
          {t('main-menu-notice')}
        </Link>
        <span style={{ margin: 'auto' }} />
        {!tokenContext.token && (
          <Link to="/signup" className="Header-link">
            {t('main-menu-signup')}
          </Link>
        )}
        {tokenContext.token && (
          <Link to={accountType && accountType == 'business' ? '/business-mypage' : '/mypage'} className="Header-link">
            {t('main-menu-mypage')}
          </Link>
        )}
        {!tokenContext.token && (
          <Link to="/signin" className="Header-link">
            {t('main-menu-signin')}
          </Link>
        )}
        {tokenContext.token && (
          <Route
            render={(props) => (
              <button
                className="Header-link"
                onClick={async () => {
                  await signout();
                  props.history.push('/');
                  tokenContext.setToken(null);
                }}
              >
                {t('main-menu-signout')}
              </button>
            )}
          />
        )}
        <div style={{ width: '120px', height: '76px', position: 'relative' }}>
          <button
            type="button"
            className={langOn ? 'Header-language-selected' : 'Header-language'}
            onClick={() => {
              setLangOn((o) => !o);
            }}
          >
            <img src={LanguageIcon} className="Header-language-logo" />
            <div className="Header-language-current">{languageLabel.has(i18n.language) ? languageLabel.get(i18n.language) : ''}</div>
            <div className="Header-language-arrow" />
          </button>
          {langOn && (
            <div className="Header-language-dropdown">
              <div className="Header-language-dropdown-title">LANGUAGE</div>
              <button
                className={i18n.language == 'ko-KR' ? 'Header-language-dropdown-menu-selected' : 'Header-language-dropdown-menu'}
                onClick={() => {
                  i18n.changeLanguage('ko-KR');
                  setLangOn(false);
                }}
                disabled={i18n.language == 'ko-KR'}
              >
                <img src={KoreaIcon} style={{ width: '28px', height: '20px', margin: '0 8px' }} />
                <div
                  className={
                    i18n.language == 'ko-KR' ? 'Header-language-dropdown-menu-label-selected' : 'Header-language-dropdown-menu-label'
                  }
                >
                  한국어
                </div>
              </button>
              <button
                className={i18n.language == 'en-US' ? 'Header-language-dropdown-menu-selected' : 'Header-language-dropdown-menu'}
                onClick={() => {
                  i18n.changeLanguage('en-US');
                  setLangOn(false);
                }}
                disabled={i18n.language == 'en-US'}
              >
                <img src={USAIcon} style={{ width: '28px', height: '20px', margin: '0 8px' }} />
                <div
                  className={
                    i18n.language == 'en-US' ? 'Header-language-dropdown-menu-label-selected' : 'Header-language-dropdown-menu-label'
                  }
                >
                  English
                </div>
              </button>
              <button
                className={i18n.language == 'vi' ? 'Header-language-dropdown-menu-selected' : 'Header-language-dropdown-menu'}
                onClick={() => {
                  i18n.changeLanguage('vi');
                  setLangOn(false);
                }}
                disabled={i18n.language == 'vi'}
              >
                <img src={VietnamIcon} style={{ width: '28px', height: '20px', margin: '0 8px' }} />
                <div
                  className={i18n.language == 'vi' ? 'Header-language-dropdown-menu-label-selected' : 'Header-language-dropdown-menu-label'}
                >
                  tiếng Việt
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
