import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../components/Header';
import Footer from '../components/Footer';
import '../css/Introduction.css';

import ContinuousIcon from 'img/game_continuous_gold.png';
import FixedIcon from 'img/game_fixed_gold.png';
import LongIcon from 'img/long_icon.png';
import ShortIcon from 'img/short_icon.png';
import { getRewards } from '../api';

export default function Introduction(): ReactElement {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [tab, setTab] = useState<number>(0);

  const [rewards, setRewards] =
    useState<{
      game1: {
        continuous: number[];
        fixed: number[];
      };
      game2: {
        continuous: number[];
        fixed: number[];
      };
      game3: {
        continuous: number[];
        fixed: number[];
      };
    } | null>(null);

  useEffect(() => {
    (async () => {
      const stages: Array<'game1' | 'game2' | 'game3'> = ['game1', 'game2', 'game3'];
      const modes: Array<'continuous' | 'fixed'> = ['continuous', 'fixed'];
      const rewards = {
        game1: {
          continuous: [0],
          fixed: [0],
        },
        game2: {
          continuous: [0],
          fixed: [0],
        },
        game3: {
          continuous: [0],
          fixed: [0],
        },
      };
      for (let i = 0; i < stages.length; i++) {
        const stage = stages[i];
        for (let j = 0; j < modes.length; j++) {
          const mode = modes[j];
          const reward = await getRewards(mode, stage);
          if (!reward) return;
          rewards[stage][mode] = reward;
        }
      }
      setRewards(rewards);
    })();
  }, []);

  return (
    <div className="App">
      <Header />
      <div className="Introduction-main">
        <div style={{ height: '116px' }} />
        <div style={{ display: 'flex' }}>
          <button className="Introduction-title" onClick={() => setTab(0)} disabled={tab == 0}>
            {t('introduction-introduction')}
          </button>
          <div className="Introduction-title">&ensp;/&ensp;</div>
          <button
            className="Introduction-title"
            onClick={() => {
              setTab(1);
              setPage(0);
            }}
            disabled={tab == 1}
          >
            {t('introduction-how-to-play')}
          </button>
        </div>
        {tab == 0 && (
          <>
            <div style={{ height: '20px' }} />
            <div className="Introduction-h1">{t('introduction-introduction-p1')}</div>
            <div style={{ height: '40px' }} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch' }}>
              <div className="Introduction-wallet-box">
                <div className="Introduction-stage-box-h1">{t('introduction-introduction-t1')}</div>
                <div style={{ height: '20px' }} />
                <div className="Introduction-stage-box-h5">{t('introduction-introduction-d1')}</div>
              </div>
              <div style={{ width: '20px' }} />
              <div className="Introduction-wallet-box">
                <div className="Introduction-stage-box-h1">{t('introduction-introduction-t2')}</div>
                <div style={{ height: '20px' }} />
                <div className="Introduction-stage-box-h5">{t('introduction-introduction-d2')}</div>
              </div>
              <div style={{ width: '20px' }} />
              <div className="Introduction-wallet-box">
                <div className="Introduction-stage-box-h1">{t('introduction-introduction-t3')}</div>
                <div style={{ height: '20px' }} />
                <div className="Introduction-stage-box-h5">{t('introduction-introduction-d3')}</div>
              </div>
            </div>
          </>
        )}
        {tab == 1 && (
          <>
            <div className="Introduction-tab-box">
              <button className="Introduction-tab" disabled={page == 0} onClick={() => setPage(0)}>
                1. {t('introduction-how-to-play')}
              </button>
              <div style={{ width: '37px' }} />
              <button className="Introduction-tab" disabled={page == 1} onClick={() => setPage(1)}>
                2. {t('introduction-how-to-trade')}
              </button>
            </div>
            {page == 0 && (
              <section>
                <div className="Introduction-h1">{t('introduction-how-to-play-p1')}</div>
                <div style={{ height: '41px' }} />
                <div className="Introduction-h2">{t('demo-challenge')}</div>
                <div style={{ height: '15px' }} />
                <div className="Introduction-h1">{t('introduction-how-to-play-p2')}</div>
                <div style={{ height: '10px' }} />
                <div className="Introduction-h1">{t('introduction-how-to-play-p3')}</div>
                <div style={{ height: '40px' }} />
                <div className="Introduction-h2">{t('tournament-challenge')}</div>
                <div style={{ height: '13px' }} />
                <div className="Introduction-h1">{t('introduction-how-to-play-p4')}</div>
                <div style={{ height: '10px' }} />
                <div className="Introduction-h1">{t('introduction-how-to-play-p5')}</div>
                <div style={{ height: '40px' }} />
                <div className="Introduction-h2">{t('live-challenge')}</div>
                <div style={{ height: '13px' }} />
                <div className="Introduction-h1">{t('introduction-how-to-play-p6')}</div>
                <div style={{ height: '10px' }} />
                <div className="Introduction-h1">{t('introduction-how-to-play-p7')}</div>
                <div style={{ height: '40px' }} />
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(40%, auto))', gap: '20px' }}>
                  <div className="Introduction-stage-box">
                    <div className="Introduction-stage-box-img">
                      <img src={ContinuousIcon} />
                    </div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-stage-box-h1">{t('continuous-mode')}</div>
                    <div style={{ height: '10px' }} />
                    <div className="Introduction-stage-box-h2">{t('introduction-how-to-play-p8')}</div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-stage-box-box">
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'stretch' }}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '70px' }}>
                            <div className="Introduction-stage-box-box-h1">{t('introduction-challenge-time')}</div>
                          </div>
                          <div style={{ width: '20px' }} />
                          <span style={{ margin: 'auto' }}>
                            <div className="Introduction-stage-box-box-h1">{t('introduction-challenge-menual')}</div>
                          </span>
                        </div>
                        <div style={{ height: '10px' }} />
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '70px' }}>
                            <div className="Introduction-stage-box-box-h2">{t('introduction-challenge-one-hour')}</div>
                          </div>
                          <div style={{ width: '20px' }} />
                          <span style={{ margin: 'auto' }}>
                            <div className="Introduction-stage-box-box-h2">{t('introduction-how-to-play-p9')}</div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-stage-box-h3">{t('introduction-how-to-play-p10')}</div>
                    <div style={{ height: '10px' }} />
                    <div className="Introduction-stage-box-h3">{t('introduction-how-to-play-p11')}</div>
                    <div style={{ height: '10px' }} />
                    <div className="Introduction-stage-box-h4">{t('introduction-how-to-play-p12')}</div>
                  </div>
                  <div className="Introduction-stage-box">
                    <div className="Introduction-stage-box-img">
                      <img src={FixedIcon} />
                    </div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-stage-box-h1">{t('fixed-mode')}</div>
                    <div style={{ height: '10px' }} />
                    <div className="Introduction-stage-box-h2">{t('introduction-how-to-play-p13')}</div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-stage-box-box">
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'stretch' }}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '20px' }} />
                          <div style={{ width: '70px' }}>
                            <div className="Introduction-stage-box-box-h1">{t('introduction-challenge-time')}</div>
                          </div>
                          <div style={{ width: '20px' }} />
                          <span style={{ margin: 'auto' }}>
                            <div className="Introduction-stage-box-box-h1">{t('introduction-challenge-menual')}</div>
                          </span>
                        </div>
                        <div style={{ height: '10px' }} />
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '20px' }} />
                          <div style={{ width: '70px' }}>
                            <div className="Introduction-stage-box-box-h2">{t('introduction-challenge-one-hour')}</div>
                          </div>
                          <div style={{ width: '20px' }} />
                          <span style={{ margin: 'auto' }}>
                            <div className="Introduction-stage-box-box-h2">{t('introduction-how-to-play-p14')}</div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-stage-box-h3">{t('introduction-how-to-play-p15')}</div>
                    <div style={{ height: '10px' }} />
                    <div className="Introduction-stage-box-h3">{t('introduction-how-to-play-p16')}</div>
                    <div style={{ height: '10px' }} />
                    <div className="Introduction-stage-box-h4">{t('introduction-how-to-play-p17')}</div>
                  </div>
                </div>
                <div style={{ height: '40px' }} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img className="Introduction-button" src={LongIcon} />
                  <div style={{ width: '22px' }} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="Introduction-long">Long</div>
                    <div style={{ height: '4px' }} />
                    <div className="Introduction-h1">{t('introduction-how-to-play-p18')}</div>
                  </div>
                </div>
                <div style={{ height: '10px' }} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img className="Introduction-button" src={ShortIcon} />
                  <div style={{ width: '22px' }} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="Introduction-short">Short</div>
                    <div style={{ height: '4px' }} />
                    <div className="Introduction-h1">{t('introduction-how-to-play-p19')}</div>
                  </div>
                </div>
                <div style={{ height: '40px' }} />
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(40%, auto))', gap: '20px' }}>
                  <div className="Introduction-stage-box">
                    <img src={PointAIcon} style={{ width: '72px', height: '72px', margin: '20px auto' }} />
                    <div className="Introduction-point-h1">{t('demo-point')}</div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-point-h2">{t('Introduction-description-demo-point')}</div>
                  </div>
                  <div className="Introduction-stage-box">
                    <img src={PointBIcon} style={{ width: '72px', height: '72px', margin: '20px auto' }} />
                    <div className="Introduction-point-h1">{t('tournament-point')}</div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-point-h2">{t('Introduction-description-tournament-point')}</div>
                  </div>
                </div>
                <div style={{ height: '15px' }} />
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(40%, auto))', gap: '20px' }}>
                  <div className="Introduction-stage-box">
                    <img src={PointCIcon} style={{ width: '72px', height: '72px', margin: '20px auto' }} />
                    <div className="Introduction-point-h1">{t('live-point')}</div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-point-h2">{t('Introduction-description-live-point')}</div>
                  </div>
                  <div className="Introduction-stage-box">
                    <img src={PointDIcon} style={{ width: '72px', height: '72px', margin: '20px auto' }} />
                    <div className="Introduction-point-h1">{t('eth-point')}</div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-point-h2">{t('Introduction-description-eth-point')}</div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-point-h3">{t('Introduction-description-eth-point2')}</div>
                  </div>
                </div>
                {rewards != null &&
                  Object.entries(rewards).map(([stage, value]) => {
                    if (stage != 'game1' && stage != 'game2' && stage != 'game3') return <></>;
                    return (
                      <>
                        <div style={{ height: '40px' }} />
                        <div className="Introduction-h2">
                          {stage == 'game1' ? t('demo-challenge') : stage == 'game2' ? t('tournament-challenge') : t('live-challenge')}{' '}
                          {t('introduction-how-to-play-rewards-title')}
                        </div>
                        <div style={{ height: '20px' }} />
                        <div className="Introduction-h4">{t('continuous-mode')}</div>
                        <div style={{ height: '10px' }} />
                        <div className="Introduction-rewards">
                          <div style={{ height: '42px', position: 'relative', margin: '0 auto' }}>
                            <div className="Challenge-rewards-title">{t('challenge-rewards')}</div>
                          </div>
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: 'repeat(10, 1fr)',
                              gridTemplateRows: 'repeat(2, 1fr)',
                              height: '228px',
                            }}
                          >
                            {value['continuous'].map((e, i) => {
                              return <RewardInfo index={i + 1} reward={e} stage={stage} />;
                            })}
                          </div>
                        </div>
                        <div style={{ height: '10px' }} />
                        <div className="Introduction-h4">{t('fixed-mode')}</div>
                        <div style={{ height: '10px' }} />
                        <div className="Introduction-rewards">
                          <div style={{ height: '42px', position: 'relative', margin: '0 auto' }}>
                            <div className="Challenge-rewards-title">{t('challenge-rewards')}</div>
                          </div>
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: 'repeat(10, 1fr)',
                              gridTemplateRows: 'repeat(2, 1fr)',
                              height: '228px',
                            }}
                          >
                            {value['fixed'].map((e, i) => {
                              return <RewardInfo index={i + 1} reward={e} stage={stage} />;
                            })}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </section>
            )}
            {page == 1 && (
              <section>
                <div className="Introduction-h2">{t('introduction-how-to-trade-title')}</div>
                <div style={{ height: '20px' }} />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch' }}>
                  <div className="Introduction-wallet-box">
                    <div className="Introduction-stage-box-h1">{t('introduction-deposit')}</div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-stage-box-h5">{t('introduction-how-to-trade-p1')}</div>
                  </div>
                  <div className="Introduction-wallet-box">
                    <div className="Introduction-stage-box-h1">{t('introduction-withdrawal')}</div>
                    <div style={{ height: '20px' }} />
                    <div className="Introduction-stage-box-h5">{t('introduction-how-to-trade-p2')}</div>
                  </div>
                </div>
              </section>
            )}
          </>
        )}
        <div style={{ height: '30px' }} />

        <Link to="/challenge-participate" className="Introduction-gotochallenge">
          {t('introduction-go-to-challenge')} ☞
        </Link>
        <div style={{ height: '180px' }} />
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
}

import PointAIcon from 'img/point_a.png';
import PointBIcon from 'img/point_b.png';
import PointCIcon from 'img/point_c.png';
import PointDIcon from 'img/point_d.png';

const RewardInfo = (props: { index: number; reward: number; stage: string }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <div className="Challenge-rewards-round">{props.index}</div>
      <div className="Challenge-rewards-round-reward">
        {props.reward != 0 &&
          (props.reward < 0 ? (
            <>
              <img
                src={props.stage == 'game1' ? PointAIcon : props.stage == 'game2' ? PointBIcon : PointCIcon}
                style={{ width: '56px', height: '56px' }}
              />
              <div className="Challenge-rewards-label">+{Math.abs(props.reward)}$</div>
              <div className="Challenge-rewards-label-shadow">+{Math.abs(props.reward)}$</div>
            </>
          ) : (
            <>
              <img
                src={props.stage == 'game1' ? PointBIcon : props.stage == 'game2' ? PointCIcon : PointDIcon}
                style={{ width: '56px', height: '56px' }}
              />
              <div className="Challenge-rewards-label">+{props.reward}$</div>
              <div className="Challenge-rewards-label-shadow">+{props.reward}$</div>
            </>
          ))}
      </div>
    </div>
  );
};
