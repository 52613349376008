import React, { ReactElement, useEffect, useState } from 'react';
import { Switch, Route, RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { findPassword, findUsername, getAccount, getWallets } from '../api';

import Footer from '../components/Footer';
import Logo from 'img/signup_logo.png';

import '../css/ForgetAccounts.css';
import { Motion, spring } from 'react-motion';

import Icon_Individual from 'img/signup/icon_individual.png';
import Icon_Business from 'img/signup/icon_business.png';

export default withRouter(function ForgetAccounts(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const [sort, setSort] = useState<string>('id');

  const [email, setEmail] = useState('');
  const [id, setId] = useState('');
  const [email2, setEmail2] = useState('');

  const [accountType, setAccountType] = useState<'individual' | 'business' | null>(null);

  return (
    <div className="App">
      {accountType == null && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ height: '100px' }} />
            <img style={{ width: '207px', height: '207px' }} src={Logo} />
            <div style={{ height: '24px' }} />
            <div className="Signup-select-h1">MAX4</div>
            <div style={{ height: '9px' }} />
            <div className="Signup-select-h2">Best Trading Education Program</div>
            <div style={{ height: '80px' }} />
            <Motion defaultStyle={{ x: 0 }} style={{ x: spring(1) }}>
              {(value) => (
                <>
                  <div
                    style={{
                      width: `${value.x * 620}px`,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    <div className="Signup-select-btn">
                      <h1>일반 회원가입</h1>
                      <div style={{ height: '40px' }} />
                      <div
                        style={{
                          height: '155px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={Icon_Individual} style={{ width: '130px', height: '155px' }} />
                      </div>
                      <div style={{ height: '40px' }} />
                      <h2>Max Signal을 이용하는 일반 회원입니다.</h2>
                      <br />
                      <div style={{ height: '20px' }} />
                      <button onClick={() => setAccountType('individual')}>찾기</button>
                    </div>
                    <div className="Signup-select-btn">
                      <h1>비즈니스 회원</h1>
                      <div style={{ height: '40px' }} />
                      <div
                        style={{
                          height: '155px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={Icon_Business} style={{ width: '140px', height: '124px' }} />
                      </div>
                      <div style={{ height: '40px' }} />
                      <h2>별도의 가입비를 지급해야 가입이 가능한 비즈니스 회원입니다.</h2>
                      <div style={{ height: '20px' }} />
                      <span style={{ margin: 'auto' }} />
                      <button onClick={() => setAccountType('business')}>찾기</button>
                    </div>
                  </div>

                  <div style={{ height: '20px' }} />
                  <div
                    style={{
                      width: `${value.x * 620}px`,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    <Link className="Signin-infoside-backbtn" to="/">
                      {t('signin-back')}
                    </Link>
                  </div>
                </>
              )}
            </Motion>
          </div>
          <div style={{ height: '220px' }} />
        </>
      )}

      {accountType != null && (
        <>
          <div className="ForgetAccounts-main">
            <div className="ForgetAccounts-logoside">
              <img src={Logo} className="ForgetAccounts-logoside-logo" />
              <div style={{ height: '38px' }} />
              <div className="Signin-logoside-h1">MAX4</div>
              <div style={{ height: '16px' }} />
              <div className="Signin-logoside-h2">Best Trading Education Program</div>
            </div>
            <div className="ForgetAccoutns-infoside">
              <button
                className="ForgetAccoutns-infoside-backbtn"
                onClick={() => {
                  props.history.goBack();
                }}
              >
                이전 화면으로 돌아가기
              </button>
              <div style={{ height: '20px' }} />
              <div className="ForgetAccoutns-infoside-box">
                <div style={{ height: '35px', display: 'flex', flexDirection: 'row' }}>
                  <button
                    className="ForgetAccoutns-infoside-box-h1"
                    disabled={sort == 'id'}
                    onClick={() => {
                      setSort('id');
                      setEmail('');
                    }}
                  >
                    아이디 찾기
                  </button>
                  <div style={{ width: '24px' }} />
                  <button
                    className="ForgetAccoutns-infoside-box-h1"
                    disabled={sort == 'pw'}
                    onClick={() => {
                      setSort('pw');
                      setId('');
                      setEmail2('');
                    }}
                  >
                    비밀번호 찾기
                  </button>
                </div>
                <div style={{ height: '28px' }} />
                {sort == 'id' && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <div className="ForgetAccoutns-infoside-box-h2">이메일</div>
                    <div style={{ height: '10px' }} />
                    <div style={{ height: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'stretch' }}>
                      <input
                        className="ForgetAccoutns-infoside-box-input"
                        placeholder="example@gmail.com"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div style={{ height: '60px' }} />
                    <button
                      className="ForgetAccoutns-infoside-box-btn2"
                      onClick={() => {
                        (async () => {
                          const result = await findUsername(email, accountType);
                          if (result) {
                            // Open modal or show toast
                            alert(t('id-find-email-sent'));
                            setEmail('');
                          } else {
                            // 잘못된 접근
                            // 401 인 경우 이메일이 올바르지 않음
                          }
                        })();
                      }}
                    >
                      아이디 찾기
                    </button>
                  </div>
                )}
                {sort == 'pw' && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <div className="ForgetAccoutns-infoside-box-h2">아이디</div>
                    <div style={{ height: '10px' }} />
                    <input
                      className="ForgetAccoutns-infoside-box-input"
                      placeholder="아이디를 입력해주세요."
                      type="text"
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                    />
                    <div style={{ height: '20px' }} />
                    <div className="ForgetAccoutns-infoside-box-h2">이메일</div>
                    <div style={{ height: '10px' }} />
                    <div style={{ height: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'stretch' }}>
                      <input
                        className="ForgetAccoutns-infoside-box-input"
                        placeholder="example@gmail.com"
                        type="text"
                        value={email2}
                        onChange={(e) => setEmail2(e.target.value)}
                      />
                    </div>
                    <div style={{ height: '60px' }} />
                    <button
                      className="ForgetAccoutns-infoside-box-btn2"
                      onClick={() => {
                        (async () => {
                          const result = await findPassword(id, email2, accountType);
                          if (result) {
                            // Open modal or show toast
                            alert(t('pw-find-email-sent'));
                            setEmail2('');
                            setId('');
                          } else {
                            // 잘못된 접근
                            // 401의 경우 아이디나 이메일이 잘못된 것
                          }
                        })();
                      }}
                    >
                      비밀번호 찾기
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});
