import React, { ReactElement, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getNotice, listNotices } from '../api';

import Header from '../components/Header';
import Footer from '../components/Footer';

import '../css/Notice.css';

const Columns = styled.div`
  display: grid;
  grid-template-columns: 140px 340px 150px 140px;
`;

const ColumnTitle = styled.div`
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
`;

const Pages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PageBtn = styled.button`
  margin: 0 5px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background: none;
  border: none;
  padding: 0;
  :disabled {
    color: #e0be7a;
  }
`;

export default withRouter(function Notice(): ReactElement {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [notices, setNotices] = useState<{ id: string; category: string; title: string; author: string; writtenAt: Date }[]>([]);
  const [content, setContent] =
    useState<{
      category: string;
      title: string;
      author: string;
      content: string;
      date: Date;
    } | null>(null);
  const [prev, setPrev] = useState<{ id: string; category: string; title: string; author: string; date: Date } | null>(null);
  const [next, setNext] = useState<{ id: string; category: string; title: string; author: string; date: Date } | null>(null);

  useEffect(() => {
    (async () => {
      const result = await listNotices(currentPage);

      if (result === null) return;

      setNotices(result.notices);
      setMaxPage(result.maxPage);
    })();
  }, [currentPage]);

  async function handleClickNotice(id: string) {
    const notice = await getNotice(id);

    if (!notice) {
      setContent(null);
      setPrev(null);
      setNext(null);
      return;
    }

    setContent({
      category: notice.notice.category,
      title: notice.notice.title,
      author: notice.notice.author,
      content: notice.notice.content,
      date: notice.notice.writtenAt,
    });

    if (!notice.prev) setPrev(null);
    else
      setPrev({
        id: notice.prev.id,
        category: notice.prev.category,
        title: notice.prev.title,
        author: notice.prev.author,
        date: notice.prev.writtenAt,
      });

    if (!notice.next) setNext(null);
    else
      setNext({
        id: notice.next.id,
        category: notice.next.category,
        title: notice.next.title,
        author: notice.next.author,
        date: notice.next.writtenAt,
      });
  }

  return (
    <div className="App">
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '96px auto 180px' }}>
        {content !== null && (
          <div className="Notice-main">
            <div className="Notice-main-h1">
              {t('main-menu-notice')}-{content.category}
            </div>
            <div className="Notice-main-h2">{content.title}</div>
            <div className="Notice-main-h3">
              {t('service-author')}: {content.author} &emsp; {t('service-date')}: {content.date.toString()}
            </div>
            <div className="Notice-main-line" />
            <div className="Notice-main-h4" dangerouslySetInnerHTML={{ __html: content.content }} />
            <div className="Notice-main-line" />
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '20px' }}>
              {prev && (
                <ContentInfo
                  sort="previous"
                  title={prev.title}
                  author={prev.author}
                  date={prev.date}
                  onClick={() => handleClickNotice(prev.id)}
                />
              )}
              {next && (
                <ContentInfo
                  sort="next"
                  title={next.title}
                  author={next.author}
                  date={next.date}
                  onClick={() => handleClickNotice(next.id)}
                />
              )}
            </div>
          </div>
        )}
        <div className="Notice-box">
          <div className="Notice-box-title">{t('main-menu-notice')}</div>
          <div style={{ height: '24px' }} />
          <Columns>
            <ColumnTitle>{t('service-category')}</ColumnTitle>
            <ColumnTitle>{t('service-title')}</ColumnTitle>
            <ColumnTitle>{t('service-author')}</ColumnTitle>
            <ColumnTitle>{t('service-date')}</ColumnTitle>
          </Columns>
          <div style={{ height: '10.5px' }} />
          <div style={{ height: '1px', border: 'solid 1px #ffffff' }} />
          {notices.map((notice) => (
            <RowData
              key={notice.id}
              category={notice.category}
              title={notice.title}
              author={notice.author}
              date={notice.writtenAt}
              onClick={() => handleClickNotice(notice.id)}
            />
          ))}
          <div style={{ height: '20px' }} />
          <Pages>
            {(() => {
              const arr: number[] = [];
              for (let page = 1; page <= maxPage; ++page) arr.push(page);
              return arr;
            })().map((page) => (
              <PageBtn key={page} disabled={page == currentPage} onClick={() => setCurrentPage(page)}>
                {page}
              </PageBtn>
            ))}
          </Pages>
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});

const RowData = (props: { category: string; title: string; author: string; date: Date; onClick: () => void }) => {
  return (
    <Row onClick={props.onClick}>
      <Columns>
        <div className="Notice-row-h1">{props.category}</div>
        <div className="Notice-row-h2">{props.title}</div>
        <div className="Notice-row-h3">{props.author}</div>
        <div className="Notice-row-h3">{props.date.toDateString()}</div>
      </Columns>
    </Row>
  );
};

const ContentInfo = (props: { sort: string; title: string; author: string; date: Date; onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="Notice-info-h1">{props.sort == 'previous' ? t('service-previous') : t('service-next')}</div>
      <button className="Notice-info-h2" onClick={props.onClick}>
        {props.title}
      </button>
      <div className="Notice-info-h3">
        {t('service-author')}: {props.author} &emsp; {t('service-date')}: {props.date.toString()}
      </div>
    </div>
  );
};
