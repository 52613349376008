import React, { ReactElement, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { verifyEmail } from '../api';

export default withRouter(function VerifyEmail(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const query = new URLSearchParams(window.location.search);
      console.log(query.get('token'));
      const response = await verifyEmail(query.get('token') ?? '');

      if (!response) {
        props.history.push('/');
        return;
      }

      if (!response.success) {
        alert(t('verify-email-alert-failed'));
        props.history.push('/');
        return;
      }

      alert(t('verify-email-alert-success'));
      props.history.push('/signin');
    })();
  });

  return <p style={{ color: 'white' }}>{t('verify-email-label')}</p>;
});
