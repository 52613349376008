import React, { ReactElement, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { confirmWalletUpdated, resetPassword } from '../api';

import Logo from 'img/signup_logo.png';

import Footer from './Footer';

import { useEffect } from 'react';

export default withRouter(function UpdateWalletAddress(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const [token, setToken] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) setToken(token);
    else {
      props.history.push('/');
      return;
    }
    (async () => {
      const result = await confirmWalletUpdated(token, 'business');
      if (result && result) {
        setSuccess(true);
      }
    })();
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#1d1d1d',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
      }}
    >
      <img src={Logo} style={{ width: '250px', height: '250px' }} />
      {success && (
        <>
          <div style={{ height: '20px' }} />
          <div className="Mypage-info-label">지갑 주소 변경이 완료되었습니다!</div>
          <div style={{ width: '300px', height: '30px' }}>
            <button
              className="Mypage-wallet-innerbox-btn-full"
              onClick={() => {
                props.history.push('/');
              }}
            >
              확인
            </button>
          </div>
        </>
      )}
    </div>
  );
});
