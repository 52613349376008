import React, { ReactElement, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getFAQ, getInquiry, listFAQs, listInquires, InquiryInfo } from '../api';

import Header from '../components/Header';
import Footer from '../components/Footer';

import '../css/CustomerCare.css';

const Tab = styled.button`
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0;
  text-align: left;
  color: #ffffff;
  background: none;
  border: none;
  opacity: 0.4;
  :disabled {
    opacity: 1;
  }
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 140px 340px 150px 140px;
`;

const ColumnTitle = styled.div`
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
`;

const Pages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PageBtn = styled.button`
  margin: 0 5px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background: none;
  border: none;
  padding: 0;
  :disabled {
    color: #e0be7a;
  }
`;

export default withRouter(function CustomerCare(): ReactElement {
  const { t } = useTranslation();

  const [tab, setTab] = useState<'inquiry' | 'ask'>('inquiry');

  // Inquiry
  const [Inquiry_currentPage, Inquiry_setCurrentPage] = useState(1);
  const [Inquiry_maxPage, Inquiry_setMaxPage] = useState(1);
  const [Inquiry_inquiries, Inquiry_setInquiries] = useState<InquiryInfo[]>([]);
  const [Inquiry_content, Inquiry_setContent] = useState<InquiryInfo | null>(null);
  const [Inquiry_prev, Inquiry_setPrev] = useState<InquiryInfo | null>(null);
  const [Inquiry_next, Inquiry_setNext] = useState<InquiryInfo | null>(null);

  // FAQ
  const [FAQ_currentPage, FAQ_setCurrentPage] = useState(1);
  const [FAQ_maxPage, FAQ_setMaxPage] = useState(1);
  const [FAQ_faqs, FAQ_setFaqs] = useState<{ id: string; category: string; title: string; author: string; writtenAt: Date }[]>([]);
  const [FAQ_content, FAQ_setContent] =
    useState<{
      category: string;
      title: string;
      author: string;
      content: string;
      date: Date;
    } | null>(null);
  const [FAQ_prev, FAQ_setPrev] = useState<{ id: string; category: string; title: string; author: string; date: Date } | null>(null);
  const [FAQ_next, FAQ_setNext] = useState<{ id: string; category: string; title: string; author: string; date: Date } | null>(null);

  useEffect(() => {
    (async () => {
      const result = await listInquires(Inquiry_currentPage);

      if (result === null) return;

      Inquiry_setInquiries(result.inquiries);
      Inquiry_setMaxPage(result.maxPage);
    })();
  }, [Inquiry_currentPage]);
  useEffect(() => {
    (async () => {
      const result = await listFAQs(FAQ_currentPage);

      if (result === null) return;

      FAQ_setFaqs(result.faqs);
      FAQ_setMaxPage(result.maxPage);
    })();
  }, [FAQ_currentPage]);

  async function handleClickInquiry(id: string) {
    const inquiry = await getInquiry(id);

    if (!inquiry) {
      Inquiry_setContent(null);
      Inquiry_setPrev(null);
      Inquiry_setNext(null);
      return;
    }

    Inquiry_setContent(inquiry.inquiry);

    if (!inquiry.prev) Inquiry_setPrev(null);
    else Inquiry_setPrev(inquiry.prev);

    if (!inquiry.next) Inquiry_setNext(null);
    else Inquiry_setNext(inquiry.next);
  }
  async function handleClickFAQ(id: string) {
    const faq = await getFAQ(id);

    if (!faq) {
      FAQ_setContent(null);
      FAQ_setPrev(null);
      FAQ_setNext(null);
      return;
    }

    FAQ_setContent({
      category: faq.faq.category,
      title: faq.faq.title,
      author: faq.faq.author,
      content: faq.faq.content,
      date: faq.faq.writtenAt,
    });

    if (!faq.prev) FAQ_setPrev(null);
    else
      FAQ_setPrev({
        id: faq.prev.id,
        category: faq.prev.category,
        title: faq.prev.title,
        author: faq.prev.author,
        date: faq.prev.writtenAt,
      });

    if (!faq.next) FAQ_setNext(null);
    else
      FAQ_setNext({
        id: faq.next.id,
        category: faq.next.category,
        title: faq.next.title,
        author: faq.next.author,
        date: faq.next.writtenAt,
      });
  }

  return (
    <div className="App">
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '96px auto 180px' }}>
        {tab === 'inquiry' && Inquiry_content !== null && (
          <div className="Notice-main">
            <div className="Notice-main-h1">
              {t('service-faq')}-{Inquiry_content.category}
            </div>
            <div className="Notice-main-h2">{Inquiry_content.title}</div>
            <div className="Notice-main-h3">
              {t('service-author')}: {Inquiry_content.author} &emsp; {t('service-date')}: {Inquiry_content.writtenAt.toString()}
            </div>
            <div className="Notice-main-line" />
            <div className="Notice-main-h4">{Inquiry_content.content}</div>
            {!Inquiry_content.response && (
              <>
                <div className="Notice-main-line" />
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '20px' }}>
                  {Inquiry_prev && (
                    <ContentInfo
                      sort="previous"
                      title={Inquiry_prev.title}
                      author={Inquiry_prev.author}
                      date={Inquiry_prev.writtenAt}
                      onClick={() => handleClickInquiry(Inquiry_prev.id)}
                    />
                  )}
                  {Inquiry_next && (
                    <ContentInfo
                      sort="next"
                      title={Inquiry_next.title}
                      author={Inquiry_next.author}
                      date={Inquiry_next.writtenAt}
                      onClick={() => handleClickInquiry(Inquiry_next.id)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
        {tab === 'inquiry' && Inquiry_content !== null && Inquiry_content.response && (
          <div className="Notice-main">
            <div className="Notice-main-h5">{Inquiry_content.response.title}</div>
            <div className="Notice-main-h3">
              {t('service-author')}: {Inquiry_content.response.author} &emsp; {t('service-date')}:{' '}
              {Inquiry_content.response.writtenAt.toString()}
            </div>
            <div className="Notice-main-line" />
            <div className="Notice-main-h4" dangerouslySetInnerHTML={{ __html: Inquiry_content.response.content }} />
            <div className="Notice-main-line" />
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '20px' }}>
              {Inquiry_prev && (
                <ContentInfo
                  sort="previous"
                  title={Inquiry_prev.title}
                  author={Inquiry_prev.author}
                  date={Inquiry_prev.writtenAt}
                  onClick={() => handleClickInquiry(Inquiry_prev.id)}
                />
              )}
              {Inquiry_next && (
                <ContentInfo
                  sort="next"
                  title={Inquiry_next.title}
                  author={Inquiry_next.author}
                  date={Inquiry_next.writtenAt}
                  onClick={() => handleClickInquiry(Inquiry_next.id)}
                />
              )}
            </div>
          </div>
        )}
        {tab === 'ask' && FAQ_content !== null && (
          <div className="Notice-main">
            <div className="Notice-main-h1">
              {t('service-faq')}-{FAQ_content.category}
            </div>
            <div className="Notice-main-h2">{FAQ_content.title}</div>
            <div className="Notice-main-h3">
              {t('service-author')}: {FAQ_content.author} &emsp; {t('service-date')}: {FAQ_content.date.toString()}
            </div>
            <div className="Notice-main-line" />
            <div className="Notice-main-h4" dangerouslySetInnerHTML={{ __html: FAQ_content.content }} />
            <div className="Notice-main-line" />
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '20px' }}>
              {FAQ_prev && (
                <ContentInfo
                  sort="previous"
                  title={FAQ_prev.title}
                  author={FAQ_prev.author}
                  date={FAQ_prev.date}
                  onClick={() => handleClickFAQ(FAQ_prev.id)}
                />
              )}
              {FAQ_next && (
                <ContentInfo
                  sort="next"
                  title={FAQ_next.title}
                  author={FAQ_next.author}
                  date={FAQ_next.date}
                  onClick={() => handleClickFAQ(FAQ_next.id)}
                />
              )}
            </div>
          </div>
        )}
        <div className="CustomerCare-box">
          <div className="CustomerCare-box-title">{t('service-center')}</div>
          <div style={{ height: '10px' }} />
          <div style={{ display: 'flex' }}>
            <Tab
              disabled={tab == 'ask'}
              onClick={() => {
                setTab('ask');
                FAQ_setContent(null);
                FAQ_setPrev(null);
                FAQ_setNext(null);
              }}
            >
              {t('service-faq')}
            </Tab>
            <div style={{ width: '20px' }} />

            <Tab
              disabled={tab == 'inquiry'}
              onClick={() => {
                setTab('inquiry');
                Inquiry_setContent(null);
                Inquiry_setPrev(null);
                Inquiry_setNext(null);
              }}
            >
              {t('service-send-enquiry')}
            </Tab>

            <span style={{ margin: 'auto' }} />
            <Link className="CustomerCare-enquiry-btn" to="/customercare/send-enquiry">
              문의 작성
            </Link>
          </div>
          <div style={{ height: '20px' }} />
          <Columns>
            <ColumnTitle>{t('service-category')}</ColumnTitle>
            <ColumnTitle>{t('service-title')}</ColumnTitle>
            <ColumnTitle>{t('service-author')}</ColumnTitle>
            <ColumnTitle>{t('service-date')}</ColumnTitle>
          </Columns>
          <div style={{ height: '10.5px' }} />
          <div style={{ height: '1px', border: 'solid 1px #ffffff' }} />
          {tab === 'inquiry' &&
            Inquiry_inquiries.map((inquiry) => (
              <>
                <RowData
                  key={inquiry.id}
                  category={inquiry.category}
                  title={inquiry.title}
                  author={inquiry.author}
                  date={inquiry.writtenAt}
                  private={inquiry.private}
                  onClick={() => handleClickInquiry(inquiry.id)}
                />
                {inquiry.response && (
                  <RowData
                    key={`${inquiry.id} reply`}
                    category={inquiry.category}
                    title={inquiry.response.title}
                    author={inquiry.response.author}
                    date={inquiry.response.writtenAt}
                    reply={true}
                    private={inquiry.response.private}
                    onClick={() => handleClickInquiry(inquiry.id)}
                  />
                )}
              </>
            ))}
          {tab === 'ask' &&
            FAQ_faqs.map((faq) => (
              <RowData
                key={faq.id}
                category={faq.category}
                title={faq.title}
                author={faq.author}
                date={faq.writtenAt}
                onClick={() => handleClickFAQ(faq.id)}
              />
            ))}
          <div style={{ height: '20px' }} />
          <Pages>
            {tab === 'inquiry' &&
              (() => {
                const arr: number[] = [];
                for (let page = 1; page <= Inquiry_maxPage; ++page) arr.push(page);
                return arr;
              })().map((page) => (
                <PageBtn key={page} disabled={page == Inquiry_currentPage} onClick={() => Inquiry_setCurrentPage(page)}>
                  {page}
                </PageBtn>
              ))}
            {tab === 'ask' &&
              (() => {
                const arr: number[] = [];
                for (let page = 1; page <= FAQ_maxPage; ++page) arr.push(page);
                return arr;
              })().map((page) => (
                <PageBtn key={page} disabled={page == FAQ_currentPage} onClick={() => FAQ_setCurrentPage(page)}>
                  {page}
                </PageBtn>
              ))}
          </Pages>
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});

import IconReply from 'img/icon_reply.png';
import IconPrivate from 'img/icon_private.png';

const RowData = (props: {
  category: string;
  title: string;
  author: string;
  private?: boolean;
  reply?: boolean;
  date: Date;
  onClick: () => void;
}) => {
  return (
    <Row onClick={props.onClick}>
      <Columns>
        <div className="CustomerCare-row-h1">{props.category}</div>
        <div style={{ display: 'flex' }}>
          {props.reply && props.reply && (
            <>
              <img src={IconReply} />
              <div style={{ width: '10px' }} />
            </>
          )}
          {props.private && props.private && (
            <>
              <img src={IconPrivate} />
              <div style={{ width: '10px' }} />
            </>
          )}
          <div className="CustomerCare-row-h2">{props.title}</div>
        </div>
        <div className="CustomerCare-row-h3">{props.author}</div>
        <div className="CustomerCare-row-h3">{props.date.toDateString()}</div>
      </Columns>
    </Row>
  );
};

const ContentInfo = (props: { sort: string; title: string; author: string; date: Date; onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="Notice-info-h1">{props.sort == 'previous' ? t('service-previous') : t('service-next')}</div>
      <button className="Notice-info-h2" onClick={props.onClick}>
        {props.title}
      </button>
      <div className="Notice-info-h3">
        {t('service-author')}: {props.author} &emsp; {t('service-date')}: {props.date.toString()}
      </div>
    </div>
  );
};
